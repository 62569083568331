import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select } from 'redux-saga/effects'
import {
  getListContact,
  disableIdContact,
  enableIdContact,
  deleteIdContact,
} from './ccCrud'
import { getAllData } from '../../../../helpers/api'

export const actionTypes = {
  setDataContact: 'CONTACTS_SET_DATA',
  getDataContact: 'CONTACTS_GET_DATA',
  errorDataContact: 'CONTACTS_ERROR_DATA',
  setDisabledIdContact: 'CONTACTS_SET_DISABLED_ID',
  setEnabledIdContact: 'CONTACTS_SET_ENABLED_ID',
  deleteIdContact: 'CONTACTS_DELETE_ID',
  createContact: 'CONTACTS_CREATE',
  updateIdContact: 'UPDATE_ID_CONTACTS',
}

const initialState = {
  contact: {
    data: {},
    meta: {
      pager: {
        count: 0,
        total: 0,
        per_page: 0,
        page: 0,
        pages: 0,
      },
    },
    error: false,
    isLoading: false,
    disabled: false,
    globalLoading: true,
  },
}

export const reducer = persistReducer(
  {
    storage,
    key: 'contact',
    blacklist: ['contact'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.getDataContact: {
        return {
          ...state,
          contact: {
            ...state.contact,
            // data: [],
            isLoading: true,
            globalLoading: true,
          },
        }
      }
      case actionTypes.setDataContact: {
        return {
          ...state,
          contact: {
            ...state.contact,
            data: action.payload,
            isLoading: false,
            globalLoading: false,
          },
        }
      }
      case actionTypes.errorDataContact: {
        return {
          ...state,
          contact: {
            ...state.contact,
            isLoading: false,
            error: true,
            globalLoading: false,
          },
        }
      }
      case actionTypes.deleteIdContact: {
        return {
          ...state,
          contact: {
            ...state.contact,
            isLoading: true,
            error: false,
          },
        }
      }
      default:
        return state
    }
  }
)
export const getDataContact = (id = 1) => ({
  type: actionTypes.getDataContact,
  id,
})

export const setPageContact = (data) => ({
  type: actionTypes.setDataContact,
  payload: data,
})

export const setDisabledIdContact = (id) => ({
  type: actionTypes.setDisabledIdContact,
  id,
})

export const setEnabledIdContact = (id) => ({
  type: actionTypes.setEnabledIdContact,
  id,
})

export const setErrorPageContact = () => ({
  type: actionTypes.errorDataContact,
})

export const actions = {
  setPageContact: (data) => ({
    type: actionTypes.setDataContact,
    payload: data,
  }),
}

export const deleteContact = (id) => ({
  type: actionTypes.deleteIdContact,
  id,
})

export const createDataContact = (data) => ({
  type: actionTypes.createContact,
  data,
})

export const editIdDataContact = ({ id, data }) => ({
  type: actionTypes.updateIdContact,
  data,
  id,
})

export function* saga() {
  // createContact

  yield takeLatest(actionTypes.updateIdContact, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.contact.contact.data.map((item) => {
        if (item.id === currentData.id) {
          item = {
            ...item,
            ...currentData.data,
          }
        }
        return item
      })

      yield put(actions.setPageContact({ data: newData }))
    } catch (e) {
      console.log(e)
      yield put(setErrorPageContact())
    }
  })

  yield takeLatest(actionTypes.deleteIdContact, function* (currentData) {
    try {
      const state = yield select()
      yield deleteIdContact(currentData.id)

      const newData = state.adminPanel.contact.contact.data.filter(
        (item) => item.id !== currentData.id
      )

      yield put(actions.setPageContact({ data: newData }))
    } catch (e) {
      console.log(e)
      yield put(setErrorPageContact())
    }
  })

  yield takeLatest(actionTypes.setEnabledIdContact, function* (currentData) {
    try {
      const state = yield select()
      yield enableIdContact(currentData.id)
      const newData = state.adminPanel.contact.contact.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'enabled'
        }
        return item
      })

      yield put(actions.setPageContact({ data: newData }))
    } catch (e) {
      console.log(e)
      yield put(setErrorPageContact())
    }
  })

  yield takeLatest(actionTypes.setDisabledIdContact, function* (currentData) {
    try {
      const state = yield select()
      yield disableIdContact(currentData.id)
      const newData = state.adminPanel.contact.contact.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'disabled'
        }
        return item
      })

      yield put(actions.setPageContact({ data: newData }))
    } catch (e) {
      console.log(e)
      yield put(setErrorPageContact())
    }
  })

  yield takeLatest(actionTypes.getDataContact, function* (currentData) {
    try {
      const { data } = yield getAllData(getListContact, currentData.id, [
        'footer',
      ])
      const initialValues = {
        adress: [{ value: '', id: false, status: true }],
        phones: [{ value: '', id: false, status: true }],
        email: [{ value: '', id: false, status: true }],
        socialNetworks: [
          { value: '', id: false, status: true, type: 'network_twitter' },
        ],
      }
      let newArray = {}
      newArray = initialValues
      data.forEach((item) => {
        if (item.type === 'phone') {
          newArray.phones.unshift(item)
        } else if (item.type === 'email') {
          newArray.email.unshift(item)
        } else if (item.type === 'address') {
          newArray.adress.unshift(item)
        } else {
          newArray.socialNetworks.unshift(item)
        }
      })

      yield put(actions.setPageContact(newArray))
    } catch (e) {
      console.log(e)
      yield put(setErrorPageContact())
    }
  })
}
