import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects' // select
import { getStaticPagesBySlug } from './staticCrud'
import { getSeoDataStart } from '../../../components/_redux/layoutRedux'

export const actionTypes = {
  getStaticPageStart: 'GET_STATIC_PAGE_START',
  getStaticPageSuccess: 'GET_STATIC_PAGE_SUCCESS',
  setPageLoading: 'SET_PAGE_LOADING',
  setPageRedirect: 'SET_PAGE_REDIRECT',
}

const initialState = {
  page: {
    id: null,
    slug: '',
    content: '',
    status: '',
  },
  redirect: false,
  loading: true,
}

export const reducer = persistReducer(
  {
    storage,
    key: 'site_static_page',
    blacklist: ['page', 'redirect', 'loading'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.getStaticPageSuccess: {
        return {
          ...state,
          ...action.payload,
        }
      }
      case actionTypes.setPageLoading: {
        return {
          ...state,
          ...action.payload,
        }
      }
      case actionTypes.setPageRedirect: {
        return {
          ...state,
          ...action.payload,
        }
      }
      default:
        return state
    }
  }
)

export const getStaticPageStart = (slug, history) => ({
  type: actionTypes.getStaticPageStart,
  payload: { slug, history },
})

export const getStaticPageSuccess = (data) => ({
  type: actionTypes.getStaticPageSuccess,
  payload: {
    page: data,
    loading: false,
    redirect: false,
  },
})

export const setPageLoading = () => ({
  type: actionTypes.setPageLoading,
  payload: { loading: true, redirect: false },
})

export const setPageRedirect = () => ({
  type: actionTypes.setPageRedirect,
  payload: { loading: false, redirect: true },
})

export function* saga() {
  // get static page list
  yield takeLatest(actionTypes.getStaticPageStart, function* (e) {
    const payload = e.payload
    try {
      const { data } = yield getStaticPagesBySlug(payload.slug)

      yield put(getSeoDataStart(data.id))
      yield put(getStaticPageSuccess(data))
    } catch (e) {
      payload.history.push('/error')
    }
  })
}
