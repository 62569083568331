import { put, takeLatest } from 'redux-saga/effects'
import { getReportsCheckouts } from './crud'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  deleteReportsCheckout: 'DELETE_REPORT_CHECKOUT',
  createReportsCheckout: 'CREATE_REPORT_CHECKOUT',
  changeStatusReportsCheckout: 'CHANGE_STATUS_REPORT_CHECKOUT',
  editReportsCheckout: 'EDIT_REPORT_CHECKOUT',
  loadingStop: 'LOADING_STOP_REPORT_CHECKOUT',
  changeStatus: 'CHANGE_STATUS_REPORT_CHECKOUTS',
  getReportsCheckoutsStart: 'GET_REPORT_CHECKOUT_DATA_START',
  getReportsCheckoutsFailure: 'GET_REPORT_CHECKOUT_DATA_FAILURE',
  getReportsCheckoutsSuccess: 'GET_REPORT_CHECKOUTS_SUCCESS',
  setFilterForCheckouts: 'SET_REPORT_CHECKOUT_FILTER',
  setFilterForCheckoutsDefault: 'SET_REPORT_CHECKOUT_FILTER_DEFAULT',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: false,
  error: false,
  disabled: false,
  globalLoading: true,
  filter: {
    products: null,
    valid_to: null,
    valid_from: null,
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getReportsCheckoutsStart: {
      return {
        ...state,
        data: [],
        isLoading: true,
      }
    }
    case actionTypes.getReportsCheckoutsFailure: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case actionTypes.setFilterForCheckoutsDefault: {
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      }
    }

    case actionTypes.setFilterForCheckouts: {
      return {
        ...state,
        filter: {
          ...action.payload,
        },
      }
    }

    case actionTypes.getReportsCheckoutsSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const actions = {
  getReportsCheckoutsStart: (page, filter) => ({
    type: actionTypes.getReportsCheckoutsStart,
    page,
    filter,
  }),
  getReportsCheckoutsFailure: () => ({
    type: actionTypes.getReportsCheckoutsFailure,
  }),
  getReportsCheckoutsSuccess: (data) => ({
    type: actionTypes.getReportsCheckoutsSuccess,
    payload: data,
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.getReportsCheckoutsStart, function* (e) {
    try {
      const { data } = yield getReportsCheckouts(e.page, e.filter)
      yield put(actions.getReportsCheckoutsSuccess(data))
    } catch (e) {
      yield put(actions.getReportsCheckoutsFailure())
      errorParser(e.response)
    }
  })
}
