export const getAllData = async (api, page = 1, params = []) => {
  return api(page, ...params)
    .then(async ({ data }) => {
      if (
        data.meta.pager.page !== data.meta.pager.pages &&
        data.meta.pager.pages > data.meta.pager.page
      ) {
        const promises = []
        for (let i = 2; i <= data.meta.pager.pages; i++) {
          promises.push(api(i, ...params))
        }
        await Promise.all(promises).then((res) => {
          const loadedArray = res.reduce((accumulator, current) => {
            return [...accumulator, ...current.data.data]
          }, [])
          data.data = [...data.data, ...loadedArray]
        })
      }
      return data
    })
    .catch((er) => {
      throw er
    })
}
