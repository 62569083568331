import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import {
  getListFooter,
  disableIdFooter,
  enableIdFooter,
  deleteIdFooter,
} from './ccCrud'
import lodash from 'lodash'

export const actionTypes = {
  setDataFooter: 'FOOTER_SET_DATA',
  getDataFooter: 'FOOTER_GET_DATA',
  errorDataFooter: 'FOOTER_ERROR_DATA',
  setDisabledIdFooter: 'FOOTER_SET_DISABLED_ID',
  setEnabledIdFooter: 'FOOTER_SET_ENABLED_ID',
  deleteIdFooter: 'FOOTER_DELETE_ID',
  createFooter: 'FOOTER_CREATE',
  updateIdFooter: 'UPDATE_ID_FOOTER',
}

const initialState = {
  footer: {
    data: [],
    meta: {
      pager: {
        count: 0,
        total: 0,
        per_page: 0,
        page: 0,
        pages: 0,
      },
    },
    error: false,
    isLoading: false,
    disabled: false,
    globalLoading: true,
  },
}

export const reducer = persistReducer(
  {
    storage,
    key: 'footer',
    blacklist: ['footer'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.getDataFooter: {
        return {
          ...state,
          footer: {
            ...state.footer,
            data: [],
            isLoading: true,
            globalLoading: true,
          },
        }
      }
      case actionTypes.setDataFooter: {
        return {
          ...state,
          footer: {
            ...state.footer,
            ...action.payload,
            isLoading: false,
            globalLoading: false,
          },
        }
      }
      case actionTypes.errorDataFooter: {
        return {
          ...state,
          footer: {
            ...state.footer,
            isLoading: false,
            error: true,
            globalLoading: false,
          },
        }
      }
      case actionTypes.deleteIdFooter: {
        return {
          ...state,
          footer: {
            ...state.footer,
            isLoading: true,
            error: false,
          },
        }
      }
      default:
        return state
    }
  }
)
export const getDataFooter = (id = 1) => ({
  type: actionTypes.getDataFooter,
  id,
})

export const setPageFooter = (data) => ({
  type: actionTypes.setDataFooter,
  payload: data,
})

export const setDisabledIdFooter = (id, error = false) => ({
  type: actionTypes.setDisabledIdFooter,
  id,
})

export const setEnabledIdFooter = (id, error = false) => ({
  type: actionTypes.setEnabledIdFooter,
  id,
})

export const setErrorPageFooter = () => ({
  type: actionTypes.errorDataFooter,
})

export const actions = {
  setPageFooter: (data) => ({
    type: actionTypes.setDataFooter,
    payload: data,
  }),
}

export const deleteFooter = (id) => ({
  type: actionTypes.deleteIdFooter,
  id,
})

export const createDataFooter = (data) => ({
  type: actionTypes.createFooter,
  data,
})

export const editIdDataFooter = ({ id, data }) => ({
  type: actionTypes.updateIdFooter,
  data,
  id,
})

export function* saga() {
  // createFooter

  yield takeLatest(actionTypes.updateIdFooter, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.footer.footer.data.map((item) => {
        if (item.id === currentData.id) {
          item = {
            ...item,
            ...currentData.data,
          }
        }
        return item
      })

      yield put(actions.setPageFooter({ data: newData }))
    } catch (e) {
      console.log(e)
      yield put(setErrorPageFooter())
    }
  })

  yield takeEvery(actionTypes.deleteIdFooter, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.footer.footer.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })

      yield put(actions.setPageFooter({ data: newData }))
      yield deleteIdFooter(currentData.id)

      newData = state.adminPanel.footer.footer.data.filter(
        (item) => item.id !== currentData.id
      )

      yield put(actions.setPageFooter({ data: newData }))
    } catch (e) {
      const state = yield select()
      const newData = state.adminPanel.footer.footer.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })

      yield put(actions.setPageFooter({ data: newData }))
      yield put(setErrorPageFooter())
    }
  })

  yield takeEvery(actionTypes.setEnabledIdFooter, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.footer.footer.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.setPageFooter({ data: newData }))
      yield enableIdFooter(currentData.id)
      newData = state.adminPanel.footer.footer.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })

      yield put(actions.setPageFooter({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.footer.footer.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.setPageFooter({ data: newData }))
      }
      yield put(setErrorPageFooter())
    }
  })

  yield takeEvery(actionTypes.setDisabledIdFooter, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.footer.footer.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.setPageFooter({ data: newData }))
      yield disableIdFooter(currentData.id)
      newData = state.adminPanel.footer.footer.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'disabled'
          item.loader = false
        }
        return item
      })

      yield put(actions.setPageFooter({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.footer.footer.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.setPageFooter({ data: newData }))
      }
      yield put(setErrorPageFooter())
    }
  })

  yield takeLatest(actionTypes.getDataFooter, function* (currentData) {
    try {
      const { data } = yield getListFooter(currentData.id)
      yield put(actions.setPageFooter(data))
    } catch (e) {
      yield put(setErrorPageFooter())
    }
  })
}
