import axios from 'axios'

export const getContactData = () => {
  return axios.get(`/contacts?placement=contact_page`)
}

export const getRequsitesData = () => {
  return axios.get(`/general/requisites`)
}

export const getRecordsData = () => {
  return axios.get('/general/info/records', {
    params: {
      keys: [
        'google-map-place-latitude',
        'google-map-place-longitude',
        'timework',
      ],
    },
  })
}
