import React from 'react'
import ReactDOM from 'react-dom'
import { ReactComponent } from 'react-formio'
import settingsForm from './Promocode.settingsForm'
import './productInfo.css'
import ProductInfo from './ProductInfo'

export default class ProductInfoComponent extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Описание продукта',
      icon: 'square',
      group: 'customBasic',
      documentation: '',
      weight: -10,
      schema: ProductInfoComponent.schema(),
    }
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'product_info',
      label: '',
      validate: {
        required: true,
      },
    })
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm() {
    return settingsForm([])
  }

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <ProductInfo
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={(e) => this.updateValue(e)} // The onChange event to call when the value changes.
        {...this}
      />,
      element
    )
  }

  getValue() {
    return null
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element)
    }
  }
}
