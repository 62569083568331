import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import {
  publishIndustries,
  unpublishIndustries,
  deleteIndustries,
  getIndustries,
  getProductByIndustryId,
  getBlockIndustries,
  disableBlockIndustries,
  enableBlockIndustries,
  deleteBlockIndustries,
  deleteCardIndustries,
  enableCardIndustries,
  disableCardIndustries,
  getCardIndustries,
} from './crud'
import lodash from 'lodash'

import { getAllData } from '../../../helpers/api'

export const actionTypes = {
  setData: 'INDUSTRY_SET_INDUSTRY_LIST',
  getData: 'INDUSTRY_GET_INDUSTRY_LIST',
  errorData: 'INDUSTRY_GET_INDUSTRY_LIST_ERROR',
  deleteIndustries: 'INDUSTRY_DELETE_ID',
  blockIndustries: 'INDUSTRY_BLOCK_ID',
  unBlockIndustries: 'INDUSTRY_UNBLOCK_ID',
  changeDataId: 'INDUSTRY_CHANGE_DATA',

  getDataProduct: 'INDUSTRY_GET_DATA_PRODUCT_PRODUCT',
  setDataProduct: 'INDUSTRY_SET_DATA_PRODUCT_PRODUCT',

  setDataBlock: 'INDUSTRY_SET_INDUSTRY_LIST_BLOCK',
  getDataBlock: 'INDUSTRY_GET_INDUSTRY_LIST_BLOCK',
  errorDataBlock: 'INDUSTRY_GET_INDUSTRY_LIST_BLOCK_ERROR',
  deleteIndustriesBlock: 'INDUSTRY_DELETE_ID_BLOCK',
  blockIndustriesBlock: 'INDUSTRY_BLOCK_ID_BLOCK',
  unBlockIndustriesBlock: 'INDUSTRY_UNBLOCK_ID_BLOCK',
  changeDataIdBlock: 'INDUSTRY_CHANGE_DATA_BLOCK',

  setDataCards: 'INDUSTRY_SET_INDUSTRY_LIST_CARDS',
  getDataCards: 'INDUSTRY_GET_INDUSTRY_LIST_CARDS',
  errorDataCards: 'INDUSTRY_GET_INDUSTRY_LIST_CARDS_ERROR',
  deleteIndustriesCards: 'INDUSTRY_DELETE_ID_CARDS',
  blockIndustriesCards: 'INDUSTRY_BLOCK_ID_CARDS',
  unBlockIndustriesCards: 'INDUSTRY_UNBLOCK_ID_CARDS',
  changeDataIdCards: 'INDUSTRY_CHANGE_DATA_CARDS',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: false,
  error: false,
  disabled: false,
  globalLoading: true,
  product: {
    data: [],
    loader: false,
  },
  blocksId: {
    data: [],
    meta: {
      pager: {
        count: 0,
        total: 0,
        per_page: 0,
        page: 0,
        pages: 0,
      },
    },
    isLoading: false,
    error: false,
  },
  cardsId: {
    data: [],
    meta: {
      pager: {
        count: 0,
        total: 0,
        per_page: 0,
        page: 0,
        pages: 0,
      },
    },
    isLoading: false,
    error: false,
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setDataBlock: {
      return {
        ...state,
        blocksId: {
          ...state.blocksId,
          ...action.payload,
          isLoading: false,
          error: false,
        },
      }
    }
    case actionTypes.getDataBlock: {
      return {
        ...state,
        blocksId: {
          ...state.blocksId,
          isLoading: true,
          error: false,
        },
      }
    }
    case actionTypes.setDataCards: {
      return {
        ...state,
        cardsId: {
          ...state.cardsId,
          ...action.payload,
          isLoading: false,
          error: false,
        },
      }
    }
    case actionTypes.getDataCards: {
      return {
        ...state,
        cardsId: {
          ...state.cardsId,
          isLoading: true,
          error: false,
        },
      }
    }
    case actionTypes.getData: {
      return {
        ...state,
        data: [],
        isLoading: true,
        globalLoading: true,
      }
    }
    case actionTypes.setDataProduct: {
      return {
        ...state,
        product: {
          data: action.payload,
          loader: false,
        },
      }
    }
    case actionTypes.getDataProduct: {
      return {
        ...state,
        product: {
          data: [],
          loader: true,
        },
      }
    }
    case actionTypes.setData: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: false,
        disabled: false,
        globalLoading: false,
      }
    }

    case actionTypes.errorData: {
      return {
        ...state,
        isLoading: false,
        error: true,
        disabled: false,
        globalLoading: false,
      }
    }

    case actionTypes.deleteIndustries: {
      return {
        ...state,
        isLoading: true,
        error: false,
        disabled: true,
      }
    }

    case actionTypes.blockIndustries:
      return { ...state, disabled: true, isLoading: true, error: false }
    case actionTypes.unBlockIndustries:
      return { ...state, disabled: true, isLoading: true, error: false }
    default:
      return state
  }
}

export const getDataCards = (id, page = 1) => ({
  type: actionTypes.getDataCards,
  id,
  page,
})

export const setDataCards = (data) => ({
  type: actionTypes.setDataCards,
  payload: data,
})

export const changeDataIdCards = (data) => ({
  type: actionTypes.changeDataIdCards,
  data,
})

export const deleteIndustryCards = (id, history) => ({
  type: actionTypes.deleteIndustriesCards,
  id,
  history,
})

export const blockIndustriesCards = (id, error = false) => ({
  type: actionTypes.blockIndustriesCards,
  id,
  error,
})

export const unBlockIndustriesCards = (id, error = false) => ({
  type: actionTypes.unBlockIndustriesCards,
  id,
  error,
})

export const getDataBlock = (id, page = 1) => ({
  type: actionTypes.getDataBlock,
  id,
  page,
})

export const setDataBlock = (data) => ({
  type: actionTypes.setDataBlock,
  payload: data,
})

export const changeDataIdBlock = (data) => ({
  type: actionTypes.changeDataIdBlock,
  data,
})

export const deleteIndustryBlock = (id, history) => ({
  type: actionTypes.deleteIndustriesBlock,
  id,
  history,
})

export const blockIndustriesBlock = (id, error = false) => ({
  type: actionTypes.blockIndustriesBlock,
  id,
  error,
})

export const unBlockIndustriesBlock = (id, error = false) => ({
  type: actionTypes.unBlockIndustriesBlock,
  id,
  error,
})

export const getData = (id = 1) => ({
  type: actionTypes.getData,
  id,
})

export const getDataProduct = (industry_id = '') => ({
  type: actionTypes.getDataProduct,
  industry_id,
})

export const setDataProduct = (data) => ({
  type: actionTypes.setDataProduct,
  payload: data,
})

export const setData = (data) => ({
  type: actionTypes.setData,
  payload: data,
})

export const deleteIndustry = (id, history) => ({
  type: actionTypes.deleteIndustries,
  id,
  history,
})

export const blockIndustries = (id, error = false) => ({
  type: actionTypes.blockIndustries,
  id,
  error,
})

export const unBlockIndustries = (id, error = false) => ({
  type: actionTypes.unBlockIndustries,
  id,
  error,
})

export const changeDataId = (data) => ({
  type: actionTypes.changeDataId,
  data,
})

export function* saga() {
  yield takeLatest(actionTypes.changeDataId, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.data.id === item.id) {
          item = currentData.data
        }
        return item
      })

      yield put(setData({ data: newData }))
    } catch (e) {
      // yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.blockIndustries, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(setData({ data: newData }))

      yield unpublishIndustries(currentData.id)
      newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'disable'
          item.loader = false
        }
        return item
      })

      yield put(setData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = false
        }
        return item
      })

      yield put(setData({ data: newData }))
    }
  })

  yield takeEvery(actionTypes.unBlockIndustries, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })
      yield put(setData({ data: newData }))
      yield publishIndustries(currentData.id)
      newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'published'
          item.loader = false
        }
        return item
      })
      yield put(setData({ data: newData }))
    } catch (e) {
      console.log(e)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = false
        }
        return item
      })
      yield put(setData({ data: newData }))
    }
  })

  yield takeEvery(actionTypes.deleteIndustries, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })
      yield put(setData({ data: newData }))
      if (currentData.history) {
        currentData.history.push('/admin/content/service/')
      } else {
        yield deleteIndustries(currentData.id)
      }
      const data = state.adminPanel.industry.data.filter(
        (item) => item.id !== currentData.id
      )

      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      if (state.adminPanel.industry.meta.pager.page === 0) {
        return yield put(getData(1))
      }

      const meta = (state.adminPanel.industry.meta.pager = {
        ...state.adminPanel.industry.meta.pager,
        total: state.adminPanel.industry.meta.pager.total - 1,
        per_page: state.adminPanel.industry.meta.pager.per_page,
        pages: state.adminPanel.industry.meta.pager.pages,
      })

      if (meta.pages !== 1 && meta.pages - 1 >= meta.total / meta.per_page) {
        meta.pages = meta.pages - 1
      }

      yield put(setData({ data: data, meta: { pager: meta } }))

      if (!data.length) {
        if (meta.total !== 0) {
          if (meta.pages !== meta.page) {
            if (meta.page > meta.pages) {
              yield put(getData(meta.page - 1))
            } else {
              yield put(getData(meta.page))
            }
          } else {
            yield put(getData(Math.ceil(meta.total / meta.per_page)))
          }
        }
      }
    } catch (e) {
      console.log(e)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })
      yield put(setData({ data: newData }))
    }
  })

  yield takeLatest(actionTypes.getData, function* (currentData) {
    try {
      const { data } = yield getIndustries(currentData.id)

      yield put(setData(data))
    } catch (e) {}
  })

  yield takeLatest(actionTypes.getDataProduct, function* (e) {
    try {
      const { data } = yield getAllData(getProductByIndustryId, 1, [
        e.industry_id,
      ])

      yield put(setDataProduct(data))
    } catch (e) {}
  })

  yield takeLatest(actionTypes.getDataBlock, function* (currentData) {
    try {
      const { data } = yield getBlockIndustries(
        currentData.id,
        currentData.page
      )

      yield put(setDataBlock(data))
    } catch (e) {}
  })

  yield takeEvery(actionTypes.blockIndustriesBlock, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.industry.blocksId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(setDataBlock({ data: newData }))

      yield disableBlockIndustries(currentData.id.id, currentData.id.block)
      newData = state.adminPanel.industry.blocksId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.status = 'disable'
          item.loader = false
        }
        return item
      })

      yield put(setDataBlock({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industry.blocksId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.loader = false
        }
        return item
      })

      yield put(setDataBlock({ data: newData }))
    }
  })

  yield takeEvery(actionTypes.unBlockIndustriesBlock, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.industry.blocksId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.loader = true
        }
        return item
      })
      yield put(setDataBlock({ data: newData }))
      yield enableBlockIndustries(currentData.id.id, currentData.id.block)
      newData = state.adminPanel.industry.blocksId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })
      yield put(setDataBlock({ data: newData }))
    } catch (e) {
      console.log(e)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industry.blocksId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.loader = false
        }
        return item
      })
      yield put(setDataBlock({ data: newData }))
    }
  })

  yield takeEvery(actionTypes.deleteIndustriesBlock, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.delete = true
        }
        return item
      })
      yield put(setDataBlock({ data: newData }))
      if (currentData.history) {
        currentData.history.push('/admin/content/service/')
      } else {
        yield deleteBlockIndustries(currentData.id.id, currentData.id.block)
      }
      const data = state.adminPanel.industry.blocksId.data.filter(
        (item) => item.id !== currentData.id.block
      )

      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      if (state.adminPanel.industry.blocksId.meta.pager.page === 0) {
        return yield put(getDataBlock(1))
      }

      const meta = (state.adminPanel.industry.blocksId.meta.pager = {
        ...state.adminPanel.industry.blocksId.meta.pager,
        total: state.adminPanel.industry.blocksId.meta.pager.total - 1,
        per_page: state.adminPanel.industry.blocksId.meta.pager.per_page,
        pages: state.adminPanel.industry.blocksId.meta.pager.pages,
      })

      if (meta.pages !== 1 && meta.pages - 1 >= meta.total / meta.per_page) {
        meta.pages = meta.pages - 1
      }

      yield put(setDataBlock({ data: data, meta: { pager: meta } }))

      if (!data.length) {
        if (meta.total !== 0) {
          if (meta.pages !== meta.page) {
            if (meta.page > meta.pages) {
              yield put(getDataBlock(meta.page - 1))
            } else {
              yield put(getDataBlock(meta.page))
            }
          } else {
            yield put(getDataBlock(Math.ceil(meta.total / meta.per_page)))
          }
        }
      }
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industry.blocksId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.delete = false
        }
        return item
      })
      yield put(getDataBlock({ data: newData }))
    }
  })

  yield takeLatest(actionTypes.changeDataIdBlock, function* (currentData) {
    const state = yield select()
    const newData = state.adminPanel.industry.blocksId.data.map((item) => {
      if (currentData.data.id === item.id) {
        item = currentData.data
      }
      return item
    })

    yield put(setDataBlock({ data: newData }))
  })

  yield takeLatest(actionTypes.getDataCards, function* (currentData) {
    try {
      const { data } = yield getCardIndustries(currentData.id, currentData.page)

      yield put(setDataCards(data))
    } catch (e) {}
  })

  yield takeEvery(actionTypes.blockIndustriesCards, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.industry.cardsId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(setDataCards({ data: newData }))

      yield disableCardIndustries(currentData.id.id, currentData.id.block)
      newData = state.adminPanel.industry.cardsId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.status = 'disable'
          item.loader = false
        }
        return item
      })

      yield put(setDataCards({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industry.cardsId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.loader = false
        }
        return item
      })

      yield put(setDataCards({ data: newData }))
    }
  })

  yield takeEvery(actionTypes.unBlockIndustriesCards, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.industry.cardsId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.loader = true
        }
        return item
      })
      yield put(setDataCards({ data: newData }))
      yield enableCardIndustries(currentData.id.id, currentData.id.block)
      newData = state.adminPanel.industry.cardsId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })
      yield put(setDataCards({ data: newData }))
    } catch (e) {
      console.log(e)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industry.cardsId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.loader = false
        }
        return item
      })
      yield put(setDataCards({ data: newData }))
    }
  })

  yield takeEvery(actionTypes.deleteIndustriesCards, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.industry.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.delete = true
        }
        return item
      })
      yield put(setDataCards({ data: newData }))
      if (currentData.history) {
        currentData.history.push('/admin/content/service/')
      } else {
        yield deleteCardIndustries(currentData.id.id, currentData.id.block)
      }
      const data = state.adminPanel.industry.cardsId.data.filter(
        (item) => item.id !== currentData.id.block
      )

      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      if (state.adminPanel.industry.cardsId.meta.pager.page === 0) {
        return yield put(getDataCards(1))
      }

      const meta = (state.adminPanel.industry.cardsId.meta.pager = {
        ...state.adminPanel.industry.cardsId.meta.pager,
        total: state.adminPanel.industry.cardsId.meta.pager.total - 1,
        per_page: state.adminPanel.industry.cardsId.meta.pager.per_page,
        pages: state.adminPanel.industry.cardsId.meta.pager.pages,
      })

      if (meta.pages !== 1 && meta.pages - 1 >= meta.total / meta.per_page) {
        meta.pages = meta.pages - 1
      }

      yield put(setDataCards({ data: data, meta: { pager: meta } }))

      if (!data.length) {
        if (meta.total !== 0) {
          if (meta.pages !== meta.page) {
            if (meta.page > meta.pages) {
              yield put(getDataCards(meta.page - 1))
            } else {
              yield put(getDataCards(meta.page))
            }
          } else {
            yield put(getDataCards(Math.ceil(meta.total / meta.per_page)))
          }
        }
      }
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industry.cardsId.data.map((item) => {
        if (currentData.id.block === item.id) {
          item.delete = false
        }
        return item
      })
      yield put(getDataCards({ data: newData }))
    }
  })

  yield takeLatest(actionTypes.changeDataIdCards, function* (currentData) {
    const state = yield select()
    const newData = state.adminPanel.industry.cardsId.data.map((item) => {
      if (currentData.data.id === item.id) {
        item = currentData.data
      }
      return item
    })

    yield put(setDataCards({ data: newData }))
  })
}
