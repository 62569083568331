import axios from 'axios'

export const getProductBoundary = (productId, coverageId) => {
  return axios.get(
    `/query/products/${productId}/coverages/${coverageId}/boundary`
  )
}

export const getListCoverages = (page = 1, productId, status = 'enabled') => {
  return axios.get(
    `/admin/products/${productId}/coverages?status=${status}&page=${page}`
  )
}

export const getCoverageInfo = (productId, id) => {
  return axios.get(`products/${productId}/coverages/${id}`)
}

export const getListRisks = (page = 1, productId, coverageId) => {
  return axios.get(
    `/products/${productId}/coverages/${coverageId}/risks?page=${page}`
  )
}

export const getListDictionaries = (
  page = 1,
  productId,
  type,
  status = 'enabled'
) => {
  return axios.get(
    `/admin/products/${productId}/dictionaries?page=${page}&type=${type}&status=${status}`
  )
}

export const getListDictionariesSite = (productId, key) => {
  return axios.get(`/products/${productId}/dictionaries/${key}`)
}

export const getDictionaryValuesSite = (page = 1, productId, key) => {
  return axios.get(
    `/products/${productId}/dictionaries/${key}/values?page=${page}`
  )
}

export const getGeneralDictionarySite = (page = 1) => {
  return axios.get(`/dictionaries?page=${page}`)
}
export const getGeneralDictionaryValuesSite = (page = 1, key) => {
  return axios.get(`/dictionaries/${key}/values?page=${page}`)
}

export const checkPromocode = (code) => {
  return axios.get(`/promocodes/${code}`)
}

export const getProductInfo = (template_id, company_id, product_id, filter) => {
  return axios.post(
    `/query/products/${template_id}/companies/${company_id}/products/${product_id}`,
    { filter: filter }
  )
}

export const getEngineReProcessProduct = (product_id, company_id, data) => {
  return axios.post(
    `/engine/products/${product_id}/re-process/${company_id}`,
    data
  )
}

export const checkPhoneValidation = (phone) => {
  return axios.post(`/insurant/otp/request`, { phone: phone })
}

export const checkSmsCodeValidation = (phone, code) => {
  return axios.post(`/insurant/otp/confirm`, { phone: phone, code: code })
}

export const checkPhoneCountryCode = (countryCode) => {
  return axios.post(`/dictionaries/telefonnye-kody/validate`, {
    value: countryCode,
  })
}

export const getDadataAddress = (key) => {
  return axios.get(`/dadata/address?key=${key}`)
}

export const getDadataParty = (key) => {
  return axios.get(`/dadata/party?key=${key}`)
}

export const getDadataFms = (key) => {
  return axios.get(`/dadata/fms-unit?key='${key}'`)
}
