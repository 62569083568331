export const getAllData = async (api, params = {}) => {
  return api(1, params)
    .then(async ({ data }) => {
      if (
        data.meta.pager.page !== data.meta.pager.pages &&
        data.meta.pager.pages > data.meta.pager.page
      ) {
        const promises = []
        for (let i = 2; i <= data.meta.pager.pages; i++) {
          promises.push(api(i))
        }
        const loadedArray = []
        await Promise.all(promises).then((res) => {
          res.map((item) => {
            loadedArray.push(...item?.data?.data)
          })
          data.data = [...data.data, ...loadedArray]
        })
      }
      return data
    })
    .catch((er) => {
      throw er
    })
}
