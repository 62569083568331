import { put, takeLatest } from 'redux-saga/effects'
import { getReportsQueries } from './crud'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  deleteReportsQuerie: 'DELETE_REPORT_QUERIE',
  createReportsQuerie: 'CREATE_REPORT_QUERIE',
  editReportsQuerie: 'EDIT_REPORT_QUERIE',
  loadingStop: 'LOADING_STOP_REPORT_QUERIE',
  changeStatus: 'CHANGE_STATUS_REPORT_QUERIES',
  getReportsQueriesStart: 'GET_REPORT_QUERIE_DATA_START',
  getReportsQueriesSuccess: 'GET_REPORT_QUERIES_SUCCESS',
  setFilterForQueries: 'SET_REPORT_QUERIE_FILTER',
  setFilterForQueriesDefault: 'SET_REPORT_QUERIE_FILTER_DEFAULT',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: false,
  error: false,
  disabled: false,
  globalLoading: true,
  filter: {
    products: null,
    valid_to: null,
    valid_from: null,
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getReportsQueriesStart: {
      return {
        ...state,
        data: [],
        isLoading: true,
      }
    }

    case actionTypes.setFilterForQueriesDefault: {
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      }
    }

    case actionTypes.setFilterForQueries: {
      return {
        ...state,
        filter: {
          ...action.payload,
        },
      }
    }

    case actionTypes.getReportsQueriesSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const actions = {
  getReportsQueriesStart: (page, filter) => ({
    type: actionTypes.getReportsQueriesStart,
    page,
    filter,
  }),
  getReportsQueriesSuccess: (data) => ({
    type: actionTypes.getReportsQueriesSuccess,
    payload: data,
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.getReportsQueriesStart, function* (e) {
    try {
      console.log(e)
      const { data } = yield getReportsQueries(e.page, e.filter)
      yield put(actions.getReportsQueriesSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
}
