import axios from 'axios'

const path = '/admin/promocodes'

export function getPromocodess(page = 1) {
  return axios.get(`${path}?page=${page}`)
}

export function deletePromocodes(id) {
  return axios.delete(`${path}/${id}`)
}

export function activePromocode(id) {
  return axios.patch(`${path}/${id}/activate`)
}

export function editPromocodes(id, data) {
  return axios.put(`${path}/${id}`, data)
}

export function showPromocodeId(id) {
  return axios.get(`${path}/${id}`)
}

export function createPromocodes(data) {
  return axios.post(`${path}`, data)
}

export function usedPromocode(id) {
  return axios.patch(`${path}/${id}/used`)
}

export function draftPromocode(id) {
  return axios.patch(`${path}/${id}/draft`)
}

export function getListCompanyProducts(page = 1, companyId) {
  return axios.get(`/admin/companies/${companyId}/products?page=${page}`)
}
export function promocodesExport(data) {
  return axios.post(`${path}/export`, data)
}

export function promocodesImport(data) {
  return axios.post(`${path}/import`, data)
}
