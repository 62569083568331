import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deleteProductRisk,
  getProductRisks,
  blockProductRisk,
  unBlockProductRisk,
  createProductRisk,
  editProductRisk,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  getRisksDataStart: 'GET_RISKS_DATA_START',
  getRisksDataSuccess: 'GET_RISKS_DATA_SUCCESS',
  deleteRisk: 'DELETE_RISK',
  blockRisk: 'BLOCK_RISK',
  unBlockRisk: 'UN_BLOCK_RISK',
  createRisk: 'CREATE_RISK',
  editRisk: 'EDIT_RISK',
  loadingStop: 'LOADING_STOP',
}

const initialAuthState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.getRisksDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getRisksDataStart: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.unBlockRisk: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.blockRisk: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const getRisksActionStart = (product_id, coverage_id, page) => ({
  type: actionTypes.getRisksDataStart,
  product_id,
  coverage_id,
  page,
})

export const getRisksActionSuccess = (data) => ({
  type: actionTypes.getRisksDataSuccess,
  payload: data,
})

export const deleteRiskAction = (product_id, coverage_id, id) => ({
  type: actionTypes.deleteRisk,
  product_id,
  coverage_id,
  id,
})

export const blockRiskAction = (product_id, coverage_id, id) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.blockRisk,
      product_id,
      coverage_id,
      id,
      resolve,
    })
  })
}

export const unBlockRiskAction = (product_id, coverage_id, id) => (
  dispatch
) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.unBlockRisk,
      product_id,
      coverage_id,
      id,
      resolve,
    })
  })
}

export const createRiskAction = (product_id, coverage_id, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.createRisk,
      product_id,
      coverage_id,
      data,
      resolve,
      reject,
    })
  })
}

export const editRiskAction = (product_id, coverage_id, id, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.editRisk,
      product_id,
      coverage_id,
      id,
      data,
      resolve,
      reject,
    })
  })
}

export const loadingStopAction = () => ({
  type: actionTypes.loadingStop,
})

export function* saga() {
  yield takeLatest(actionTypes.getRisksDataStart, function* (e) {
    try {
      const { data } = yield getProductRisks(
        e.product_id,
        e.coverage_id,
        e.page
      )

      yield put(getRisksActionSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.blockRisk, function* (e) {
    try {
      const {
        adminPanel: { productRisks },
      } = yield select()
      yield blockProductRisk(e.product_id, e.coverage_id, e.id)

      yield put(
        getRisksActionStart(
          e.product_id,
          e.coverage_id,
          productRisks.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    } finally {
      yield put(loadingStopAction())
      e.resolve()
    }
  })
  yield takeLatest(actionTypes.unBlockRisk, function* (e) {
    try {
      const {
        adminPanel: { productRisks },
      } = yield select()
      yield unBlockProductRisk(e.product_id, e.coverage_id, e.id)

      yield put(
        getRisksActionStart(
          e.product_id,
          e.coverage_id,
          productRisks.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    } finally {
      yield put(loadingStopAction())
      e.resolve()
    }
  })

  yield takeLatest(actionTypes.deleteRisk, function* (e) {
    try {
      const {
        adminPanel: {
          productRisks: { meta },
        },
      } = yield select()

      yield deleteProductRisk(e.product_id, e.coverage_id, e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Риск успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(getRisksActionStart(e.product_id, e.coverage_id, page))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createRisk, function* ({
    product_id,
    coverage_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createProductRisk(product_id, coverage_id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editRisk, function* ({
    product_id,
    coverage_id,
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editProductRisk(product_id, coverage_id, id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
