import axios from 'axios'

const path_slider_main = '/admin/slider/main'
const path_slider_best_offer = '/admin/slider/best-offer'

// http://localhost:8001/api/v1/admin/slider/main/slides

export function createMainSlider(data) {
  return axios.post(`${path_slider_main}/slides`, data)
}
// editMainSlider

export function editMainSlider(id, data) {
  return axios.put(`${path_slider_main}/slides/${id}`, data)
}

export function editPositionMainSlider(id, data) {
  return axios.patch(`${path_slider_main}/slides/${id}/position`, data)
}

export function editImageMainSlider(id, data) {
  return axios.post(`${path_slider_main}/slides/${id}/images`, data)
}

export function getIdMainSlider(id) {
  return axios.get(`${path_slider_main}/slides/${id}`)
}

export function deleteMainSlider(id) {
  return axios.delete(`${path_slider_main}/slides/${id}`)
}

export function getPagesMainSlider(page) {
  return axios.get(`${path_slider_main}/slides/page?=${page}`)
}

export function blockedSliderMain(id) {
  return axios.patch(`${path_slider_main}/slides/${id}/disable`)
}
export function unBlockedSliderMain(id) {
  return axios.patch(`${path_slider_main}/slides/${id}/enable`)
}

export function getListSliderMain(page) {
  return axios.get(`${path_slider_main}/slides?page=${page}`)
}

// best offer

export function createBestSlider(data) {
  return axios.post(`${path_slider_best_offer}/slides`, data)
}
// editMainSlider

export function editBestSlider(id, data) {
  return axios.put(`${path_slider_best_offer}/slides/${id}`, data)
}

export function editPositionBestSlider(id, data) {
  return axios.patch(`${path_slider_best_offer}/slides/${id}/position`, data)
}

export function editImageBestSlider(id, data) {
  return axios.post(`${path_slider_best_offer}/slides/${id}/images`, data)
}

export function getIdBestSlider(id) {
  return axios.get(`${path_slider_best_offer}/slides/${id}`)
}

export function deleteBestSlider(id) {
  return axios.delete(`${path_slider_best_offer}/slides/${id}`)
}

export function getPagesBestSlider(page) {
  return axios.get(`${path_slider_best_offer}/slides/page?=${page}`)
}

export function blockedSliderBest(id) {
  return axios.patch(`${path_slider_best_offer}/slides/${id}/disable`)
}
export function unBlockedSliderBest(id) {
  return axios.patch(`${path_slider_best_offer}/slides/${id}/enable`)
}

export function getListSliderBest(page) {
  return axios.get(`${path_slider_best_offer}/slides?page=${page}`)
}
