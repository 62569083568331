const projectConfig = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  apiUrl: process.env.REACT_APP_API_URL + '/api/v1',
  resetPasswordUrl:
    process.env.REACT_APP_BASE_URL + '/admin/auth/reset-password',
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  registerUser: process.env.REACT_APP_BASE_URL + '/admin/auth/register',
  resetEmailProfileUrl:
    process.env.REACT_APP_BASE_URL + '/admin/profile/change-email',
  widgetFileUrl: process.env.REACT_APP_WIDGET_URL + '/index.js',
}

export default projectConfig
