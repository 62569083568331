import React from 'react'
import classNames from 'classnames'

// default this btn is blue and normal size
const Button = (props) => {
  const {
    small,
    big,
    yellow,
    onClick,
    children,
    className,
    fullWidth,
    disabled = false,
  } = props

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'btn',
        { yellow: yellow, small, big, fullWidth },
        className
      )}
    >
      {children}
    </button>
  )
}

export default Button
