import React, { Component } from 'react'

export default class Spinner extends Component {
  render() {
    const { diamter = '12', radius = '10', stroke = '3' } = this.props
    return (
      <svg className="loaded-spinner">
        <circle
          className="path"
          cx={diamter}
          cy={diamter}
          r={radius}
          fill="none"
          strokeWidth={stroke}
        ></circle>
      </svg>
    )
  }
}
