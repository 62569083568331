import { put, takeLatest } from 'redux-saga/effects'
import {
  deleteWidget,
  createWidget,
  editWidget,
  unBlockWidget,
  blockWidget,
  getWidgets,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  deleteWidget: 'DELETE_WIDGET',
  createWidget: 'CREATE_WIDGET',
  changeStatusWidget: 'CHANGE_STATUS_WIDGET',
  editWidget: 'EDIT_WIDGET',
  loadingStop: 'LOADING_STOP_WIDGET',
  changeStatus: 'CHANGE_STATUS_WIDGETS',
  getWidgetsStart: 'GET_WIDGET_DATA_START',
  getWidgetsSuccess: 'GET_WIDGETS_SUCCESS',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getWidgetsStart: {
      return {
        ...state,
        data: [],
        isLoading: true,
      }
    }

    case actionTypes.getWidgetsSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const actions = {
  deleteWidget: () => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.deleteWidget,
        resolve,
        reject,
      })
    })
  },
  createWidget: (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.createWidget,
        data,
        resolve,
        reject,
      })
    })
  },
  editWidget: (id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.editWidget,
        id,
        data,
        resolve,
        reject,
      })
    })
  },
  changeStatusWidget: (status) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.changeStatusWidget,
        status,
        resolve,
        reject,
      })
    })
  },
  getWidgetsStart: (page) => ({
    type: actionTypes.getWidgetsStart,
    page,
  }),
  getWidgetsSuccess: (data) => ({
    type: actionTypes.getWidgetsSuccess,
    payload: data,
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.getWidgetsStart, function* (e) {
    try {
      const { data } = yield getWidgets(e.page)

      yield put(actions.getWidgetsSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.deleteWidget, function* (e) {
    try {
      const response = yield deleteWidget()
      e.resolve(response)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Механизм удержания успешно удален!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    } catch (er) {
      errorParser(er.response)
      e.reject(er.response)
    }
  })

  yield takeLatest(actionTypes.createWidget, function* ({
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createWidget(data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editWidget, function* ({
    id,
    data,
    test,
    resolve,
    reject,
  }) {
    try {
      const response = yield editWidget(id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.changeStatusWidget, function* ({
    status,
    resolve,
    reject,
  }) {
    try {
      let response
      if (status === 'enabled') {
        response = yield unBlockWidget()
      } else {
        response = yield blockWidget()
      }
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
