import axios from 'axios'

const PATH_FAQ = '/admin/faq/records'

export function get_faq_show(page = 1) {
  return axios.get(`${PATH_FAQ}?page=${page}`)
}

export function faqCreate(data) {
  return axios.post(`${PATH_FAQ}`, data)
}

export function editFaq(id, data) {
  return axios.put(`${PATH_FAQ}/${id}`, data)
}

export function editPositionFaq(id, data) {
  return axios.patch(`${PATH_FAQ}/${id}/position`, data)
}

export function delete_faq(id) {
  return axios.delete(`${PATH_FAQ}/${id}`)
}

export function publishFaq(id) {
  return axios.patch(`${PATH_FAQ}/${id}/publish`)
}

export function unpublishFaq(id) {
  return axios.patch(`${PATH_FAQ}/${id}/unpublish`)
}

export function showFaq(id) {
  return axios.get(`${PATH_FAQ}/${id}`)
}
