import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { showSeoUrl } from '../../SEO/_redux/crud'
import {
  deleteMainSlider,
  getListSliderMain,
  getListSliderBest,
  blockedSliderMain,
  unBlockedSliderMain,
  unBlockedSliderBest,
  blockedSliderBest,
  deleteBestSlider,
} from '../redux/crud'
import { getAllData } from '../../../helpers/api'
import lodash from 'lodash'
export const actionTypes = {
  setInitialState: 'SET_PAGE_MAIN_INITIAL_VALUES',
  getDataPage: 'GET_DATA_PAGE_MAIN',
  setData: 'SET_DATA_PAGE_MAIN',
  SetLoaderState: 'SET_PAGE_MAIN_LOADER',
  setError: 'PAGE_MAIN_SET_ERROR',
  // setSeoSuccess: 'SEO_SUCCESS',
  deleteItemSlider: 'PAGE_MAIN_DELETE_ID_SLIDER_MAIN',
  setMainSlider: 'SET_MAIN_SLIDER_ITEMS',
  getListSliderMain: 'GET_LIST_SLIDER_MAIN_ALL',
  setListSliderMain: 'SET_LIST_SLIDER_MAIN_ALL',
  setItemSliderMain: 'SET_ITEM_SLIDER_MAIN_ALL',
  getListSliderBest: 'GET_LIST_SLIDER_BEST_ALL',
  setListSliderBest: 'SET_LIST_SLIDER_BEST_ALL',
  setItemSliderBest: 'SET_ITEM_SLIDER_BEST_ALL',
  unBlockSliderBest: 'SET_ITEM_SLIDER_BEST_UNBLOCK',
  unBlockSliderMain: 'SET_ITEM_SLIDER_MAIN_UNBLOCK',
  blockSliderMain: 'SET_ITEM_SLIDER_MAIN_BLOCK',
  blockSliderBest: 'SET_ITEM_SLIDER_BEST_BLOCK',
  deleteItemSliderBest: 'SET_DELETE_SLIDER_BEST_ID',

  setLoaderSaveTrue: 'SET_FORM_MAIN_TRUE',
  setLoaderSaveFalse: 'SET_FORM_MAIN_FALSE',
}

const initialAuthState = {
  saveLoader: false,
  initialValues: {
    seo: {
      id: null,
      title: '',
      description: '',
      keywords: '',
    },
  },
  dataSliderMain: {
    data: [],
    loader: false,
  },
  dataSliderBest: {
    data: [],
    loader: false,
  },
  loader: false,
  error: false,
}

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.setLoaderSaveTrue: {
      return {
        ...state,
        saveLoader: true,
      }
    }
    case actionTypes.setLoaderSaveFalse: {
      return {
        ...state,
        saveLoader: false,
      }
    }
    case actionTypes.getListSliderBest: {
      return {
        ...state,
        dataSliderBest: {
          ...state.dataSliderBest,
          loader: true,
        },
      }
    }
    case actionTypes.setListSliderBest: {
      return {
        ...state,
        dataSliderBest: {
          ...state.dataSliderBest,
          data: action.payload,
          loader: false,
        },
      }
    }
    case actionTypes.getListSliderMain: {
      return {
        ...state,
        dataSliderMain: {
          ...state.dataSliderMain,
          loader: true,
        },
      }
    }
    case actionTypes.setListSliderMain: {
      return {
        ...state,
        dataSliderMain: {
          ...state.dataSliderMain,
          data: action.payload,
          loader: false,
        },
      }
    }
    case actionTypes.setMainSlider: {
      return {
        ...state,
        dataSliderMain: {
          ...action.data,
          loader: false,
        },
      }
    }
    case actionTypes.default: {
      return initialAuthState
    }

    case actionTypes.setInitialState:
      return {
        initialAuthState,
      }

    case actionTypes.setData:
      return {
        ...state,
        initialValues: {
          ...action.payload,
        },
        loader: false,
        error: false,
      }
    case actionTypes.getDataPage:
      return {
        ...state,
        loader: true,
        error: false,
      }
    case actionTypes.setError:
      return {
        ...state,
        loader: false,
        error: false,
      }
    default:
      return state
  }
}

export const setLoaderSaveTrue = () => ({
  type: actionTypes.setLoaderSaveTrue,
})

export const setLoaderSaveFalse = () => ({
  type: actionTypes.setLoaderSaveFalse,
})

export const unBlockSliderBest = (id, error = false) => ({
  type: actionTypes.unBlockSliderBest,
  id,
  error,
})

export const unBlockSliderMain = (id, error = false) => ({
  type: actionTypes.unBlockSliderMain,
  id,
  error,
})

export const blockSliderBest = (id, error = false) => ({
  type: actionTypes.blockSliderBest,
  id,
  error,
})

export const blockSliderMain = (id, error = false) => ({
  type: actionTypes.blockSliderMain,
  id,
  error,
})

export const setItemSliderMain = (data) => ({
  type: actionTypes.setItemSliderMain,
  data,
})

export const setItemSliderBest = (data) => ({
  type: actionTypes.setItemSliderBest,
  data,
})

export const getListSliderMainAction = () => ({
  type: actionTypes.getListSliderMain,
})

export const getListSliderBestAction = () => ({
  type: actionTypes.getListSliderBest,
})

export const setListSliderMain = (data) => ({
  type: actionTypes.setListSliderMain,
  payload: data,
})

export const deleteItemSlider = (id, history) => ({
  type: actionTypes.deleteItemSlider,
  id,
  history,
})

export const setListSliderBest = (data) => ({
  type: actionTypes.setListSliderBest,
  payload: data,
})

export const getDataPage = (data) => ({
  type: actionTypes.getDataPage,
  data,
})

export const setData = (data) => ({
  type: actionTypes.setData,
  payload: data,
})

export const setMainSlider = (data) => ({
  type: actionTypes.setMainSlider,
  data,
})

export const deleteItemSliderBest = (id, history) => ({
  type: actionTypes.deleteItemSliderBest,
  id,
  history,
})

export function* saga() {
  yield takeEvery(actionTypes.blockSliderBest, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.main.dataSliderBest.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(setListSliderBest(newData))

      yield blockedSliderBest(currentData.id)
      newData = state.adminPanel.main.dataSliderBest.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'disabled'
          item.loader = false
        }
        return item
      })

      yield put(setListSliderBest(newData))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.main.dataSliderBest.data.map(
          (item) => {
            if (currentData.id === item.id) {
              item.loader = false
            }
            return item
          }
        )

        yield put(setListSliderBest(newData))
      }
    }
  })

  yield takeEvery(actionTypes.unBlockSliderBest, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.main.dataSliderBest.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })
      yield put(setListSliderBest(newData))
      yield unBlockedSliderBest(currentData.id)
      newData = state.adminPanel.main.dataSliderBest.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })
      yield put(setListSliderBest(newData))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.main.dataSliderBest.data.map(
          (item) => {
            if (currentData.id === item.id) {
              item.loader = false
            }
            return item
          }
        )
        yield put(setListSliderBest(newData))
      }
    }
  })

  yield takeEvery(actionTypes.blockSliderMain, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.main.dataSliderMain.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(setListSliderMain(newData))

      yield blockedSliderMain(currentData.id)
      newData = state.adminPanel.main.dataSliderMain.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'disabled'
          item.loader = false
        }
        return item
      })

      yield put(setListSliderMain(newData))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.main.dataSliderMain.data.map(
          (item) => {
            if (currentData.id === item.id) {
              item.loader = false
            }
            return item
          }
        )

        yield put(setListSliderMain(newData))
      }
    }
  })

  yield takeEvery(actionTypes.unBlockSliderMain, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.main.dataSliderMain.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })
      yield put(setListSliderMain(newData))
      yield unBlockedSliderMain(currentData.id)
      newData = state.adminPanel.main.dataSliderMain.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })
      yield put(setListSliderMain(newData))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.main.dataSliderMain.data.map(
          (item) => {
            if (currentData.id === item.id) {
              item.loader = false
            }
            return item
          }
        )
        yield put(setListSliderMain(newData))
      }
    }
  })

  yield takeLatest(actionTypes.setItemSliderBest, function* (e) {
    try {
      const state = yield select()
      const data = state.adminPanel.main.dataSliderBest.data
      const newData = []
      data.forEach((item) => {
        if (e.data.id === item.id) {
          item = e.data
        }
        newData.push(item)
        return item
      })
      yield put(setListSliderBest(newData))
    } catch (e) {
      console.log(e)
      // yield put(errorHeading())
    }
  })
  yield takeLatest(actionTypes.setItemSliderMain, function* (e) {
    try {
      const state = yield select()
      const data = state.adminPanel.main.dataSliderMain.data
      const newData = []
      data.forEach((item) => {
        if (e.data.id === item.id) {
          item = e.data
        }
        newData.push(item)
        return item
      })
      yield put(setListSliderMain(newData))
    } catch (e) {
      // yield put(errorHeading())
    }
  })

  yield takeLatest(actionTypes.getListSliderMain, function* (e) {
    try {
      const { data } = yield getAllData(getListSliderMain)

      yield put(setListSliderMain(data))
    } catch (e) {
      // yield put(errorHeading())
    }
  })

  yield takeLatest(actionTypes.getListSliderBest, function* (e) {
    try {
      const { data } = yield getAllData(getListSliderBest)
      yield put(setListSliderBest(data))
    } catch (e) {
      // yield put(errorHeading())
    }
  })

  yield takeEvery(actionTypes.deleteItemSlider, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.main.dataSliderMain.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })
      yield put(setListSliderMain(newData))
      if (currentData.history) {
        currentData.history.push('/admin/main-management/')
      } else {
        yield deleteMainSlider(currentData.id)
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      const data = state.adminPanel.main.dataSliderMain.data.filter(
        (item) => item.id !== currentData.id
      )

      yield put(setMainSlider({ data: data }))
    } catch (e) {
      const state = yield select()
      const newData = state.adminPanel.main.dataSliderMain.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })
      yield put(setListSliderMain(newData))
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  })

  yield takeEvery(actionTypes.deleteItemSliderBest, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.main.dataSliderBest.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })
      yield put(setListSliderBest(newData))
      if (currentData.history) {
        currentData.history.push('/admin/main-management/')
      } else {
        yield deleteBestSlider(currentData.id)
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      const data = state.adminPanel.main.dataSliderBest.data.filter(
        (item) => item.id !== currentData.id
      )
      yield put(setListSliderBest(data))
    } catch (e) {
      const state = yield select()
      const newData = state.adminPanel.main.dataSliderBest.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })
      yield put(setListSliderBest(newData))
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  })

  yield takeLatest(actionTypes.getDataPage, function* (e) {
    try {
      const { data } = yield showSeoUrl('MAIN')

      yield put(setData({ seo: data }))
    } catch (e) {
      yield put(actionTypes.setError())
    }
  })
}
