import React, { Component, createRef } from 'react'
import 'react-calendar/dist/Calendar.css'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import {
  setContainerStyle,
  setContainerValueStyle,
} from '../../SetStylesFormio/styles'

export default class DatePickerClass extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {
      date: null,
      currentView: 'decade',
    }

    this[`ref-${this.props.id}`] = React.createRef()
    this.tooltip = createRef(null)
    this.datePickerContainer = createRef(null)
  }

  componentDidMount() {
    this._isMounted = true

    setTimeout(() => {
      /* есть особеность в самом формио, незнаю почему но нужна задержка, может помочь даже задержка в 1 милисекунд */
      const {
        component: { key },
      } = this.props

      const date = this.props._data[`${key}`]
      let d = []
      if (date) {
        d = date.split('.')
      }
      this.setState((prevState) => {
        return {
          date: d.length === 3 ? new Date(d[2], d[1] - 1, d[0]) : '',
        }
      })
    }, 100)
  }

  componentWillUnmount() {
    this._isMounted = false
    const { clearOnHide } = this.props.component
    if (clearOnHide) {
      this.props.onChange(null)
    }
  }

  onChangeDate = (e) => {
    this.setState({
      date: e,
    })

    if (e) {
      this.props.onChange(moment(e).format('L'))
    }
  }

  handleBlur = (e) => {
    const day = document.querySelectorAll(
      `#${this.props.id} .react-date-picker__inputGroup__input`
    )[0].value
    const month = document.querySelectorAll(
      `#${this.props.id} .react-date-picker__inputGroup__input`
    )[1].value
    const year = document.querySelectorAll(
      `#${this.props.id} .react-date-picker__inputGroup__input`
    )[2].value

    if (day && month && year) {
      if (!moment(`${year}/${month}/${day}`).isValid()) {
        this.clearDate()
        return true
      }
      const { minDate, maxDate } = this.props.component
      if (minDate) {
        if (moment(`${year}/${month}/${day}`).unix() < moment(minDate).unix()) {
          this.clearDate()
        }
      }
      if (maxDate) {
        if (moment(`${year}/${month}/${day}`).unix() > moment(maxDate).unix()) {
          this.clearDate()
        }
      }
    }
  }

  clearDate = () => {
    this[`ref-${this.props.id}`].current.clear()

    this.props.onChange(null)
    setTimeout(() => {
      for (let i = 0; i < 3; i++) {
        const input = document.querySelectorAll(
          `#${this.props.id} .react-date-picker__inputGroup__input`
        )
        if (input[i]) {
          input[i].value = ''
        }
      }
    })
  }

  render() {
    const { component } = this.props

    const { date } = this.state

    return (
      <div
        className={`formioDatePickerContainer ${this.props.id}`}
        ref={this.datePickerContainer}
      >
        <div
          className="container-fields-wrapper"
          style={setContainerStyle(component)}
        >
          <div style={setContainerValueStyle(component)}>
            <DatePicker
              format={'d/M/yyyy'}
              onBlur={this.handleBlur}
              onChange={this.onChangeDate}
              value={date}
              defaultView={'decade'}
              maxDetail={'month'}
              minDetail={'decade'}
              ref={this[`ref-${this.props.id}`]}
              clearIcon={null}
              onViewChange={(...e) => {
                this.setState({
                  currentView: e.view,
                })
              }}
              onCalendarClose={() => {
                this.setState({
                  currentView: 'decade',
                })
              }}
              locale={'ru-RU'}
              view={this.state.currentView}
              next2Label={null}
              prev2Label={null}
              maxDate={
                this.props.component?.maxDate
                  ? moment(this.props.component?.maxDate)?.toDate()
                  : null
              }
              minDate={
                this.props.component?.minDate
                  ? moment(this.props.component?.minDate)?.toDate()
                  : null
              }
            />
          </div>
        </div>
      </div>
    )
  }
}
