import axios from 'axios'

const generalDictionaryUrl = '/admin/dictionaries'

export function getGeneralDictionaries(page = 1) {
  return axios.get(`${generalDictionaryUrl}?page=${page}`)
}

export function deleteGeneralDictionary(id) {
  return axios.delete(`${generalDictionaryUrl}/${id}`, {
    data: {},
  })
}

export function blockGeneralDictionary(id) {
  return axios.patch(`${generalDictionaryUrl}/${id}/disable`)
}

export function unBlockGeneralDictionary(id) {
  return axios.patch(`${generalDictionaryUrl}/${id}/enable`)
}

export function createGeneralDictionary(data) {
  return axios.post(`${generalDictionaryUrl}`, data)
}

export function editGeneralDictionary(id, data) {
  return axios.put(`${generalDictionaryUrl}/${id}`, data)
}

export function showGeneralDictionary(id) {
  return axios.get(`${generalDictionaryUrl}/${id}`)
}

export function getGeneralDictionariesValues(dictionary_id, page = 1) {
  return axios.get(
    `${generalDictionaryUrl}/${dictionary_id}/values?page=${page}`
  )
}
export function deleteGeneralDictionaryValue(dictionary_id, id) {
  return axios.delete(`${generalDictionaryUrl}/${dictionary_id}/values/${id}`)
}

export function createGeneralDictionaryValue(dictionary_id, data) {
  return axios.post(`${generalDictionaryUrl}/${dictionary_id}/values`, data)
}

export function editGeneralDictionaryValue(dictionary_id, id, data) {
  return axios.put(
    `${generalDictionaryUrl}/${dictionary_id}/values/${id}`,
    data
  )
}
