import axios from 'axios'
import qs from 'qs'

const path = '/admin/insurant/stories/queries'

export function getReportsQueries(page, filter) {
  return axios.get(`${path}?page=${page}`, {
    params: qs.parse({ filter: filter }),
  })
}

export function exportsReportsQueries(data) {
  return axios.post(`${path}/export`, data)
}

export function deleteReportsQuerie() {
  return axios.delete(`${path}`, {
    data: {},
  })
}

export function blockReportsQuerie(id) {
  return axios.patch(`${path}/${id}/disable`)
}

export function unBlockReportsQuerie(id) {
  return axios.patch(`${path}/${id}/enable`)
}

export function createReportsQuerie(data) {
  return axios.post(`${path}`, data)
}

export function editReportsQuerie(id, data) {
  return axios.put(`${path}/${id}`, data)
}

export function showReportsQuerie(id) {
  return axios.get(`${path}/${id}`)
}
