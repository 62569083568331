import axios from 'axios'

const path = '/admin/content/retentions'

// http://localhost:8001/api/v1/admin/content/retentions/first
export function getRetentions() {
  return axios.get(`${path}/first`)
}

export function deleteRetention() {
  return axios.delete(`${path}`, {
    data: {},
  })
}

export function blockRetention(id) {
  return axios.patch(`${path}/disable`)
}

export function unBlockRetention(id) {
  return axios.patch(`${path}/enable`)
}

export function createRetention(data) {
  return axios.post(`${path}`, data)
}

export function editRetention(data) {
  return axios.put(`${path}`, data)
}

export function showRetention(id) {
  return axios.get(`${path}/first`)
}

// FIELD

export function getGeneralRetentionFields(page = 1) {
  return axios.get(`${path}/forms/fields?page=${page}`)
}
export function deleteRetentionField(id) {
  return axios.delete(`${path}/forms/fields/${id}`)
}

export function createRetentionField(data) {
  return axios.post(`${path}/forms/fields`, data)
}

export function editRetentionField(id, data) {
  return axios.put(`${path}/forms/fields/${id}`, data)
}
