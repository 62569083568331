import axios from 'axios'

const staticPageUrl = '/admin/products'

export function getProductRisks(product_id, coverage_id, page = 1) {
  return axios.get(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/risks?page=${page}`
  )
}

export function deleteProductRisk(product_id, coverage_id, id) {
  return axios.delete(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/risks/${id}`,
    {
      data: {},
    }
  )
}

export function blockProductRisk(product_id, coverage_id, id) {
  return axios.patch(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/risks/${id}/disable`
  )
}

export function unBlockProductRisk(product_id, coverage_id, id) {
  return axios.patch(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/risks/${id}/enable`
  )
}

export function createProductRisk(product_id, coverage_id, data) {
  return axios.post(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/risks`,
    data
  )
}

export function editProductRisk(product_id, coverage_id, id, data) {
  return axios.put(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/risks/${id}`,
    data
  )
}

export function showProductRisk(product_id, coverage_id, id) {
  return axios.get(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/risks/${id}`
  )
}
