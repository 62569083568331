import axios from 'axios'

const path = '/admin/content/content-forms'

// http://localhost:8001/api/v1/admin/content/retentions/first
export function getFeedbacks() {
  return axios.get(`${path}`)
}

export function deleteFeedback() {
  return axios.delete(`${path}`, {
    data: {},
  })
}

export function blockFeedback(id) {
  return axios.patch(`${path}/disable`)
}

export function unBlockFeedback(id) {
  return axios.patch(`${path}/enable`)
}

export function createFeedback(data) {
  return axios.post(`${path}`, data)
}

export function editFeedback(data) {
  return axios.put(`${path}`, data)
}

export function showFeedback(id) {
  return axios.get(`${path}/first`)
}

// FIELD

export function getGeneralFeedbackFields(page = 1) {
  return axios.get(`${path}/fields?page=${page}`)
}
export function deleteFeedbackField(id) {
  return axios.delete(`${path}/fields/${id}`)
}

export function createFeedbackField(data) {
  return axios.post(`${path}/fields`, data)
}

export function editFeedbackField(id, data) {
  return axios.put(`${path}/fields/${id}`, data)
}
