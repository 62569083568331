import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { userRoles } from '../../../../../constants'

const routes = [
  {
    buttonText: 'Пригласить пользователя',
    routeTo: '/admin/users/invite',
    matchUrl: '/admin/users',
  },
  {
    buttonText: 'Пригласить пользователя',
    routeTo: '/admin/users/invite',
    matchUrl: '/admin/users/invite',
  },
  {
    buttonText: 'Добавить услугу',
    routeTo: '/admin/services/item',
    matchUrl: '/admin/services',
  },
  {
    buttonText: 'Добавить промокод',
    routeTo: '/admin/promocodes/item',
    matchUrl: '/admin/promocodes',
  },
  {
    buttonText: 'Добавить компанию',
    routeTo: '/admin/company/item',
    matchUrl: '/admin/company',
    access: [userRoles.root.type, userRoles.superAdmin.type],
  },
  {
    buttonText: 'Добавить услугу',
    routeTo: '/admin/content/service/item',
    matchUrl: '/admin/content/service',
  },
  {
    buttonText: 'Добавить рубрику',
    routeTo: '/admin/content/heading/item',
    matchUrl: '/admin/content/heading',
  },
  {
    buttonText: 'Добавить страницу',
    routeTo: '/admin/content/static-pages/page',
    matchUrl: '/admin/content/static-pages',
  },
  {
    buttonText: 'Добавить шаблон',
    routeTo: '/admin/product-templates/template',
    matchUrl: '/admin/product-templates',
  },
  {
    buttonText: 'Добавить справочник',
    routeTo: '/admin/general-dictionary/dictionary',
    matchUrl: '/admin/general-dictionary',
  },
  {
    buttonText: 'Добавить виджет',
    routeTo: '/admin/widgets/item/',
    matchUrl: '/admin/widgets',
  },
]

class SwitchContent extends Component {
  render() {
    const { history, user } = this.props

    const route = routes.find((item) => {
      if (
        item.matchUrl === history.location.pathname ||
        item.matchUrl + '/' === history.location.pathname
      ) {
        if (item.access) {
          if (
            user?.roles &&
            user.roles.some((role) => item.access.indexOf(role) !== -1)
          ) {
            return item
          }
        } else {
          return item
        }
      }
    })

    if (route) {
      return (
        <Link
          to={route.routeTo}
          style={{ marginLeft: '27px' }}
          className={`btn btn-primary font-weight-bold mr-2`}
        >
          {route.buttonText}
        </Link>
      )
    }
    return <></>
  }
}

function mapStateToProps(store) {
  return {
    user: store.adminPanel.auth.user,
    saveLoader: store.adminPanel.main.saveLoader,
    saveLoaderCompanyForm: store.adminPanel.company.form.loader,
  }
}

export default withRouter(connect(mapStateToProps, null)(SwitchContent))
