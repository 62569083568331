export const Descktop = 'Descktop'
export const Tablet = 'Tablet'
export const Mobile = 'Mobile'

export const Breakpoints = {
  Mobile: 768,
  Tablet: 992,
  Desktop: 1200,
}

export const OFFSET_HEADER_TOP_HEIGHT = 112

export const Color = [
  '#04AA77',
  '#8950FC',
  '#F64E60',
  '#187DE4',
  '#3F3CE1',
  '#663259',
  '#0BB783',
  '#5F5CF1',
  '#EF6327',
  '#FFA800',
]
export const PHONE_MASK = [
  '+',
  '7',
  ' ',
  /\d/,
  /[0-9]/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
]
export const SMS_CODE_MASK = [/\d/, /\d/, /\d/, /\d/]

export const CODE_FMS_CODE_MASK = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

export const CODE_PARTY_CODE_MASK = [
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
]

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/

export const TYPE_DICTIONARY = [
  { type: 'information', name: 'Информационые' },
  { type: 'blocking', name: 'Блокирующие' },
  { type: 'rated', name: 'Расчетные' },
]

export const formioDataTypes = {
  dictionary: 'DICTIONARY',
  generalDictionary: 'GENERAL_DICTIONARY',
  coverage: 'COVERAGE',
  dadataAddress: 'DADATA_ADDRESS',
  dadataFms: 'DADATA_FMS',
  dadataParty: 'DADATA_PARTY',
  otp: 'OTP',
}

export const errorPlaces = {
  toast: 'toast',
  content: 'content',
}

export const offerProductAttributes = {
  coverages: '',
  dictionary_values: '',
}

export const userRoles = {
  root: { type: 'ROLE_ROOT', label: 'ROOT' },
  superAdmin: { type: 'ROLE_SUPER_ADMIN', label: 'Супер админ' },
  contentManager: {
    type: 'ROLE_CONTENT_MANAGER',
    label: 'Контент менеджер',
  },
  productTemplateManager: {
    type: 'ROLE_PRODUCT_TEMPLATE_MANAGER',
    label: 'Темплейт менеджер',
  },
  partnerManager: {
    type: 'ROLE_PARTNER_MANAGER',
    label: 'Партнер менеджер',
  },
  productTemplateOperator: {
    type: 'ROLE_PRODUCT_TEMPLATE_OPERATOR',
    label: 'Оператор шаблона продукта',
  },
  companyOperator: {
    type: 'ROLE_COMPANY_OPERATOR',
    label: 'Оператор СК',
  },
}
