import axios from 'axios'
import projectConfig from '../../../../config'

export const LOGIN_URL = '/auth/token'
export const REGISTER_URL = 'api/auth/register'
export const REQUEST_PASSWORD_URL = '/auth/reset-password/request'
export const REQUEST_RESET_PASSWORD_URL = '/auth/reset-password/reset'
export const RESET_PASSWORD_URL = '/reset-password/reset'
export const REQUEST_REFRESH_TOKEN = '/auth/token/refresh'

const JOIN_REGISTER = '/auth/join-by-invitation/join'

export const USER_SHOW = '/auth/profile'
export function login(
  username,
  password,
  grant_type = 'password',
  client_id = projectConfig.clientId,
  client_secret = projectConfig.clientSecret
) {
  return axios.post(LOGIN_URL, {
    username,
    password,
    grant_type,
    client_id,
    client_secret,
  })
}

export function requestJoinEmail(data) {
  return axios.post(JOIN_REGISTER, data)
}

export function requestRefreshToken(data) {
  return axios.post(LOGIN_URL, data)
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password })
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
    confirmation_url: projectConfig.resetPasswordUrl,
  })
}

export function confirmPasswordByToken(password, token) {
  return axios.post(REQUEST_RESET_PASSWORD_URL, {
    password,
    token,
  })
}

export function getUserByToken() {
  return axios.get(USER_SHOW)
}
