import baseEditForm from 'formiojs/components/_classes/component/Component.form'

export default (extend) => {
  return baseEditForm(
    [
      {
        key: 'display',
        components: [
          { key: 'label', ignore: true },
          { key: 'myCustomSetting', ignore: true },
          { key: 'labelPosition', ignore: true },
          { key: 'placeholder', ignore: true },
          { key: 'customClass', ignore: true },
          { key: 'tabindex', ignore: true },
          { key: 'hide', ignore: true },
          { key: 'hidden', ignore: true },
          { key: 'hideLabel', ignore: true },
          { key: 'autofocus', ignore: true },
          { key: 'disabled', ignore: true },
          { key: 'tableView', ignore: true },
          { key: 'modalEdit', ignore: true },
        ],
      },
      {
        key: 'data',
        ignore: true,
        components: [
          {
            ignore: true,
            key: 'defaultValue',
          },
          {
            ignore: true,
            key: 'persistent',
          },
          {
            ignore: true,
            key: 'multiple',
          },
          {
            ignore: true,
            key: 'protected',
          },
          {
            ignore: true,
            key: 'dbIndex',
          },
          {
            ignore: true,
            key: 'encrypted',
          },
          {
            ignore: true,
            key: 'redrawOn',
          },
          {
            ignore: true,
            key: 'clearOnHide',
          },
          {
            ignore: true,
            key: 'customDefaultValuePanel',
          },
          {
            ignore: true,
            key: 'calculateValuePanel',
          },
          {
            ignore: true,
            key: 'calculateServer',
          },
          {
            ignore: true,
            key: 'allowCalculateOverride',
          },
        ],
      },
      {
        key: 'layout',
        ignore: true,
        components: [],
      },
      {
        key: 'validation',
        components: [
          {
            key: 'custom-validation-js',
            ignore: true,
          },
          {
            key: 'json-validation-json',
            ignore: true,
          },
          {
            key: 'unique',
            ignore: true,
          },
          {
            key: 'validateOn',
            ignore: true,
          },
        ],
      },
      {
        key: 'api',
        // ignore: true,
        components: [
          {
            key: 'tags',
            ignore: true,
          },
          {
            key: 'properties',
            ignore: true,
          },
        ],
      },
      {
        key: 'conditional',
        ignore: true,
        components: [],
      },
      {
        key: 'logic',
        ignore: true,
        components: [],
      },
    ],
    ...extend
  )
}
