import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { isAdminSide } from '../../../helpers/isAdminSide'
import parse from 'html-react-parser'

export default class Html extends React.PureComponent {
  constructor(props) {
    super(props)
    this._isMounted = false
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  loadSourceJS(scripts) {
    const { component } = this.props
    if (scripts && window[`js_${component.id}`] === undefined) {
      window[`js_${component.id}`] = true

      const scriptsArr = scripts.split('\n')

      if (scriptsArr.length) {
        scriptsArr.map((sc) => {
          const script = document.createElement('script')

          script.src = sc
          script.async = true

          document.body.appendChild(script)
        })
      }
    }
  }

  render() {
    const { component } = this.props

    const {
      location: { pathname },
    } = window

    if (!isAdminSide(pathname)) {
      return (
        <>
          {component.htmlType && parse(component.htmlContent)}
          {component.jsType && (
            <img
              src={toAbsoluteUrl('/media/pib/htmlJs.png')}
              alt="productInfo"
              onLoad={() => {
                // eslint-disable-next-line no-eval
                eval(component.jsContent)
              }}
            />
          )}
          {component.jsSourceType &&
            this.loadSourceJS(component.jsSourceContent)}
        </>
      )
    }
    return <div></div>
  }
}
