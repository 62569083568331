import React, { Component } from 'react'
import { TreeItem } from '../components/TreeItem'
import { getListCoverages } from '../redux/crud'
import {
  errorPlaces,
  formioDataTypes,
  TYPE_DICTIONARY,
} from '../../../../constants'
import { getAllData } from '../../../helpers/api'
import { errorParser } from '../../../helpers/errorsParse'
import { Alert } from 'react-bootstrap'
import { GeneralDictionaryComponent } from '../components/GeneralDictionaryComponent'

export default class FormioTree extends Component {
  constructor(props) {
    super(props)
    this.state = {
      coverages: [],
      props: props._data,
      activeValueState: null,
      sleep: false,
      isLoadingCoverage: false,
      errorTextCoverage: null,
    }
  }

  componentDidMount() {
    this.setState({ isLoadingCoverage: true, errorText: null })

    const { template_id } = this.props.options
    // Костиль:
    setTimeout(() => {
      this.setState({ sleep: !this.state.sleep })
    }, 2000)

    getAllData(getListCoverages, 1, [template_id, 'enabled'])
      .then((response) => {
        const { data } = response
        this.setState({ coverages: data })
      })
      .catch((e) => {
        this.setState({
          errorTextCoverage: errorParser(e.response, errorPlaces.content),
        })
      })
      .finally(() => {
        this.setState({ isLoadingCoverage: false })
      })
  }

  _onChangeHandle = (type = formioDataTypes.dictionary, data) => {
    const { onChange } = this.props

    onChange({ formioDataType: type, data: data })

    this.setState({ activeValueState: data.id })
  }

  render() {
    const {
      activeValueState,
      coverages,
      isLoadingCoverage,
      errorTextCoverage,
    } = this.state
    const { template_id } = this.props.options

    const active_id = this.props._data?.dictionary?.data.id ?? null

    const activeValue = activeValueState || active_id
    return (
      <>
        <div className="tree_wrapper">
          <h6>Справочники:</h6>
          {TYPE_DICTIONARY.map((dictionaryItem, index) => {
            return (
              <TreeItem
                key={`dictionary-${dictionaryItem.type}`}
                dictionary={dictionaryItem}
                template_id={template_id}
                onChange={this._onChangeHandle}
                activeDictionary={activeValue}
              />
            )
          })}
          <h6>Покрытия:</h6>
          {isLoadingCoverage && <p>Идет загрузка покрытий ...</p>}
          {errorTextCoverage && (
            <Alert key={'danger'} variant={'danger'}>
              {errorTextCoverage}
            </Alert>
          )}
          <ul>
            {coverages.map((coverageItem, index) => {
              return (
                <li key={`coverage-${coverageItem.id}`}>
                  <input
                    type="radio"
                    id={coverageItem.id}
                    onChange={(e) => {
                      if (
                        this.props._data.type !== '' &&
                        (this.props._data.type !== 'radiobox' ||
                          this.props._data.type !== 'custom_select')
                      ) {
                        this._onChangeHandle(formioDataTypes.coverage, {
                          id: coverageItem.id,
                        })
                      }
                    }}
                    value={coverageItem.id}
                    checked={coverageItem.id === activeValue}
                    name={formioDataTypes.coverage}
                    disabled={
                      this.props._data.type !== '' &&
                      (this.props._data.type === 'radiobox' ||
                        this.props._data.type === 'custom_select')
                    }
                  />
                  <label htmlFor={coverageItem.id}>{coverageItem.name}</label>
                </li>
              )
            })}
          </ul>
          {this.props._data.type !== '' &&
          this.props._data.type !== 'custom_checkbox' && ( // скрываем общие спарвочники для список чекбокс, так как они будут не коректно работать, и зза структуры которую принимает бек
              <GeneralDictionaryComponent
                onChange={this._onChangeHandle}
                activeDictionary={activeValue}
              />
            )}
        </div>
      </>
    )
  }
}
