import projectConfig from '../config'
import { requestRefreshToken } from '../app/pages/Auth/_redux/authCrud'
import { actions } from '../app/pages/Auth/_redux/authRedux'

var isAlreadyFetchingAccessToken = false
var subscribers = []

const onAccessTokenFetched = (access_token) => {
  subscribers = subscribers.filter((callback) => callback(access_token))
}

const addSubscriber = (callback) => {
  subscribers.push(callback)
}

export default function setupAxios(axios, store) {
  axios.defaults.baseURL = projectConfig.apiUrl
  axios.interceptors.request.use(
    (config) => {
      const {
        adminPanel: {
          auth: { access_token },
        },
      } = store.getState()

      config.headers = {
        'Content-Type': 'application/json',
        'Accept-Language': 'ru',
        Accept: 'application/json',
      }
      config.data = config.data ? config.data : null
      if (
        access_token &&
        (config.url.indexOf('/admin') === 0 ||
          config.url.indexOf('/auth') === 0 ||
          config.url.indexOf('admin') === 0 ||
          config.url.indexOf('auth') === 0)
      ) {
        config.headers.Authorization = `Bearer ${access_token}`
      }

      return config
    },
    (err) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      const {
        adminPanel: {
          auth: { refresh_token },
        },
      } = store.getState()
      const { config, response } = error
      const originalRequest = config
      if (
        response?.status === 401 &&
        refresh_token &&
        originalRequest?.url !== '/auth/token'
      ) {
        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((access_token) => {
            originalRequest.headers.Authorization = 'Bearer ' + access_token
            return resolve(axios(originalRequest))
          })
        })

        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          requestRefreshToken({
            grant_type: 'refresh_token',
            refresh_token: refresh_token,
            client_id: projectConfig.clientId,
            client_secret: projectConfig.clientSecret,
          })
            .then((res) => {
              store.dispatch(
                actions.login({
                  access_token: res.data.access_token,
                  refresh_token: res.data.refresh_token,
                })
              )
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(res.data.access_token)
            })
            .catch((er) => {
              store.dispatch(actions.logout())
            })
        }
        return retryOriginalRequest
      }
      return Promise.reject(error)
    }
  )
}
