import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import {
  getCompanyList,
  deleteCompany,
  blockedCompany,
  unBlockedCompany,
} from './crud'

import lodash from 'lodash'
export const actionTypes = {
  setInitialState: 'SET_PAGE_COMPANY_INITIAL_VALUES',
  getDataPage: 'GET_DATA_PAGE_COMPANY',
  setData: 'SET_DATA_ID_COMPANY',
  setDataList: 'SET_DATA_LIST_PAGE_COMPANY',
  getCompanyPages: 'GET_PAGES_DATA_COMPANY',
  SetLoaderState: 'SET_COMPANY_CompanyS_LOADER',
  setError: 'PAGE_COMPANY_SET_ERROR',
  // setSeoSuccess: 'SEO_SUCCESS',
  deleteCompany: 'SET_DELETE_ID_COMPANY',
  unBlockCompany: 'SET_UNBLOCK_ID_COMPANY',
  blockCompany: 'SET_BLOCK_ID_COMPANY',
  changeDataId: 'SET_DATA_ID_COMPANY',
  setLoaderFormTrue: 'SET_DATA_FORM_LOADER_TRUE_COMPANY',
  setLoaderFormFalse: 'SET_DATA_FORM_LOADER_FALSE_COMPANY',
}

const initialAuthState = {
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  data: [],
  form: {
    loader: false,
  },
  loader: false,
  error: false,
}

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.default: {
      return initialAuthState
    }
    case actionTypes.setLoaderFormTrue: {
      return {
        ...state,
        form: {
          loader: true,
        },
      }
    }
    case actionTypes.setLoaderFormFalse: {
      return {
        ...state,
        form: {
          loader: false,
        },
      }
    }

    case actionTypes.setInitialState:
      return {
        initialAuthState,
      }

    case actionTypes.setData:
      return {
        ...state,
        initialValues: {
          ...action.payload,
        },
        loader: false,
        error: false,
      }
    case actionTypes.setDataList:
      return {
        ...state,
        ...action.payload,
        loader: false,
        error: false,
      }
    case actionTypes.getDataPage:
    case actionTypes.getCompanyPages:
    case actionTypes.unBlockCompany:
    case actionTypes.blockCompany:
      return {
        ...state,
        loader: true,
        error: false,
      }
    case actionTypes.setError:
      return {
        ...state,
        loader: false,
        error: false,
      }
    default:
      return state
  }
}
export const setLoaderFormTrue = () => ({
  type: actionTypes.setLoaderFormTrue,
})

export const setLoaderFormFalse = () => ({
  type: actionTypes.setLoaderFormFalse,
})

export const deleteCompanyAction = (id, history) => ({
  type: actionTypes.deleteCompany,
  id,
  history,
})

export const unBlockCompany = (id, error = false) => ({
  type: actionTypes.unBlockCompany,
  id,
  error,
})

export const blockCompany = (id, error = false) => ({
  type: actionTypes.blockCompany,
  id,
  error,
})

export const getDataListAction = (page = 1) => ({
  type: actionTypes.getCompanyPages,
  page,
})

export const getDataPage = (data) => ({
  type: actionTypes.getDataPage,
  data,
})

export const setData = (data) => ({
  type: actionTypes.setData,
  payload: data,
})

export const setDataList = (data) => ({
  type: actionTypes.setDataList,
  payload: data,
})

export const setError = () => ({
  type: actionTypes.setError,
})

export const changeDataId = (data) => ({
  type: actionTypes.changeDataId,
  data,
})

export function* saga() {
  yield takeLatest(actionTypes.changeDataId, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.company.data.map((item) => {
        if (currentData.data.id === item.id) {
          item = currentData.data
        }
        return item
      })
      yield put(setDataList({ data: newData }))
    } catch (e) {
      yield put(setError())
    }
  })

  yield takeEvery(actionTypes.blockCompany, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.company.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(setDataList({ data: newData }))

      yield blockedCompany(currentData.id)
      newData = state.adminPanel.company.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'block'
          item.loader = false
        }
        return item
      })

      yield put(setDataList({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.company.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(setDataList({ data: newData }))
      }
      yield put(setError())
    }
  })

  yield takeEvery(actionTypes.unBlockCompany, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.company.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })
      yield put(setDataList({ data: newData }))
      yield unBlockedCompany(currentData.id)
      newData = state.adminPanel.company.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'active'
          item.loader = false
        }
        return item
      })
      yield put(setDataList({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.company.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })
        yield put(setDataList({ data: newData }))
      }
      yield put(setError())
    }
  })

  yield takeEvery(actionTypes.deleteCompany, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.company.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })
      yield put(setDataList({ data: newData }))
      if (currentData.history) {
        currentData.history.push('/admin/company/')
      } else {
        yield deleteCompany(currentData.id)
      }
      const data = state.adminPanel.company.data.filter(
        (item) => item.id !== currentData.id
      )

      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      if (state.adminPanel.company.meta.pager.page === 0) {
        return yield put(getDataListAction(1))
      }

      const meta = (state.adminPanel.company.meta.pager = {
        ...state.adminPanel.company.meta.pager,
        total: state.adminPanel.company.meta.pager.total - 1,
        per_page: state.adminPanel.company.meta.pager.per_page,
        pages: state.adminPanel.company.meta.pager.pages,
      })

      if (meta.pages !== 1 && meta.pages - 1 >= meta.total / meta.per_page) {
        meta.pages = meta.pages - 1
      }

      yield put(setDataList({ data: data, meta: { pager: meta } }))

      if (!data.length) {
        if (meta.total !== 0) {
          if (meta.pages !== meta.page) {
            if (meta.page > meta.pages) {
              yield put(getDataListAction(meta.page - 1))
            } else {
              yield put(getDataListAction(meta.page))
            }
          } else {
            yield put(getDataListAction(Math.ceil(meta.total / meta.per_page)))
          }
        }
      }
    } catch (e) {
      console.log(e)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.company.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })
      yield put(setDataList({ data: newData }))
      yield put(setError())
    }
  })

  yield takeLatest(actionTypes.getCompanyPages, function* (e) {
    try {
      const { data } = yield getCompanyList(e.page)

      yield put(setDataList(data))
    } catch (e) {
      yield put(setError())
    }
  })
}
