import axios from 'axios'

const path = '/admin/companies'

export function setCompanyImg(id, data) {
  return axios.post(`${path}/${id}/logotype`, data)
}

export function companyCreate(data) {
  return axios.post(`${path}`, data)
}

export function editMainCompany(id, data) {
  return axios.put(`${path}/${id}`, data)
}

export function getCompanyList(page = 1) {
  return axios.get(`${path}?page=${page}`)
}

export function getCompaniesList() {
  return axios.get(`${path}-list`)
}

export function getCompanyListByProducts(page = 1, id) {
  return axios.get(`${path}/products/${id}?page=${page}`)
}

export function companyGet(id) {
  return axios.get(`${path}/${id}`)
}

export function deleteCompany(id) {
  return axios.delete(`${path}/${id}`)
}
export function blockedCompany(id) {
  return axios.patch(`${path}/${id}/block`)
}
export function unBlockedCompany(id) {
  return axios.patch(`${path}/${id}/activate`)
}

export function deleteImageCompany(id) {
  return axios.delete(`${path}/${id}/logotype`)
}

export function CompanyAddCategories(id, data) {
  return axios.post(`${path}/${id}/categories`, data)
}

export function CompanyDeleteCategories(id, categoryId) {
  return axios.delete(`${path}/${id}/categories/${categoryId}`)
}

export function CompanyChangeMainCategories(id, data) {
  return axios.post(`${path}/${id}/main-category`, data)
}

export function companyChangePosition(id, data) {
  return axios.patch(`${path}/${id}/position`, data)
}

export const getCompanyListFromProduct = (page, id) => {
  return axios.get(`${path}/products/${id}?page=${page}`)
}
