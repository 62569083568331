import FormioRangeSlider from './RangeComponent'
import TreeSelectComponentForRange from './TreeSelectComponentForRange'
import TreeSelectComponent from './TreeSelectComponent'
import RadioBoxComponent from './RadioBoxComponent'
import CheckboxBoxComponent from './CheckboxBoxComponent'
import SelectComponent from './SelectComponent'
import PromocodeComponent from './PromoCodeComponent'
import ProductInfoComponent from './ProductInfoComponent'
import OtpComponent from './OtpComponent'
import DadataAddressComponent from './DadataGroup/DadataAdressComponent'
import DadataFmsComponent from './DadataGroup/DadataFmsComponent'
import DadataPartyComponent from './DadataGroup/DadataPartyComponent'
import ReCaptchaComponent from './ReCaptchaComponent'
import CheckoutConfirmComponent from './CheckoutConfirmComponent'
import DatePickerComponent from './DatePickerComponent'
import HTMLComponent from './HTMLComponent'

export const formioCustomComponents = [
  { rangeslider: FormioRangeSlider },
  { coverage: TreeSelectComponentForRange },
  { dictionary: TreeSelectComponent },
  { radiobox: RadioBoxComponent },
  { custom_checkbox: CheckboxBoxComponent },
  { custom_select: SelectComponent },
  { custom_promocode: PromocodeComponent },
  { product_info: ProductInfoComponent },
  { otp: OtpComponent },
  { dadata_address: DadataAddressComponent },
  { dadata_organisation: DadataFmsComponent },
  { dadata_party: DadataPartyComponent },
  { recaptcha: ReCaptchaComponent },
  { checkout_confirm: CheckoutConfirmComponent },
  { datePicker: DatePickerComponent },
  { html_js: HTMLComponent },
]
