import axios from 'axios'

const content = '/admin/menu/footer/items'

export const getListFooter = (page = 1) => {
  return axios.get(`${content}?page=${page}`)
}

export const getIdFooter = (id) => {
  return axios.get(`${content}/${id}`)
}

export const createFooter = (data) => {
  return axios.post(`${content}`, data)
}

export const editFooter = (id, data) => {
  return axios.post(`${content}/${id}`, data)
}

export const disableIdFooter = (id, data) => {
  return axios.patch(`${content}/${id}/disable`, data)
}

export const enableIdFooter = (id, data) => {
  return axios.patch(`${content}/${id}/enable`, data)
}

export const deleteIdFooter = (id) => {
  return axios.delete(`${content}/${id}`)
}

export const editIdFooter = ({ id, data }) => {
  return axios.put(`${content}/${id}`, data)
}

export const changePositionFooter = ({ id, data }) => {
  return axios.patch(`${content}/${id}/position`, data)
}

export const getFooterInfoRecords = () => {
  return axios.get('/general/info/records', {
    params: {
      keys: [
        'footer-info-text',
        'copyright-text',
        'privacy-link',
        'privacy-link-title',
        'support-link',
        'support-link-title',
      ],
    },
  })
}

export const createFooterInfoRecord = (data) => {
  return axios.post('/admin/general/info/records', data)
}

export const editInfoRecord = (id, data) => {
  return axios.put(`/admin/general/info/records/${id}`, data)
}

export const disableInfoRecord = (id) => {
  return axios.patch(`/admin/general/info/records/${id}/disable`)
}

export const enableInfoRecord = (id) => {
  return axios.patch(`/admin/general/info/records/${id}/enable`)
}
