import {
  getHeadingList,
  deleteHeading,
  blockedHeading,
  unBlockedHeading,
} from './crud'
import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import lodash from 'lodash'
import { getAllData } from '../../../helpers/api'

export const actionTypes = {
  setInitialState: 'SET_INITIAL_STATE_HEADING',
  getHeaing: 'GET_DATA_HEADING',
  setHeaing: 'SET_DATA_HEADING',
  SetLoaderState: 'SET_HEADING_LOADER',
  setErrorHeaing: 'SEO_HEADING_ERROR',
  block: 'SET_HEADING_BLOCK',
  delete: 'HEADING_DELETE_ID',
  unBlock: 'SET_HEADING_UNBLOCK',
  changeDataId: 'SET_DATA_ID_HEADING',
  getDataAll: 'GET_ALL_DATA_HEADING',
  setHeaingAll: 'SEL_ALL_DATA_HEADING',
  // setHeaingSuccess: 'SEO_SUCCESS',
}

const initialState = {
  data: [],
  allData: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  loader: false,
  error: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.default: {
      return initialState
    }

    case actionTypes.setInitialState:
      return {
        initialState,
      }

    case actionTypes.setHeaing:
      return {
        ...state,
        ...action.payload,
        loader: false,
        error: false,
      }
    case actionTypes.setHeaingAll:
      return {
        ...state,
        allData: action.payload,
        loader: false,
        error: false,
      }
    case actionTypes.getHeaing:
      return {
        ...state,
        loader: true,
        error: false,
      }
    case actionTypes.setErrorHeaing:
      return {
        ...state,
        loader: false,
        error: false,
      }

    default:
      return state
  }
}

export const setHeaingAction = (data) => ({
  type: actionTypes.setHeaing,
  payload: data,
})

export const getHeaingDataAction = (page = 1) => ({
  type: actionTypes.getHeaing,
  page,
})

export const errorHeading = (id) => ({
  type: actionTypes.setErrorHeaing,
  id,
})

export const blockHeading = (id, error = false) => ({
  type: actionTypes.block,
  id,
  error,
})

export const unBlockHeading = (id, error = false) => ({
  type: actionTypes.unBlock,
  id,
  error,
})

export const deleteHeadingAction = (id, history) => ({
  type: actionTypes.delete,
  id,
  history,
})
export const changeDataId = (data) => ({
  type: actionTypes.changeDataId,
  data,
})

export const setAllHeaingAction = (data) => ({
  type: actionTypes.setHeaingAll,
  payload: data,
})

export const getAllDataHeading = () => ({
  type: actionTypes.getDataAll,
})

export function* saga() {
  yield takeLatest(actionTypes.changeDataId, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.heading.data.map((item) => {
        if (currentData.data.id === item.id) {
          item = currentData.data
        }
        return item
      })

      yield put(setHeaingAction({ data: newData }))
    } catch (e) {
      yield put(errorHeading())
    }
  })

  yield takeEvery(actionTypes.unBlock, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.heading.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(setHeaingAction({ data: newData }))
      yield unBlockedHeading(currentData.id)
      newData = state.adminPanel.heading.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })

      yield put(setHeaingAction({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.heading.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(setHeaingAction({ data: newData }))
      }
      yield put(errorHeading())
    }
  })

  yield takeEvery(actionTypes.block, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.heading.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(setHeaingAction({ data: newData }))
      yield blockedHeading(currentData.id)

      newData = state.adminPanel.heading.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'disabled'
          item.loader = false
        }
        return item
      })

      yield put(setHeaingAction({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.heading.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(setHeaingAction({ data: newData }))
      }
      yield put(errorHeading())
    }
  })

  yield takeEvery(actionTypes.delete, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.heading.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })

      yield put(setHeaingAction({ data: newData }))

      if (currentData.history) {
        currentData.history.push('/admin/content/heading')
      } else {
        yield deleteHeading(currentData.id)
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      const data = state.adminPanel.heading.data.filter(
        (item) => item.id !== currentData.id
      )
      if (state.adminPanel.heading.meta.pager.page === 0) {
        return yield put(getHeaingDataAction(1))
      }
      const meta = (state.adminPanel.heading.meta.pager = {
        ...state.adminPanel.heading.meta.pager,
        total: state.adminPanel.heading.meta.pager.total - 1,
        per_page: state.adminPanel.heading.meta.pager.per_page,
        pages: state.adminPanel.heading.meta.pager.pages,
      })

      if (meta.pages !== 1 && meta.pages - 1 >= meta.total / meta.per_page) {
        meta.pages = meta.pages - 1
      }

      yield put(setHeaingAction({ data: data, meta: { pager: meta } }))

      if (!data.length) {
        if (meta.total !== 0) {
          if (meta.pages !== meta.page) {
            if (meta.page > meta.pages) {
              yield put(getHeaingDataAction(meta.page - 1))
            } else {
              yield put(getHeaingDataAction(meta.page))
            }
          } else {
            yield put(
              getHeaingDataAction(Math.ceil(meta.total / meta.per_page))
            )
          }
        }
      }
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.heading.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })

      yield put(setHeaingAction({ data: newData }))
      yield put(errorHeading())
    }
  })

  yield takeLatest(actionTypes.getHeaing, function* (e) {
    try {
      const { data } = yield getHeadingList(e.page)

      yield put(setHeaingAction(data))
    } catch (e) {
      yield put(errorHeading())
    }
  })

  yield takeLatest(actionTypes.getDataAll, function* (e) {
    try {
      const { data } = yield getAllData(getHeadingList)

      yield put(setAllHeaingAction(data))
    } catch (e) {
      yield put(errorHeading())
    }
  })
  yield
}
