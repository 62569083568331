import axios from 'axios'

export const getCategoryBlog = (slug = '', page = 1) => {
  return axios.get(
    `/blog/articles?category_slug=${slug}&page=${page}&direction=desc`
  )
}

export const getCategoryBlogById = (id = '', page = 1) => {
  return axios.get(
    `/blog/articles?category_id=${id}&page=${page}&direction=desc`
  )
}

export const getCategoryBlogId = (id) => {
  return axios.get(`/blog/articles/${id}`)
}
