import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { get_faq_show, delete_faq, unpublishFaq, publishFaq } from './crud'
import lodash from 'lodash'

export const actionTypes = {
  setData: 'FAQS_SET_FAQS_LIST',
  setDataPage: 'FAQS_SET_FAQS_LIST_PAGE',
  getData: 'FAQS_GET_FAQS_LIST',
  errorData: 'FAQS_GET_FAQS_LIST_ERROR',
  deleteFaq: 'FAQS_DELETE_ID',
  blockFaq: 'FAQS_BLOCK_ID',
  unBlockFaq: 'FAQS_UNBLOCK_ID',
  changeDataId: 'FAQS_CHANGE_DATA',
  setDataInitial: 'FAQ_SET_INITIAL,',
}

const initialAuthState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  initialValues: {
    seo: {
      id: null,
      title: '',
      description: '',
      keywords: '',
      // robots: null,
    },
  },
  isLoading: false,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = persistReducer(
  {
    storage,
    key: 'faq',
    blacklist: [
      'data',
      'meta',
      'error',
      'disabled',
      'globalLoading',
      'isLoading',
      'initialValues',
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.getData: {
        return {
          ...state,
          data: [],
          isLoading: true,
          globalLoading: true,
        }
      }
      case actionTypes.setDataInitial:
        return {
          ...state,
          initialValues: {
            ...action.payload,
          },
          loader: false,
          error: false,
        }
      case actionTypes.setData: {
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          error: false,
          disabled: false,
          globalLoading: false,
        }
      }

      case actionTypes.errorData: {
        return {
          ...state,
          isLoading: false,
          error: true,
          disabled: false,
          globalLoading: false,
        }
      }

      case actionTypes.deleteFaq: {
        return {
          ...state,
          isLoading: true,
          error: false,
          disabled: true,
        }
      }

      case actionTypes.blockFaq:
        return { ...state, disabled: true, isLoading: true, error: false }
      case actionTypes.unBlockFaq:
        return { ...state, disabled: true, isLoading: true, error: false }
      case actionTypes.changePage:
        return { ...state, disabled: true, isLoading: true, error: false }
      default:
        return state
    }
  }
)
export const getData = (id = 1) => ({
  type: actionTypes.getData,
  id,
})

export const changePage = (page) => ({
  type: actionTypes.setDataPage,
  data: page,
})

export const deleteFaq = (id, history) => ({
  type: actionTypes.deleteFaq,
  id,
  history,
})

export const setDataInitialData = (data) => ({
  type: actionTypes.setDataInitial,
  payload: data,
})

export const blockFaq = (id, error = false) => ({
  type: actionTypes.blockFaq,
  id,
  error,
})

export const unBlockFaq = (id, error = false) => ({
  type: actionTypes.unBlockFaq,
  id,
  error,
})

export const changeDataId = (data) => ({
  type: actionTypes.changeDataId,
  data,
})

export const actions = {
  getData: () => ({
    type: actionTypes.getData,
  }),
  updateData: (data) => ({ type: actionTypes.setData, payload: data }),
  error: () => ({ type: actionTypes.errorData }),
}

export function* saga() {
  yield takeLatest(actionTypes.changeDataId, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.faq.data.map((item) => {
        if (currentData.data.id === item.id) {
          item = currentData.data
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.unBlockFaq, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.faq.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
      yield publishFaq(currentData.id)
      newData = state.adminPanel.faq.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'published'
          item.loader = false
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.faq.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.updateData({ data: newData }))
      }
      yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.blockFaq, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.faq.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
      yield unpublishFaq(currentData.id)

      newData = state.adminPanel.faq.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'unpublished'
          item.loader = false
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.faq.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.updateData({ data: newData }))
      }
      yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.deleteFaq, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.faq.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))

      if (currentData.history) {
        currentData.history.push('/admin/content/heading')
      } else {
        yield delete_faq(currentData.id)
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      if (state.adminPanel.faq.meta.pager.page === 0) {
        return yield put(get_faq_show(1))
      }

      const data = state.adminPanel.faq.data.filter(
        (item) => item.id !== currentData.id
      )
      const meta = (state.adminPanel.faq.meta.pager = {
        ...state.adminPanel.faq.meta.pager,
        total: state.adminPanel.faq.meta.pager.total - 1,
        per_page: state.adminPanel.faq.meta.pager.per_page,
        pages: state.adminPanel.faq.meta.pager.pages,
      })
      if (meta.pages !== 1 && meta.pages - 1 >= meta.total / meta.per_page) {
        meta.pages = meta.pages - 1
      }
      // const OBJECT = yield get_faq_show(meta.page)
      // let newData = OBJECT.data.data
      yield put(actions.updateData({ data: data, meta: { pager: meta } }))
      if (!data.length) {
        if (meta.total !== 0) {
          if (meta.pages !== meta.page) {
            if (meta.page > meta.pages) {
              yield put(getData(meta.page - 1))
            } else {
              yield put(getData(meta.page))
            }
          } else {
            yield put(getData(Math.ceil(meta.total / meta.per_page)))
          }
        }
      }
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.faq.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
      yield put(actions.error())
    }
  })

  yield takeLatest(actionTypes.getData, function* (currentData) {
    try {
      const { data } = yield get_faq_show(currentData.id)

      yield put(actions.updateData(data))
    } catch (e) {
      yield put(actions.error())
    }
  })

  yield takeLatest(actionTypes.setDataPage, function* (e) {
    try {
      const { data } = yield get_faq_show(e.page)

      yield put(actions.updateData(data))
    } catch (e) {
      yield put(actions.error())
    }
  })
}
