import { put, takeLatest, takeEvery, select } from 'redux-saga/effects'

import {
  getListRequisites,
  enableIdRequisites,
  disableIdRequisites,
  deleteIdRequisites,
  getRecords,
  getListContact,
} from './crud'
import lodash from 'lodash'

export const actionTypes = {
  setInitialState: 'SET_PAGE_CONTACTS_INITIAL_VALUES',
  getDataPage: 'GET_DATA_PAGE_CONTACTS',
  setData: 'SET_DATA_PAGE_CONTACTS',
  SetLoaderState: 'SET_PAGE_CONTACTS_LOADER',
  setError: 'PAGE_CONTACTS_SET_ERROR',
  addRequiset: 'PAGE_CONTACTS_ADD_REQUISET',
  setDataRequiset: 'PAGE_CONTACTS_SET_REQUISET',
  getDataRequisets: 'PAGE_CONTACTS_GET_REQUISET',
  disableRequisite: 'PAGE_CONTACTS_SET_ID_DISABLED_REQUISET',
  enableRequisite: 'PAGE_CONTACTS_SET_ID_ENABLED_REQUISET',
  deleteRequsit: 'PAGE_CONTACTS_DELETE_ID_REQUISET',
  getDataContentContacts: 'PAGE_CONTACTS_GET_MAIN_DATA',
  getDataContentRecords: 'PAGE_CONTACTS_GET_RECORDER_DATA',
  setDataContacts: 'PAGE_CONTACTS_SET_MAIN_CONTACTS_DATA',
  setDataRecords: 'PAGE_CONTACTS_SET_MAIN_RECORDS_DATA',
}

const initialAuthState = {
  initialValues: {
    seo: {
      id: null,
      title: '',
      description: '',
      keywords: '',
    },
    'google-map-place-longitude': { value: '', id: '' },
    'google-map-place-latitude': { value: '', id: '' },
    timework: { value: '', id: '' },
    email: { value: '', id: '' },
    address: { value: '', id: '' },
    phone: { value: '', id: '' },
    loaderContacts: false,
    loaderRecords: false,
  },
  requesites: {
    data: [],
    isLoading: false,
  },
  loader: false,
  error: false,
}

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.getDataRequisets: {
      return {
        ...state,
        loader: false,
        requesites: {
          ...state.requesites,
          isLoading: true,
        },
      }
    }
    case actionTypes.setDataContacts: {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          ...action.payload,
          loaderContacts: false,
        },
      }
    }
    case actionTypes.setDataRecords: {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          ...action.payload,
          loaderRecords: false,
        },
      }
    }
    case actionTypes.getDataContentRecords: {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          loaderRecords: true,
        },
      }
    }
    case actionTypes.getDataContentContacts: {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          loaderContacts: true,
        },
      }
    }
    case actionTypes.default: {
      return initialAuthState
    }

    case actionTypes.setInitialState:
      return {
        initialAuthState,
      }

    case actionTypes.setData:
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          ...action.payload,
        },
        loader: false,
        error: false,
      }
    case actionTypes.getDataPage:
      return {
        ...state,
        loader: true,
        error: false,
      }
    case actionTypes.setError:
      return {
        ...state,
        loader: false,
        error: false,
      }
    case actionTypes.setDataRequiset:
      return {
        ...state,
        loader: false,
        requesites: {
          ...state.requesites,
          data: action.payload,
          isLoading: false,
        },
      }
    default:
      return state
  }
}

export const setDataRecords = (data) => ({
  type: actionTypes.setDataRecords,
  payload: data,
})

export const setDataContacts = (data) => ({
  type: actionTypes.setDataContacts,
  payload: data,
})

export const getDataRequisets = () => ({
  type: actionTypes.getDataRequisets,
})

export const getDataContentRecords = () => ({
  type: actionTypes.getDataContentRecords,
})

export const getDataContentContacts = () => ({
  type: actionTypes.getDataContentContacts,
})

export const addRequiset = (data) => ({
  type: actionTypes.addRequiset,
  payload: data,
})

const setDataRequsit = (data) => ({
  type: actionTypes.setDataRequiset,
  payload: data,
})

export const getDataPage = (data) => ({
  type: actionTypes.getDataPage,
  data,
})

export const setData = (data) => ({
  type: actionTypes.setData,
  payload: data,
})

export const disableRequisite = (id, error = true) => ({
  type: actionTypes.disableRequisite,
  id,
  error,
})

export const enableRequisite = (id, error = true) => ({
  type: actionTypes.enableRequisite,
  id,
  error,
})

export const deleteRequsite = (id) => ({
  type: actionTypes.deleteRequsit,
  id,
})

export function* saga() {
  yield takeLatest(actionTypes.getDataContentRecords, function* () {
    try {
      const { data } = yield getRecords(1)
      const newObject = {}
      data.data.map((item) => {
        item.type = item.key
        delete item.key
        newObject[item.type] = item
        return item
      })

      yield put(setDataRecords(newObject))
    } catch (e) {}
  })

  yield takeLatest(actionTypes.getDataContentContacts, function* () {
    try {
      const { data } = yield getListContact(1, ['contact_page'])
      const newObject = {}
      data.data.map((item) => {
        newObject[item.type] = item
        return item
      })
      yield put(setDataContacts(newObject))
    } catch (e) {
      console.log(e)
    }
  })
  // getDataContentRecords
  yield takeLatest(actionTypes.getDataRequisets, function* () {
    const { data } = yield getListRequisites(1)
    yield put(setDataRequsit(data.data))
  })

  yield takeEvery(actionTypes.addRequiset, function* (e) {
    try {
      const state = yield select()
      let newData = state.adminPanel.contacts.requesites.data
      if (e.payload.id && lodash.some(newData, { id: e.payload.id })) {
        newData = newData.map((item) => {
          if (item.id === e.payload.id) {
            item = e.payload
          }
          return item
        })
      } else {
        newData = [...newData, e.payload]
      }
      yield put(setDataRequsit(newData))
    } catch (e) {
      console.log(e)
    }
  })

  yield takeEvery(actionTypes.disableRequisite, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.contacts.requesites.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(setDataRequsit(newData))

      yield disableIdRequisites(currentData.id)
      newData = state.adminPanel.contacts.requesites.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'disable'
          item.loader = false
        }
        return item
      })

      yield put(setDataRequsit(newData))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.contacts.requesites.data.map(
          (item) => {
            if (currentData.id === item.id) {
              item.loader = false
            }
            return item
          }
        )

        yield put(setDataRequsit(newData))
      }
    }
  })

  yield takeEvery(actionTypes.enableRequisite, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.contacts.requesites.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })
      yield put(setDataRequsit(newData))
      yield enableIdRequisites(currentData.id)
      newData = state.adminPanel.contacts.requesites.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })
      yield put(setDataRequsit(newData))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.contacts.requesites.data.map(
          (item) => {
            if (currentData.id === item.id) {
              item.loader = false
            }
            return item
          }
        )
        yield put(setDataRequsit(newData))
      }
    }
  })

  yield takeEvery(actionTypes.deleteRequsit, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.contacts.requesites.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })
      yield put(setDataRequsit(newData))
      yield deleteIdRequisites(currentData.id)

      const data = state.adminPanel.contacts.requesites.data.filter(
        (item) => item.id !== currentData.id
      )

      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(setDataRequsit(data))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.contacts.requesites.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })
      yield put(setDataRequsit(newData))
    }
  })
}
