import { put, takeLatest } from 'redux-saga/effects'
import { showSeoUrl } from '../../SEO/_redux/crud'

export const actionTypes = {
  setInitialState: 'SET_PAGE_PARTNERS_INITIAL_VALUES',
  getDataPage: 'GET_DATA_PAGE_PARTNERS',
  setData: 'SET_DATA_PAGE_PARTNERS',
  SetLoaderState: 'SET_PAGE_PARTNERS_LOADER',
  setError: 'PAGE_PARTNERS_SET_ERROR',
}

const initialState = {
  initialValues: {
    seo: {
      id: null,
      title: '',
      description: '',
      keywords: '',
    },
  },
  loader: false,
  error: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.default: {
      return initialState
    }

    case actionTypes.setInitialState:
      return {
        initialState,
      }

    case actionTypes.setData:
      return {
        ...state,
        initialValues: {
          ...action.payload,
        },
        loader: false,
        error: false,
      }
    case actionTypes.getDataPage:
      return {
        ...state,
        loader: true,
        error: false,
      }
    case actionTypes.setError:
      return {
        ...state,
        loader: false,
        error: false,
      }
    default:
      return state
  }
}

export const getDataPage = (data) => ({
  type: actionTypes.getDataPage,
  data,
})

export const setData = (data) => ({
  type: actionTypes.setData,
  payload: data,
})

export function* saga() {
  yield takeLatest(actionTypes.getDataPage, function* (e) {
    try {
      const { data } = yield showSeoUrl('partners')

      yield put(setData({ seo: data }))
    } catch (e) {
      yield put(actionTypes.setError())
    }
  })
}
