import axios from 'axios'

const path = '/admin/query/products'

export function getProductTemplateCompanyQuery(page = 1, product_id) {
  return axios.get(`${path}/${product_id}/companies?page=${page}`)
}

export function deleteProductTemplateCompanyQuery(product_id, id) {
  return axios.delete(`${path}/${product_id}/companies/${id}`, {
    data: {},
  })
}
export function createProductTemplateCompanyQuery(product_id, data) {
  return axios.post(`${path}/${product_id}/companies`, data)
}

export function editProductTemplateCompanyQuery(product_id, id, data) {
  return axios.put(`${path}/${product_id}/companies/${id}`, data)
}

// export function getProductTemplateCompanyQuery(product_id, id) {
//   return axios.get(`${path}/${product_id}/companies/${id}`)
// }
