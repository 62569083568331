import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deleteProductDirectoriesRated,
  getProductDirectoriesRateds,
  blockProductDirectoriesRated,
  unBlockProductDirectoriesRated,
  createProductDirectoriesRated,
  editProductDirectoriesRated,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../../helpers/errorsParse'

export const actionTypes = {
  getDirectoriesRatedsDataStart: 'GET_DIRECTORIES_RATEDS_DATA_START',
  getDirectoriesRatedsDataSuccess: 'GET_DIRECTORIES_RATEDS_DATA_SUCCESS',
  deleteDirectoriesRated: 'DELETE_DIRECTORIES_RATED',
  blockDirectoriesRated: 'BLOCK_DIRECTORIES_RATED',
  unBlockDirectoriesRated: 'UN_BLOCK_DIRECTORIES_RATED',
  createDirectoriesRated: 'CREATE_DIRECTORIES_RATED',
  editDirectoriesRated: 'EDIT_DIRECTORIES_RATED',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getDirectoriesRatedsDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getDirectoriesRatedsDataStart: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.unBlockDirectoriesRated: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.blockDirectoriesRated: {
      return {
        ...state,
        isLoading: true,
      }
    }
    default:
      return state
  }
}

export const getDirectoriesRatedsActionStart = (
  template_id,
  page,
  typeCrud
) => ({
  type: actionTypes.getDirectoriesRatedsDataStart,
  template_id,
  page,
  typeCrud,
})

export const getDirectoriesRatedsActionSuccess = (data) => ({
  type: actionTypes.getDirectoriesRatedsDataSuccess,
  payload: data,
})

export const deleteDirectoriesRatedAction = (template_id, id) => ({
  type: actionTypes.deleteDirectoriesRated,
  template_id,
  id,
})

export const blockDirectoriesRatedAction = (template_id, id) => ({
  type: actionTypes.blockDirectoriesRated,
  template_id,
  id,
})

export const unBlockDirectoriesRatedAction = (template_id, id) => ({
  type: actionTypes.unBlockDirectoriesRated,
  template_id,
  id,
})

export const createDirectoriesRatedAction = (template_id, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.createDirectoriesRated,
      template_id,
      data,
      resolve,
      reject,
    })
  })
}

export const editDirectoriesRatedAction = (template_id, id, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.editDirectoriesRated,
      template_id,
      id,
      data,
      resolve,
      reject,
    })
  })
}

export function* saga() {
  yield takeLatest(actionTypes.getDirectoriesRatedsDataStart, function* (e) {
    try {
      const { data } = yield getProductDirectoriesRateds(
        e.template_id,
        e.page,
        e.typeCrud
      )
      yield put(getDirectoriesRatedsActionSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.blockDirectoriesRated, function* (e) {
    try {
      const {
        adminPanel: { directoriesRated },
      } = yield select()
      yield blockProductDirectoriesRated(e.template_id, e.id)

      yield put(
        getDirectoriesRatedsActionStart(
          e.template_id,
          directoriesRated.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.unBlockDirectoriesRated, function* (e) {
    try {
      const {
        adminPanel: { directoriesRated },
      } = yield select()
      yield unBlockProductDirectoriesRated(e.template_id, e.id)

      yield put(
        getDirectoriesRatedsActionStart(
          e.template_id,
          directoriesRated.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.deleteDirectoriesRated, function* (e) {
    try {
      const {
        adminPanel: {
          directoriesRated: { meta },
        },
      } = yield select()

      yield deleteProductDirectoriesRated(e.template_id, e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Справочник успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(getDirectoriesRatedsActionStart(e.template_id, page))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createDirectoriesRated, function* ({
    template_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createProductDirectoriesRated(template_id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editDirectoriesRated, function* ({
    template_id,
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editProductDirectoriesRated(template_id, id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
