import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects'
import { getUserByToken } from './authCrud'

export const actionTypes = {
  Login: 'AUTH_LOGIN_SUCCESS',
  Logout: 'AUTH_LOGOUT_SUCCESS',
  Register: 'AUTH_REGISTRATION_SUCCESS',
  UserRequested: 'USER_REQUESTED_SUCCESS',
  UserLoaded: 'USER_LOADED_SUCCESS',
  ChangeNameUser: 'USER_CHANGE_NAME',
  changeEmailUser: 'USER_CHANGE_EMAIL',
}

const initialAuthState = {
  token_type: undefined,
  expires_in: undefined,
  access_token: undefined,
  refresh_token: undefined,
  user: {
    id: null,
    date: null,
    email: '',
    roles: [],
    entities: [],
    phone: '',
    name: {
      first: '',
      last: '',
    },
    profile: {
      sex: '',
      birthday: null,
    },
  },
}

export const reducer = persistReducer(
  {
    storage,
    key: 'authRoot',
    whitelist: ['access_token', 'refresh_token', 'user'],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { access_token, refresh_token } = action.payload

        return { ...state, access_token, refresh_token }
      }

      case actionTypes.Register: {
        const { authToken } = action.payload

        return { ...state, authToken }
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload
        return { ...state, user }
      }

      case actionTypes.ChangeNameUser:
        return {
          ...state,
          user: {
            ...state.user,
            name: {
              last: action.payload.last_name,
              first: action.payload.first_name,
            },
          },
        }

      case actionTypes.changeEmailUser:
        return {
          ...state,
          user: {
            ...state.user,
            email: action.payload.email,
          },
        }

      default:
        return state
    }
  }
)

export const actions = {
  login: ({ access_token, refresh_token }) => ({
    type: actionTypes.Login,
    payload: { access_token, refresh_token },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  changeNameUser: (data) => ({
    type: actionTypes.ChangeNameUser,
    payload: data,
  }),
  changeEmailUser: (data) => ({
    type: actionTypes.changeEmailUser,
    payload: data,
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data } = yield getUserByToken()

    yield put(actions.fulfillUser(data))
  })
}
