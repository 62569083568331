import { put, takeLatest } from 'redux-saga/effects'
import { showSeo } from './crud'

export const actionTypes = {
  setInitialState: 'SET_SEO_INITIAL_VALUES',
  getSeo: 'GET_DATA_SEO',
  setSeo: 'SET_DATA_SEO',
  SetLoaderState: 'SET_SEO_LOADER',
  setErrorSeo: 'SEO_SET_ERROR',
}

const initialAuthState = {
  data: {
    id: null,
    title: null,
    description: null,
    keywords: null,
    robots: null,
  },
  loader: false,
  error: false,
}

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.default: {
      return initialAuthState
    }

    case actionTypes.setInitialState:
      return {
        initialAuthState,
      }

    case actionTypes.setSeo:
      return {
        ...state,
        ...action.payload,
        loader: false,
        error: false,
      }
    case actionTypes.getSeo:
      return {
        ...state,
        loader: true,
        error: false,
      }
    case actionTypes.setErrorSeo:
      return {
        ...state,
        loader: false,
        error: false,
      }

    default:
      return state
  }
}

export const setSeoAction = (data) => ({
  type: actionTypes.setSeo,
  data,
})

export const getSeoDataAction = (id) => ({
  type: actionTypes.getSeo,
  id,
})

export function* saga() {
  yield takeLatest(actionTypes.getSeo, function* (e) {
    try {
      const { data } = yield showSeo(e.id)
      yield put(actionTypes.setSeo(data))
    } catch (e) {
      yield put(actionTypes.setErrorSeo())
    }
  })
}
