import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deleteProductTemplateCompanyQuery,
  getProductTemplateCompanyQuery,
  createProductTemplateCompanyQuery,
  editProductTemplateCompanyQuery,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  getProductTemplateCompanyQueryStart:
    'GET_PRODUCT_TEMPLATE_QUERY_COMPANY_DATA_START',
  getProductTemplateCompanyQuerySuccess:
    'GET_PRODUCT_TEMPLATE_QUERY_COMPANY_DATA_SUCCESS',
  deleteProductTemplateCompanyQuery: 'DELETE_PRODUCT_TEMPLATE_QUERY_COMPANY',
  createProductTemplateCompanyQuery: 'CREATE_PRODUCT_TEMPLATE_QUERY_COMPANY',
  editProductTemplateCompanyQuery: 'EDIT_PRODUCT_TEMPLATE_QUERY_COMPANY',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getProductTemplateCompanyQuerySuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getProductTemplateCompanyQueryStart: {
      return {
        ...state,
        data: initialState.data,
        meta: initialState.meta,
        isLoading: true,
      }
    }

    case actionTypes.getGeneralDictionaryValuesSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export const actions = {
  getProductTemplateCompanyQueryStart: (template_id, page) => ({
    type: actionTypes.getProductTemplateCompanyQueryStart,
    template_id,
    page,
  }),
  getProductTemplateCompanyQuerySuccess: (data) => ({
    type: actionTypes.getProductTemplateCompanyQuerySuccess,
    payload: data,
  }),
  deleteProductTemplateCompanyQuery: (product_id, id) => ({
    type: actionTypes.deleteProductTemplateCompanyQuery,
    product_id,
    id,
  }),
  createProductTemplateCompanyQuery: (product_id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.createProductTemplateCompanyQuery,
        product_id,
        data,
        resolve,
        reject,
      })
    })
  },
  editProductTemplateCompanyQuery: (product_id, id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.editProductTemplateCompanyQuery,
        product_id,
        id,
        data,
        resolve,
        reject,
      })
    })
  },
}

export function* saga() {
  yield takeLatest(actionTypes.getProductTemplateCompanyQueryStart, function* (
    e
  ) {
    try {
      const { data } = yield getProductTemplateCompanyQuery(
        e.page,
        e.template_id
      )

      yield put(actions.getProductTemplateCompanyQuerySuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.deleteProductTemplateCompanyQuery, function* (
    e
  ) {
    try {
      const {
        adminPanel: {
          productCompanyQuery: { meta },
        },
      } = yield select()

      yield deleteProductTemplateCompanyQuery(e.product_id, e.id)

      let page = 1
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Приоритет успешно удален!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      yield put(actions.getProductTemplateCompanyQueryStart(e.product_id, page))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createProductTemplateCompanyQuery, function* ({
    product_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const {
        adminPanel: {
          productCompanyQuery: { meta },
        },
      } = yield select()

      const response = yield createProductTemplateCompanyQuery(product_id, data)

      yield put(
        actions.getProductTemplateCompanyQueryStart(product_id, meta.pager.page)
      )

      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editProductTemplateCompanyQuery, function* ({
    product_id,
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const {
        adminPanel: {
          productCompanyQuery: { meta },
        },
      } = yield select()

      const response = yield editProductTemplateCompanyQuery(
        product_id,
        id,
        data
      )

      yield put(
        actions.getProductTemplateCompanyQueryStart(product_id, meta.pager.page)
      )

      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
