import React, { Component } from 'react'
import { getListCoverages } from '../redux/crud'
import { getAllData } from '../../../helpers/api'
import { errorParser } from '../../../helpers/errorsParse'
import { errorPlaces } from '../../../../constants'
import { Alert } from 'react-bootstrap'

export default class FormioTree extends Component {
  constructor(props) {
    super(props)
    this.state = {
      coverages: [],
      props: props._data,
      activeCoverage: null,
      isLoading: false,
      errorText: null,
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true, errorText: null })

    const { template_id } = this.props.options

    getAllData(getListCoverages, 1, [template_id, 'enabled'])
      .then((response) => {
        const { data } = response
        this.setState({ coverages: data })
      })
      .catch((e) => {
        this.setState({
          errorText: errorParser(e.response, errorPlaces.content),
        })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  _onChangeHandle = (e) => {
    const { onChange } = this.props
    onChange(e.target.value)
    this.setState({ activeCoverage: e.target.value })
  }

  render() {
    const { coverages, activeCoverage, isLoading, errorText } = this.state
    const { coverage } = this.props._data
    const activatedCoverage = coverage || activeCoverage
    if (isLoading) {
      return <p>Идет загрузка покрытий ...</p>
    }
    if (errorText) {
      return (
        <Alert key={'danger'} variant={'danger'}>
          {errorText}
        </Alert>
      )
    }
    return (
      <>
        <div className="tree_wrapper">
          <ul>
            {coverages.map((coverageItem, index) => {
              return (
                <li key={`coverage-${coverageItem.id}`}>
                  <input
                    type="radio"
                    id={coverageItem.id}
                    onChange={this._onChangeHandle}
                    value={coverageItem.id}
                    checked={coverageItem.id === activatedCoverage}
                    name={'coverage'}
                  />
                  <label htmlFor={coverageItem.id}>{coverageItem.name}</label>
                </li>
              )
            })}
          </ul>
        </div>
      </>
    )
  }
}
