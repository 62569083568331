import axios from 'axios'
import projectConfig from '../../../../config'

const USERS_SHOW = '/admin/users'
const INVITE_USER = 'auth/join-by-invitation/invite'

export function get_user_show(page = 1) {
  return axios.get(`${USERS_SHOW}?page=${page}`)
}

export function delete_user(id) {
  return axios.delete(`${USERS_SHOW}/${id}`)
}

export function block_user(id) {
  return axios.patch(`${USERS_SHOW}/${id}/block`)
}

export function unblock_user(id) {
  return axios.patch(`${USERS_SHOW}/${id}/unblock`)
}

export function show_user(id) {
  return axios.get(`${USERS_SHOW}/${id}`)
}

export function requestUser(data) {
  data.join_url = projectConfig.registerUser
  return axios.post(`${INVITE_USER}`, data)
}

export function changeRoles(id, data) {
  return axios.patch(`${USERS_SHOW}/${id}/change-role`, data)
}
