import currencyFormatter from 'currency-formatter'

function precision(value) {
  return value % 1 === 0 ? 0 : null
}
export const priceFormat = (price) => {
  return currencyFormatter.format(price, {
    decimal: '.',
    thousand: ' ',
    precision: precision(price),
    format: '%v', // %s is the symbol and %v is the value
  })
}
