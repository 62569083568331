import axios from 'axios'

const staticPageUrl = '/admin/products'

export function getProductTechnicalCoefficients(
  product_id,
  coverage_id,
  page = 1
) {
  return axios.get(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/technical-coefficients?page=${page}`
  )
}

export function deleteProductTechnicalCoefficient(product_id, coverage_id, id) {
  return axios.delete(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/technical-coefficients/${id}`,
    {
      data: {},
    }
  )
}

export function blockProductTechnicalCoefficient(product_id, coverage_id, id) {
  return axios.patch(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/technical-coefficients/${id}/disable`
  )
}

export function unBlockProductTechnicalCoefficient(
  product_id,
  coverage_id,
  id
) {
  return axios.patch(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/technical-coefficients/${id}/enable`
  )
}

export function createProductTechnicalCoefficient(
  product_id,
  coverage_id,
  data
) {
  return axios.post(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/technical-coefficients`,
    data
  )
}

export function editProductTechnicalCoefficient(
  product_id,
  coverage_id,
  id,
  data
) {
  return axios.put(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/technical-coefficients/${id}`,
    data
  )
}

export function showProductTechnicalCoefficient(product_id, coverage_id, id) {
  return axios.get(
    `${staticPageUrl}/${product_id}/coverages/${coverage_id}/technical-coefficients/${id}`
  )
}
