import { put, takeLatest } from 'redux-saga/effects'
import {
  deleteRetention,
  createRetention,
  editRetention,
  unBlockRetention,
  blockRetention,
  getGeneralRetentionFields,
  deleteRetentionField,
  createRetentionField,
  editRetentionField,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  deleteRetention: 'DELETE_RETENTIONY',
  createRetention: 'CREATE_RETENTIONY',
  changeStatusRetention: 'CHANGE_STATUS_RETENTION',
  editRetention: 'EDIT_RETENTIONY',
  loadingStop: 'LOADING_STOP',
  changeStatus: 'CHANGE_STATUS',
  getReletionFieldsStart: 'GET_RETENTIONY_FIELDS_DATA_START',
  getReletionFieldsSuccess: 'GET_RETENTIONY_FIELDS_DATA_SUCCESS',
  deleteRetentionField: 'DELETE_RETENTIONY_FIELD',
  createRetentionField: 'CREATE_RETENTIONY_FIELD',
  editRetentionField: 'EDIT_RETENTIONY_FIELD',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getReletionFieldsStart: {
      return {
        data: initialState.data,
        isLoading: true,
      }
    }
    case actionTypes.getReletionFieldsSuccess: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      }
    }

    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const actions = {
  deleteRetention: () => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.deleteRetention,
        resolve,
        reject,
      })
    })
  },
  createRetention: (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.createRetention,
        data,
        resolve,
        reject,
      })
    })
  },
  editRetention: (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.editRetention,
        data,
        resolve,
        reject,
      })
    })
  },
  changeStatusRetention: (status) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.changeStatusRetention,
        status,
        resolve,
        reject,
      })
    })
  },
  getReletionFieldsStart: (page) => ({
    type: actionTypes.getReletionFieldsStart,
    page,
  }),
  getReletionFieldsSuccess: (data) => ({
    type: actionTypes.getReletionFieldsSuccess,
    payload: data,
  }),
  deleteRetentionField: (id) => ({
    type: actionTypes.deleteRetentionField,
    id,
  }),
  createRetentionField: (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.createRetentionField,
        data,
        resolve,
        reject,
      })
    })
  },
  editRetentionField: (id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.editRetentionField,
        id,
        data,
        resolve,
        reject,
      })
    })
  },
}

export function* saga() {
  yield takeLatest(actionTypes.deleteRetention, function* (e) {
    try {
      const response = yield deleteRetention()
      e.resolve(response)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Механизм удержания успешно удален!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    } catch (er) {
      errorParser(er.response)
      e.reject(er.response)
    }
  })

  yield takeLatest(actionTypes.createRetention, function* ({
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createRetention(data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editRetention, function* ({
    data,
    resolve,
    reject,
  }) {
    try {
      console.log(data)
      const response = yield editRetention(data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.changeStatusRetention, function* ({
    status,
    resolve,
    reject,
  }) {
    try {
      let response
      if (status === 'enabled') {
        response = yield unBlockRetention()
      } else {
        response = yield blockRetention()
      }
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.getReletionFieldsStart, function* (e) {
    try {
      const { data } = yield getGeneralRetentionFields(e.page)

      yield put(actions.getReletionFieldsSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.deleteRetentionField, function* (e) {
    try {
      yield deleteRetentionField(e.id)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Поле успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(actions.getReletionFieldsStart())
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createRetentionField, function* ({
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createRetentionField(data)

      yield put(actions.getReletionFieldsStart())

      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
  yield takeLatest(actionTypes.editRetentionField, function* ({
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editRetentionField(id, data)
      yield put(actions.getReletionFieldsStart())
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
