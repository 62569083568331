import axios from 'axios'
// import projectConfig from '../../../../config'

const content = '/admin/menu/header/items'

export const getListHeader = (page = 1) => {
  return axios.get(`${content}?page=${page}`)
}

export const getIdHeader = (id) => {
  return axios.get(`${content}/${id}`)
}

export const createHeader = (data) => {
  return axios.post(`${content}`, data)
}

export const editHeader = (id, data) => {
  return axios.post(`${content}/${id}`, data)
}

export const disableIdHeader = (id, data) => {
  return axios.patch(`${content}/${id}/disable`, data)
}

export const enableIdHeader = (id, data) => {
  return axios.patch(`${content}/${id}/enable`, data)
}

export const deleteIdHeader = (id) => {
  return axios.delete(`${content}/${id}`)
}

export const editIdHeader = ({ id, data }) => {
  return axios.put(`${content}/${id}`, data)
}

export const changePositionHeader = ({ id, data }) => {
  return axios.patch(`${content}/${id}/position`, data)
}
