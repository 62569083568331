import React, { Component } from 'react'
import Spinner from '../../../../../catalog_app/components/Spinner'
import AsyncSelect from 'react-select/async'
import {
  customStylesFormInputFms,
  customStyles,
} from '../../../formioSelect/select'
import { getDadataFms } from '../../redux/crud'
import MaskedInput from 'react-text-mask'
import { CODE_FMS_CODE_MASK, formioDataTypes } from '../../../../../constants'
import {
  setContainerStyle,
  setLabelStyle,
  setWrapperStyle,
  setContainerValueStyle,
} from '../../../SetStylesFormio/styles'
import { components } from 'react-select'

export default class DadataFms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productLoading: false,
      disableForm: false,
      issued: null,
    }
    this._isMounted = true
    // this._searchFms = _.debounce(this._searchFms, 1000);
    this._timeOutFms = null
  }

  componentDidMount() {
    this._isMounted = true

    setTimeout(() => {
      /* есть особеность в самом формио, незнаю почему но нужна задержка, может помочь даже задержка в 1 милисекунд */
      const {
        component: { key },
        _data,
      } = this.props
      this.setState((prevState) => {
        return {
          ...prevState,
          ..._data[`${key}`]?.submit,
        }
      })
    }, 100)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onChangeValue = (type, e) => {
    this.setState({
      [type]: e,
    })
    this.props.onChange({
      submit: {
        issued: e,
        formioDataType: formioDataTypes.dadataFms,
      },
    })
  }

  _searchFms = async (e) => {
    clearTimeout(this._timeOutFms)
    const promise = new Promise((resolve) => {
      this._timeOutFms = setTimeout(() => {
        getDadataFms(e)
          .then(({ data }) => {
            resolve(data.data)
            return data.data
          })
          .catch((er) => {
            resolve([])
          })
      }, 1000)
    })
    return promise
  }

  render() {
    const { component } = this.props
    const { issued } = this.state

    if (this.state.productLoading) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '200px' }}
        >
          <Spinner animation="border" variant="primary">
            <span className="sr-only">Загрузка...</span>
          </Spinner>
        </div>
      )
    }
    return (
      <div className="dadataWrapper">
        <div
          className="container-fields-wrapper"
          style={setContainerStyle(component)}
        >
          <div className="field-wrapper" style={setWrapperStyle(component)}>
            <label style={setLabelStyle(component)} htmlFor={'issued'}>
              Код подразделения
            </label>
          </div>
          <div style={setContainerValueStyle(component)}>
            <AsyncSelect
              loadingMessage={() => 'Идет загрузка...'}
              className={'dadataInputContainer'}
              noOptionsMessage={(e) => 'Список пуст'}
              styles={customStylesFormInputFms}
              getOptionLabel={(option) => option['code']}
              loadOptions={async (e) => {
                return await this._searchFms(e)
              }}
              onChange={(e) => {
                this.onChangeValue('issued', e)
              }}
              placeholder={'_ _ _ _ _ _'}
              value={issued}
              getOptionValue={(item) => item}
              components={{
                DropdownIndicator: null,
                Option: (props) => {
                  return (
                    <components.Option {...props}>
                      {`${props.data.code} - ${props.data.name}`}
                    </components.Option>
                  )
                },
                Input: (inputProps) => {
                  return (
                    <MaskedInput
                      onBlur={inputProps.onBlur}
                      onFocus={inputProps.onFocus}
                      onChange={inputProps.onChange}
                      id={inputProps.id}
                      style={{
                        width: '124px',
                        padding: '0 26px 0 26px',
                      }}
                      value={inputProps.value}
                      mask={CODE_FMS_CODE_MASK}
                      className="form-control"
                      render={(textMaskRef, props) => (
                        <input
                          {...props}
                          ref={(node) => {
                            textMaskRef(node) // Keep this so the component can still function
                            inputProps.innerRef(node)
                          }}
                        />
                      )}
                    />
                  )
                },
              }}
            />
          </div>
        </div>

        <div
          className="container-fields-wrapper"
          style={setContainerStyle(component)}
        >
          <div className="field-wrapper" style={setWrapperStyle(component)}>
            <label style={setLabelStyle(component)} htmlFor={'issued'}>
              Кем выдан
            </label>
          </div>
          <div style={setContainerValueStyle(component)}>
            <AsyncSelect
              styles={customStyles}
              className={'dadataInputContainer'}
              name="issued"
              // cacheOptions
              defaultOptions={[]}
              loadingMessage={() => 'Идет загрузка...'}
              placeholder="Кем выдан"
              noOptionsMessage={(e) => 'Список пуст'}
              components={{ DropdownIndicator: null }}
              isSearchable={true}
              value={issued}
              onChange={(e) => {
                this.onChangeValue('issued', e)
              }}
              getOptionLabel={(option) => {
                return option['value']
              }}
              getOptionValue={(item) => item}
              loadOptions={async (e) => {
                return await this._searchFms(e)
              }}
            />
            {/* {false ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">error</div>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    )
  }
}
