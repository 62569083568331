import axios from 'axios'

const path = '/admin/blog/articles'
// http://localhost:8001/api/v1/admin/blog/articles/{id}/main-category
export function setArticleImg(id, data) {
  return axios.post(`${path}/${id}/images`, data)
}

export function ArticleCreate(data) {
  return axios.post(`${path}`, data)
}

export function editMainArticle(id, data) {
  return axios.put(`${path}/${id}`, data)
}

export function getArticleList(page = 1) {
  return axios.get(`${path}?page=${page}`)
}

export function ArticleGet(id) {
  return axios.get(`${path}/${id}`)
}
// deleteArticle, blockedArticle, unBlockArticle
export function deleteArticle(id) {
  return axios.delete(`${path}/${id}`)
}
export function blockedArticle(id) {
  return axios.patch(`${path}/${id}/unpublish`)
}
export function unBlockedArticle(id) {
  return axios.patch(`${path}/${id}/publish`)
}

export function deleteImageArticle(id) {
  return axios.delete(`${path}/${id}/images`)
}

export function ArticleAddCategories(id, data) {
  return axios.post(`${path}/${id}/categories`, data)
}

export function ArticleDeleteCategories(id, categoryId) {
  return axios.delete(`${path}/${id}/categories/${categoryId}`)
}

export function ArticleChangeMainCategories(id, data) {
  return axios.post(`${path}/${id}/main-category`, data)
}

export function ArticleChangePosition(id, data) {
  return axios.patch(`${path}/${id}/position`, data)
}
