import React, { Component } from 'react'
import { PaginationLinks } from './PaginationLinks'
import { PaginationToolbar } from './PaginationToolbar'

export default class Pagination extends Component {
  render() {
    const { children, paginationProps, isLoading } = this.props
    return (
      <>
        {children}
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <PaginationLinks paginationProps={paginationProps} />
          <PaginationToolbar
            isLoading={isLoading}
            paginationProps={paginationProps}
          />
        </div>
      </>
    )
  }
}
