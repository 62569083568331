import axios from 'axios'

const path = '/admin/companies'
const queryRiskPath = '/admin/query/risks'

export function getProductsCompanyCoverageRisk(
  page = 1,
  companyId,
  productId,
  coveragesId
) {
  return axios.get(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}/risks?page=${page}`
  )
}

export function deleteProductCompanyCoverageRisk(
  companyId,
  productId,
  coveragesId,
  riskId
) {
  return axios.delete(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}/risks/${riskId}`
  )
}

export function unBlockProductCompanyCoverageRisk(
  companyId,
  productId,
  coveragesId,
  riskId
) {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}/risks/${riskId}/enable`
  )
}

export function editProductCompanyCoverageRisk(
  companyId,
  productId,
  coveragesId,
  riskId,
  data
) {
  return axios.put(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}/risks/${riskId}`,
    data
  )
}

export function showProductCompanyCoverageRiskId(
  companyId,
  productId,
  coveragesId,
  riskId
) {
  return axios.get(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}/risks/${riskId}`
  )
}

export function createProductCompanyCoverageRisk(
  companyId,
  productId,
  coveragesId,
  data
) {
  return axios.post(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}/risks`,
    data
  )
}

export function blockProductCompanyCoverageRisk(
  companyId,
  productId,
  coveragesId,
  riskId
) {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}/risks/${riskId}/disable`
  )
}

export function getProductRisks(page = 1, product_id, coverage_id) {
  return axios.get(
    `/admin/products/${product_id}/coverages/${coverage_id}/risks?page=${page}`
  )
}

export function showQueryProductRisks(page = 1, product_id, coverage_id) {
  return axios.get(
    `${queryRiskPath}?page=${page}&product_id=${product_id}&coverage_id=${coverage_id}`
  )
}

export function createQueryProductRisk(data) {
  return axios.post(queryRiskPath, data)
}

export function editQueryProductRisk(id, data) {
  return axios.put(`${queryRiskPath}/${id}`, data)
}
