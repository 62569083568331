import axios from 'axios'

const content_contact = '/admin/contacts'

export const createContact = (data) => {
  return axios.post(`${content_contact}`, data)
}
export const editContact = (id, data) => {
  return axios.put(`${content_contact}/${id}`, data)
}
export const deleteIdContact = (id) => {
  return axios.delete(`${content_contact}/${id}`)
}

export const disableIdContact = (id) => {
  return axios.patch(`/admin/contacts/${id}/disable`)
}
export const getListContact = (page = 1, placement = []) => {
  const placementMap = []
  placement.map((item) => {
    placementMap.push(`&placement=${item}`)
  })
  return axios.get(
    `${content_contact}?page=${page}${placementMap.join('')}`,
    {}
  )
}

// record
// http://localhost:8001/api/v1/admin/general/info/records
const content_record = '/admin/general/info/records'

export const createRecord = (data) => {
  return axios.post(`${content_record}`, data)
}
export const editRecord = (id, data) => {
  return axios.put(`${content_record}/${id}`, data)
}
export const deleteIdRecord = (id) => {
  return axios.delete(`${content_record}/${id}`)
}

export const disableIdRecord = (id) => {
  return axios.patch(`/admin/general/info/records/${id}/disable`)
}

export const getRecords = () => {
  return axios.get('/general/info/records', {
    params: {
      keys: [
        'google-map-place-longitude',
        'google-map-place-latitude',
        'timework',
      ],
    },
  })
}

const content_requisites = '/admin/general/requisites'

export const createRequisites = (data) => {
  return axios.post(`${content_requisites}`, data)
}
export const editRequisites = (id, data) => {
  return axios.put(`${content_requisites}/${id}`, data)
}
export const deleteIdRequisites = (id) => {
  return axios.delete(`${content_requisites}/${id}`)
}
export const disableIdRequisites = (id) => {
  return axios.patch(`${content_requisites}/${id}/disable`)
}
export const enableIdRequisites = (id) => {
  return axios.patch(`${content_requisites}/${id}/enable`)
}
export const getListRequisites = (page) => {
  return axios.get(`${content_requisites}?page=${page}`)
}

// http://localhost:8001/api/v1/admin/general/requisites
