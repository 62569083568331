import { put, takeLatest } from 'redux-saga/effects'
import {
  productDictionaryValueShowAll,
  companyProductDictionaryValueShowAll,
} from './crud'
import lodash from 'lodash'
import { getAllData } from '../../../helpers/api'
import { showProductCompanyId } from '../../ProductCompany/redux/crud'

export const actionTypes = {
  getProductCompanyDirectoriesRatedsDataStart:
    'GET_PRODUCT_COMPANY_DIRECTORIES_RATEDS_DATA_START',
  getProductCompanyDirectoriesRatedsDataSuccess:
    'GET_PRODUCT_COMPANY_DIRECTORIES_RATEDS_DATA_SUCCESS',
  getProductCompanyDirectoriesRatedsDataError:
    'GET_PRODUCT_COMPANY_DIRECTORIES_RATEDS_DATA_ERROR',
}

const initialState = {
  data: [],
  isLoading: true,
  error: false,
  disabled: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getProductCompanyDirectoriesRatedsDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
        error: false,
      }
    }
    case actionTypes.getProductCompanyDirectoriesRatedsDataError: {
      return {
        ...state,
        error: true,
        isLoading: false,
      }
    }
    case actionTypes.getProductCompanyDirectoriesRatedsDataStart: {
      return {
        ...state,
        isLoading: true,
        error: false,
      }
    }
    default:
      return state
  }
}

export const getCompanyDirectoriesRatedsActionStart = (
  company_id,
  company_product_id,
  directory_id
) => ({
  type: actionTypes.getProductCompanyDirectoriesRatedsDataStart,
  company_id,
  company_product_id,
  directory_id,
})

export const getProductCompanyDirectoriesRatedsDataError = () => {
  return {
    type: actionTypes.getProductCompanyDirectoriesRatedsDataError,
  }
}

export const getDirectoriesRatedsActionSuccess = (data) => ({
  type: actionTypes.getProductCompanyDirectoriesRatedsDataSuccess,
  payload: data,
})

export function* saga() {
  yield takeLatest(
    actionTypes.getProductCompanyDirectoriesRatedsDataStart,
    function* (e) {
      try {
        let resData = []

        yield showProductCompanyId(e.company_id, e.company_product_id).then(
          async ({ data }) => {
            const promises = []
            promises[0] = getAllData(productDictionaryValueShowAll, 1, [
              data.product_id,
              e.directory_id,
            ])
            promises[1] = getAllData(companyProductDictionaryValueShowAll, 1, [
              e.company_id,
              e.company_product_id,
              e.directory_id,
            ])

            await Promise.allSettled(promises)
              .then(async (res) => {
                if (!lodash.some(res, { status: 'rejected' })) {
                  resData = res[0].value.data.map((item) => {
                    const children = res[1].value.data.find(
                      (itemValue) => itemValue.value_id === item.id
                    )
                    item.children = children ? [children] : []
                    return item
                  })
                } else {
                  throw Error
                }
              })
              .catch((e) => {
                throw e
              })
          }
        )
        yield put(getDirectoriesRatedsActionSuccess({ data: resData }))
      } catch (e) {
        yield put(getProductCompanyDirectoriesRatedsDataError())
      }
    }
  )
}
