import { put, takeLatest } from 'redux-saga/effects'
import {
  deleteFeedback,
  createFeedback,
  editFeedback,
  unBlockFeedback,
  blockFeedback,
  getGeneralFeedbackFields,
  deleteFeedbackField,
  createFeedbackField,
  editFeedbackField,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  deleteFeedback: 'DELETE_FEEDBACK',
  createFeedback: 'CREATE_FEEDBACK',
  changeStatusFeedback: 'CHANGE_STATUS_FEEDBACK',
  editFeedback: 'EDIT_FEEDBACK',
  loadingStop: 'LOADING_STOP_FEEDBACK',
  changeStatus: 'CHANGE_STATUS_FEEDBACK',
  getFeedbackFieldsStart: 'GET_FEEDBACK_FIELDS_DATA_START',
  getFeedbackFieldsSuccess: 'GET_FEEDBACK_FIELDS_DATA_SUCCESS',
  deleteFeedbackField: 'DELETE_FEEDBACK_FIELD',
  createFeedbackField: 'CREATE_FEEDBACK_FIELD',
  editFeedbackField: 'EDIT_FEEDBACK_FIELD',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getFeedbackFieldsStart: {
      return {
        data: initialState.data,
        isLoading: true,
      }
    }
    case actionTypes.getFeedbackFieldsSuccess: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      }
    }

    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const actions = {
  deleteFeedback: () => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.deleteFeedback,
        resolve,
        reject,
      })
    })
  },
  createFeedback: (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.createFeedback,
        data,
        resolve,
        reject,
      })
    })
  },
  editFeedback: (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.editFeedback,
        data,
        resolve,
        reject,
      })
    })
  },
  changeStatusFeedback: (status) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.changeStatusFeedback,
        status,
        resolve,
        reject,
      })
    })
  },
  getFeedbackFieldsStart: (page) => ({
    type: actionTypes.getFeedbackFieldsStart,
    page,
  }),
  getFeedbackFieldsSuccess: (data) => ({
    type: actionTypes.getFeedbackFieldsSuccess,
    payload: data,
  }),
  deleteFeedbackField: (id) => ({
    type: actionTypes.deleteFeedbackField,
    id,
  }),
  createFeedbackField: (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.createFeedbackField,
        data,
        resolve,
        reject,
      })
    })
  },
  editFeedbackField: (id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.editFeedbackField,
        id,
        data,
        resolve,
        reject,
      })
    })
  },
}

export function* saga() {
  yield takeLatest(actionTypes.deleteFeedback, function* (e) {
    try {
      const response = yield deleteFeedback()
      e.resolve(response)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Механизм удержания успешно удален!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    } catch (er) {
      errorParser(er.response)
      e.reject(er.response)
    }
  })

  yield takeLatest(actionTypes.createFeedback, function* ({
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createFeedback(data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editFeedback, function* ({
    data,
    resolve,
    reject,
  }) {
    try {
      console.log(data)
      const response = yield editFeedback(data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.changeStatusFeedback, function* ({
    status,
    resolve,
    reject,
  }) {
    try {
      let response
      if (status === 'enabled') {
        response = yield unBlockFeedback()
      } else {
        response = yield blockFeedback()
      }
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.getFeedbackFieldsStart, function* (e) {
    try {
      const { data } = yield getGeneralFeedbackFields(e.page)

      yield put(actions.getFeedbackFieldsSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.deleteFeedbackField, function* (e) {
    try {
      yield deleteFeedbackField(e.id)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Поле успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(actions.getFeedbackFieldsStart())
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createFeedbackField, function* ({
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createFeedbackField(data)

      yield put(actions.getFeedbackFieldsStart())

      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
  yield takeLatest(actionTypes.editFeedbackField, function* ({
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editFeedbackField(id, data)
      yield put(actions.getFeedbackFieldsStart())
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
