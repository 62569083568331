import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deleteProductTechnicalCoefficient,
  getProductTechnicalCoefficients,
  blockProductTechnicalCoefficient,
  unBlockProductTechnicalCoefficient,
  createProductTechnicalCoefficient,
  editProductTechnicalCoefficient,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  getTechnicalCoefficientsDataStart: 'GET_TECHNICAL_COEFFICIENTS_DATA_START',
  getTechnicalCoefficientsDataSuccess:
    'GET_TECHNICAL_COEFFICIENTS_DATA_SUCCESS',
  deleteTechnicalCoefficient: 'DELETE_TECHNICAL_COEFFICIENT',
  blockTechnicalCoefficient: 'BLOCK_TECHNICAL_COEFFICIENT',
  unBlockTechnicalCoefficient: 'UN_BLOCK_TECHNICAL_COEFFICIENT',
  createTechnicalCoefficient: 'CREATE_TECHNICAL_COEFFICIENT',
  editTechnicalCoefficient: 'EDIT_TECHNICAL_COEFFICIENT',
  loadingStop: 'LOADING_STOP',
}

const initialAuthState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.getTechnicalCoefficientsDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getTechnicalCoefficientsDataStart: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.unBlockTechnicalCoefficient: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.blockTechnicalCoefficient: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const getTechnicalCoefficientsActionStart = (
  product_id,
  coverage_id,
  page
) => ({
  type: actionTypes.getTechnicalCoefficientsDataStart,
  product_id,
  coverage_id,
  page,
})

export const getTechnicalCoefficientsActionSuccess = (data) => ({
  type: actionTypes.getTechnicalCoefficientsDataSuccess,
  payload: data,
})

export const deleteTechnicalCoefficientAction = (
  product_id,
  coverage_id,
  id
) => ({
  type: actionTypes.deleteTechnicalCoefficient,
  product_id,
  coverage_id,
  id,
})

export const blockTechnicalCoefficientAction = (
  product_id,
  coverage_id,
  id
) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.blockTechnicalCoefficient,
      product_id,
      coverage_id,
      id,
      resolve,
    })
  })
}

export const unBlockTechnicalCoefficientAction = (
  product_id,
  coverage_id,
  id
) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.unBlockTechnicalCoefficient,
      product_id,
      coverage_id,
      id,
      resolve,
    })
  })
}

export const createTechnicalCoefficientAction = (
  product_id,
  coverage_id,
  data
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.createTechnicalCoefficient,
      product_id,
      coverage_id,
      data,
      resolve,
      reject,
    })
  })
}

export const editTechnicalCoefficientAction = (
  product_id,
  coverage_id,
  id,
  data
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.editTechnicalCoefficient,
      product_id,
      coverage_id,
      id,
      data,
      resolve,
      reject,
    })
  })
}

export const loadingStopAction = () => ({
  type: actionTypes.loadingStop,
})

export function* saga() {
  yield takeLatest(actionTypes.getTechnicalCoefficientsDataStart, function* (
    e
  ) {
    try {
      const { data } = yield getProductTechnicalCoefficients(
        e.product_id,
        e.coverage_id,
        e.page
      )

      yield put(getTechnicalCoefficientsActionSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.blockTechnicalCoefficient, function* (e) {
    try {
      const {
        adminPanel: { productTechnicalCoefficients },
      } = yield select()
      yield blockProductTechnicalCoefficient(e.product_id, e.coverage_id, e.id)

      yield put(
        getTechnicalCoefficientsActionStart(
          e.product_id,
          e.coverage_id,
          productTechnicalCoefficients.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    } finally {
      e.resolve()
      yield put(loadingStopAction())
    }
  })
  yield takeLatest(actionTypes.unBlockTechnicalCoefficient, function* (e) {
    try {
      const {
        adminPanel: { productTechnicalCoefficients },
      } = yield select()
      yield unBlockProductTechnicalCoefficient(
        e.product_id,
        e.coverage_id,
        e.id
      )

      yield put(
        getTechnicalCoefficientsActionStart(
          e.product_id,
          e.coverage_id,
          productTechnicalCoefficients.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    } finally {
      e.resolve()
      yield put(loadingStopAction())
    }
  })

  yield takeLatest(actionTypes.deleteTechnicalCoefficient, function* (e) {
    try {
      const {
        adminPanel: {
          productTechnicalCoefficients: { meta },
        },
      } = yield select()

      yield deleteProductTechnicalCoefficient(e.product_id, e.coverage_id, e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Риск успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(
        getTechnicalCoefficientsActionStart(e.product_id, e.coverage_id, page)
      )
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createTechnicalCoefficient, function* ({
    product_id,
    coverage_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createProductTechnicalCoefficient(
        product_id,
        coverage_id,
        data
      )
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editTechnicalCoefficient, function* ({
    product_id,
    coverage_id,
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editProductTechnicalCoefficient(
        product_id,
        coverage_id,
        id,
        data
      )
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
