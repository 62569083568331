const initialState = {
  contentSending: false,
  checkedProductInfo: null,
  checkedProductInfoLoading: false,
  setTotalInfoVisible: true,
}

export const actionTypes = {
  setContentSending: 'SET_CONTENT_SENDING',
  setProductInfo: 'SET_PRODUCT_INFO',
  setProductInfoLoading: 'SET_PRODUCT_INFO_LOADING',
  setTotalInfoVisible: 'SET_TOTAL_INFO_VISIBLE',
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setContentSending:
      return {
        ...state,
        contentSending: action.payload,
      }
    case actionTypes.setProductInfo:
      return {
        ...state,
        checkedProductInfo: action.payload,
        checkedProductInfoLoading: false,
      }
    case actionTypes.setProductInfoLoading:
      return {
        ...state,
        checkedProductInfoLoading: action.payload,
      }
    case actionTypes.setTotalInfoVisible:
      return {
        ...state,
        setTotalInfoVisible: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export const actions = {
  setContentSending: (bool) => ({
    type: actionTypes.setContentSending,
    payload: bool,
  }),
  setProductInfo: (info) => ({
    type: actionTypes.setProductInfo,
    payload: info,
  }),
  setProductInfoLoading: (bool) => ({
    type: actionTypes.setProductInfoLoading,
    payload: bool,
  }),
  setTotalInfoVisible: (bool) => ({
    type: actionTypes.setTotalInfoVisible,
    payload: bool,
  }),
}
