import baseEditForm from 'formiojs/components/_classes/component/Component.form'

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: 'display',
        components: [
          // { key: 'label', ignore: true },
          { key: 'myCustomSetting', ignore: true },
          // { key: 'labelPosition', ignore: true },
          // { key: 'placeholder', ignore: true },
          { key: 'customClass', ignore: true },
          { key: 'tabindex', ignore: true },
          { key: 'hide', ignore: true },
          { key: 'placeholder', ignore: true },
          { key: 'hidden', ignore: true },
          { key: 'autofocus', ignore: true },
          { key: 'disabled', ignore: true },
          { key: 'tableView', ignore: true },
          { key: 'modalEdit', ignore: true },
          {
            key: 'hideLabelCheckout',
            type: 'checkbox',
            label: 'Hide Label on checkout step',
            input: true,
          },
        ],
      },
      {
        key: 'data',
        components: [
          {
            type: 'input',
            input: true,
            label: 'Укажите мин дату',
            weight: 12,
            key: 'minDate',
            widget: {
              mode: 'single',
              type: 'calendar',
              format: 'yyyy-MM-dd hh:mm a',
              saveAs: 'date',
              altInput: true,
              time_24hr: false,
              allowInput: true,
              clickOpens: true,
              dateFormat: 'yyyy-MM-ddTHH:mm:ssZ',
              enableDate: true,
              enableTime: false,
              noCalendar: false,
              hourIncrement: 1,
              minuteIncrement: 5,
              useLocaleSettings: false,
            },
          },
          {
            type: 'input',
            input: true,
            label: 'Укажите макс дату',
            weight: 12,
            key: 'maxDate',
            widget: {
              mode: 'single',
              type: 'calendar',
              format: 'yyyy-MM-dd hh:mm a',
              saveAs: 'date',
              altInput: true,
              time_24hr: false,
              allowInput: true,
              clickOpens: true,
              dateFormat: 'yyyy-MM-ddTHH:mm:ssZ',
              enableDate: true,
              enableTime: false,
              noCalendar: false,
              hourIncrement: 1,
              minuteIncrement: 5,
              useLocaleSettings: false,
            },
          },
          {
            ignore: true,
            key: 'defaultValue',
          },
          {
            ignore: true,
            key: 'persistent',
          },
          {
            ignore: true,
            key: 'multiple',
          },
          {
            ignore: true,
            key: 'protected',
          },
          {
            ignore: true,
            key: 'dbIndex',
          },
          {
            ignore: true,
            key: 'encrypted',
          },
          {
            ignore: true,
            key: 'redrawOn',
          },
          {
            ignore: true,
            key: 'customDefaultValuePanel',
          },
          {
            ignore: true,
            key: 'calculateValuePanel',
          },
          {
            ignore: true,
            key: 'calculateServer',
          },
          {
            ignore: true,
            key: 'allowCalculateOverride',
          },
        ],
      },
      {
        key: 'layout',
        ignore: true,
        components: [],
      },
      {
        key: 'validation',
        components: [
          {
            key: 'unique',
            ignore: true,
          },
          {
            key: 'validateOn',
            ignore: true,
          },
        ],
      },
      {
        key: 'api',
        // ignore: true,
        components: [
          {
            key: 'tags',
            ignore: true,
          },
          {
            key: 'properties',
            ignore: true,
          },
        ],
      },
      {
        key: 'logic',
        ignore: true,
        components: [],
      },
    ],
    ...extend
  )
}
