import { put, takeLatest } from 'redux-saga/effects'
import { getReportsCheckoutsForOperators } from './crud'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  getReportsOperatorsCheckoutsStart: 'GET_REPORT_OPERATORS_CHECKOUT_DATA_START',
  getReportsOperatorsCheckoutsFailure:
    'GET_REPORT_OPERATORS_CHECKOUT_DATA_FAILURE',
  getReportsOperatorsCheckoutsSuccess: 'GET_REPORT_OPERATORS_CHECKOUTS_SUCCESS',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getReportsOperatorsCheckoutsStart: {
      return {
        ...state,
        data: [],
        isLoading: true,
      }
    }
    case actionTypes.getReportsOperatorsCheckoutsFailure: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case actionTypes.getReportsOperatorsCheckoutsSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const actions = {
  getReportsCheckoutsForOperatorsStart: (page, filter) => ({
    type: actionTypes.getReportsOperatorsCheckoutsStart,
    page,
    filter,
  }),
  getReportsCheckoutsForOperatorsFailure: () => ({
    type: actionTypes.getReportsOperatorsCheckoutsFailure,
  }),
  getReportsCheckoutsForOperatorsSuccess: (data) => ({
    type: actionTypes.getReportsOperatorsCheckoutsSuccess,
    payload: data,
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.getReportsOperatorsCheckoutsStart, function* (
    e
  ) {
    try {
      const { data } = yield getReportsCheckoutsForOperators(e.page, e.filter)
      yield put(actions.getReportsCheckoutsForOperatorsSuccess(data))
    } catch (e) {
      yield put(actions.getReportsCheckoutsForOperatorsFailure())
      errorParser(e.response)
    }
  })
}
