import { Component } from 'react'
import { connect } from 'react-redux'

class UserRolesAccessComponent extends Component {
  render() {
    const { access, user, children } = this.props
    if (user?.roles && user.roles.some((role) => access.indexOf(role) !== -1)) {
      return children
    }
    return null
  }
}

const mapStateToProps = (store) => ({
  user: store.adminPanel.auth.user,
})

export default connect(mapStateToProps)(UserRolesAccessComponent)
