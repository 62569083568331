import axios from 'axios'
import qs from 'qs'

const path = '/admin/insurant/stories/'

export function getReportsCheckoutsForOperators(page, filter) {
  return axios.get(`${path}checkouts-extended-for-operator?page=${page}`, {
    params: qs.parse({ filter: filter }),
  })
}

export function exportReportsCheckoutsForOperators(data) {
  return axios.post(`${path}checkouts-extended-for-operator/export`, data)
}

export function exportAllReportsCheckoutsForOperators(data) {
  return axios.post(`${path}checkouts/export-all-extended-for-operator`, data)
}
