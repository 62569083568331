import baseEditForm from 'formiojs/components/_classes/component/Component.form'

export default (extend) => {
  return baseEditForm(
    [
      {
        key: 'display',
        components: [
          { key: 'myCustomSetting', ignore: true },
          { key: 'customClass', ignore: true },
          { key: 'tabindex', ignore: true },
          { key: 'placeholder', ignore: true },
          { key: 'hide', ignore: true },
          { key: 'hidden', ignore: true },
          { key: 'autofocus', ignore: true },
          { key: 'disabled', ignore: true },
          { key: 'tableView', ignore: true },
          { key: 'modalEdit', ignore: true },
          {
            key: 'htmlType',
            type: 'checkbox',
            label: 'Html',
            input: true,
          },
          {
            key: 'htmlContent',
            type: 'textarea',
            description:
              'Example: <br/><span><</span>link href=https://examlple.com/path_example/example.css rel=stylesheet/><br><span><</span>div class="exampleClass">Text for Example<span><</span>/div><br><span><</span>style>.exampleClass{background-color: red}<span><</span>/style>',
            label: 'Your Html',
            conditional: {
              show: true,
              when: 'htmlType',
              eq: 'true',
            },
          },
          {
            key: 'jsType',
            type: 'checkbox',
            label: 'JS',
            input: true,
          },
          {
            key: 'jsContent',
            type: 'textarea',
            description:
              'Example: <br/>document.getElementById("exampleID").style.color = "red"<hr/>If you need global variables, use window. object<br/>Example: <br/>window.w_antimite_domain = "0"',
            label: 'Your JS',
            conditional: {
              show: true,
              when: 'jsType',
              eq: 'true',
            },
          },
          {
            key: 'jsSourceType',
            type: 'checkbox',
            label: 'Source JS',
            input: true,
          },
          {
            key: 'jsSourceContent',
            type: 'textarea',
            description:
              'if you need few urls, divide they "\\n" or press Enter',
            label: 'Your source js',
            conditional: {
              show: true,
              when: 'jsSourceType',
              eq: 'true',
            },
          },
          // {
          //   key: 'typeHtmlJsContent',
          //   type: 'checkbox',
          //   label: 'html',
          //   inline: false,
          //   input: true,
          //   values: [
          //     {
          //       label: 'Html',
          //       value: 'html',
          //       shortcut: '',
          //     },
          //     {
          //       label: 'Js',
          //       value: 'js',
          //       shortcut: '',
          //     },
          //   ],
          // },
          // { key: 'content', type: 'textarea', label: 'Your html/js' },
        ],
      },
      {
        key: 'data',
        ignore: true,
        components: [
          {
            ignore: true,
            key: 'defaultValue',
          },
          {
            ignore: true,
            key: 'persistent',
          },
          {
            ignore: true,
            key: 'multiple',
          },
          {
            ignore: true,
            key: 'protected',
          },
          {
            ignore: true,
            key: 'dbIndex',
          },
          {
            ignore: true,
            key: 'encrypted',
          },
          {
            ignore: true,
            key: 'redrawOn',
          },
          {
            ignore: true,
            key: 'clearOnHide',
          },
          {
            ignore: true,
            key: 'customDefaultValuePanel',
          },
          {
            ignore: true,
            key: 'calculateValuePanel',
          },
          {
            ignore: true,
            key: 'calculateServer',
          },
          {
            ignore: true,
            key: 'allowCalculateOverride',
          },
        ],
      },
      {
        key: 'layout',
        ignore: true,
        components: [],
      },
      {
        key: 'validation',
        ignore: true,
        components: [
          {
            key: 'custom-validation-js',
            ignore: true,
          },
          {
            key: 'json-validation-json',
            ignore: true,
          },
          {
            key: 'unique',
            ignore: true,
          },
          {
            key: 'validateOn',
            ignore: true,
          },
        ],
      },
      {
        key: 'api',
        // ignore: true,
        components: [
          {
            key: 'tags',
            ignore: true,
          },
          {
            key: 'properties',
            ignore: true,
          },
        ],
      },
      {
        key: 'conditional',
      },
      {
        key: 'logic',
        ignore: true,
        components: [],
      },
    ],
    ...extend
  )
}
