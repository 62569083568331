import axios from 'axios'
// import projectConfig from '../../../../config'

const content = '/admin/contacts'

export const getListContact = (page = 1, ...placement) => {
  const placementMap = []
  placement.map((item) => {
    placementMap.push(`&placement=${item}`)
  })
  return axios.get(`${content}?page=${page}${placementMap.join('')}`, {})
}

export const createContact = (data) => {
  return axios.post(`${content}`, data)
}

export const editContent = (id, data) => {
  return axios.put(`${content}/${id}`, data)
}

export const disableIdContact = (id) => {
  return axios.patch(`${content}/${id}/disable`)
}

export const enableIdContact = (id) => {
  return axios.patch(`${content}/${id}/enable`)
}

export const deleteIdContact = (id) => {
  return axios.delete(`${content}/${id}`)
}

export const editIdContact = ({ id, data }) => {
  return axios.put(`${content}/${id}`, data)
}
