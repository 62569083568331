import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deleteProductCoverage,
  getProductCoverages,
  createProductCoverage,
  editProductCoverage,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  getCoveragesDataStart: 'GET_COVERAGES_DATA_START',
  getCoveragesDataSuccess: 'GET_COVERAGES_DATA_SUCCESS',
  deleteCoverage: 'DELETE_COVERAGE',
  createCoverage: 'CREATE_COVERAGE',
  editCoverage: 'EDIT_COVERAGE',
  loadingStop: 'LOADING_STOP',
  changeStatus: 'CHANGE_STATUS',
}

const initialAuthState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.getCoveragesDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getCoveragesDataStart: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const getCoveragesActionStart = (product_id, page) => ({
  type: actionTypes.getCoveragesDataStart,
  product_id,
  page,
})

export const getCoveragesActionSuccess = (data) => ({
  type: actionTypes.getCoveragesDataSuccess,
  payload: data,
})

export const deleteCoverageAction = (product_id, id) => ({
  type: actionTypes.deleteCoverage,
  product_id,
  id,
})

export const createCoverageAction = (product_id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.createCoverage,
      product_id,
      data,
      resolve,
      reject,
    })
  })
}

export const editCoverageAction = (product_id, coverage_id, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.editCoverage,
      product_id,
      coverage_id,
      data,
      resolve,
      reject,
    })
  })
}

export const loadingStopAction = () => ({
  type: actionTypes.loadingStop,
})

export function* saga() {
  yield takeLatest(actionTypes.getCoveragesDataStart, function* (e) {
    try {
      const { data } = yield getProductCoverages(e.product_id, e.page)

      yield put(getCoveragesActionSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.deleteCoverage, function* (e) {
    try {
      const {
        adminPanel: {
          productCoverages: { meta },
        },
      } = yield select()

      yield deleteProductCoverage(e.product_id, e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Покрытие успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(getCoveragesActionStart(e.product_id, page))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createCoverage, function* ({
    product_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createProductCoverage(product_id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editCoverage, function* ({
    product_id,
    coverage_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editProductCoverage(product_id, coverage_id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
