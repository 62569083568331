import React, { useEffect, useState } from 'react'
import { getGeneralDictionarySite } from '../redux/crud'
import { errorPlaces, formioDataTypes } from '../../../../constants'
import { getAllData } from '../../../helpers/api'
import { errorParser } from '../../../helpers/errorsParse'
import { Alert } from 'react-bootstrap'

export const GeneralDictionaryComponent = ({ onChange, activeDictionary }) => {
  const [generalDictionaryList, setGeneralDictionaryList] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState(null)

  useEffect(() => {
    setLoading(true)
    setErrorText(null)
    getAllData(getGeneralDictionarySite, 1)
      .then((response) => {
        const { data } = response
        setGeneralDictionaryList(data)
      })
      .catch((e) => {
        setErrorText(errorParser(e.response, errorPlaces.content))
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <h6>Общие справочники:</h6>
      {isLoading && <p>Идет загрузка справочников...</p>}
      {errorText && (
        <Alert key={'danger'} variant={'danger'}>
          {errorText}
        </Alert>
      )}
      <ul>
        {generalDictionaryList.map((dict, index) => {
          return (
            <li key={`generalDictionary-${dict.id}`}>
              <input
                type="radio"
                id={dict.id}
                onClick={(e) =>
                  onChange(formioDataTypes.generalDictionary, {
                    id: dict.id,
                    key: dict.key,
                  })
                }
                onChange={(e) => {}}
                value={dict.id}
                checked={dict.id === activeDictionary}
                name={'general_dictionary'}
              />
              <label
                onClick={(e) =>
                  onChange(formioDataTypes.generalDictionary, {
                    id: dict.id,
                    key: dict.key,
                  })
                }
                htmlFor={dict.id}
              >
                {dict.name}
              </label>
            </li>
          )
        })}
      </ul>
    </>
  )
}
