import axios from 'axios'
import qs from 'qs'

const path = '/admin/insurant/stories/checkouts'

export function getReportsCheckouts(page, filter) {
  return axios.get(`${path}?page=${page}`, {
    params: qs.parse({ filter: filter }),
  })
}

export function exportsReportsCheckouts(data) {
  return axios.post(`${path}/export`, data)
}

export function exportsReportsCheckoutsAll(data) {
  return axios.post(`${path}/export-all`, data)
}

export function deleteReportsCheckout() {
  return axios.delete(`${path}`, {
    data: {},
  })
}

export function blockReportsCheckout(id) {
  return axios.patch(`${path}/${id}/disable`)
}

export function unBlockReportsCheckout(id) {
  return axios.patch(`${path}/${id}/enable`)
}

export function createReportsCheckout(data) {
  return axios.post(`${path}`, data)
}

export function editReportsCheckout(id, data) {
  return axios.put(`${path}/${id}`, data)
}

export function showReportsCheckout(id) {
  return axios.get(`${path}/${id}`)
}
