import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { unBlockPage, blockPage, deletePage, getStaticPages } from './crud'
import lodash from 'lodash'

export const actionTypes = {
  setData: 'STATIC_PAGES_SET_staticPages_LIST',
  setDataPage: 'STATIC_PAGES_SET_staticPages_LIST_PAGE',
  getData: 'STATIC_PAGES_GET_staticPages_LIST',
  errorData: 'STATIC_PAGES_GET_staticPages_LIST_ERROR',
  deletePage: 'STATIC_PAGES_DELETE_ID',
  blockPage: 'STATIC_PAGES_BLOCK_ID',
  unBlockPage: 'STATIC_PAGES_UNBLOCK_ID',
  changeDataId: 'STATIC_PAGES_CHANGE_DATA',
  setNewDataId: 'STATIC_PAGES_SET_NEW_DATA_ID',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getData: {
      return {
        ...state,
        data: [],
        isLoading: true,
        globalLoading: true,
      }
    }
    case actionTypes.setData: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: false,
        disabled: false,
        globalLoading: false,
      }
    }

    case actionTypes.errorData: {
      return {
        ...state,
        isLoading: false,
        error: true,
        disabled: false,
        globalLoading: false,
      }
    }

    case actionTypes.deletePage: {
      return {
        ...state,
        isLoading: true,
        error: false,
        disabled: true,
      }
    }

    case actionTypes.blockPage:
      return { ...state, disabled: true, isLoading: true, error: false }
    case actionTypes.unBlockPage:
      return { ...state, disabled: true, isLoading: true, error: false }
    case actionTypes.changePage:
      return { ...state, disabled: true, isLoading: true, error: false }
    default:
      return state
  }
}
export const getDataAction = (page) => ({
  type: actionTypes.getData,
  page,
})

export const changePageAction = (page) => ({
  type: actionTypes.setDataPage,
  page,
})

export const deletePageAction = (id, history) => ({
  type: actionTypes.deletePage,
  id,
  history,
})

export const blockPageAction = (id, error = false) => ({
  type: actionTypes.blockPage,
  id,
  error,
})

export const unBlockPageActin = (id, error = false) => ({
  type: actionTypes.unBlockPage,
  id,
  error,
})

export const actions = {
  getData: () => ({
    type: actionTypes.getData,
  }),
  updateData: (data) => ({ type: actionTypes.setData, payload: data }),
  error: () => ({ type: actionTypes.errorData }),
}

export const changeDataId = (data) => ({
  type: actionTypes.changeDataId,
  data,
})

export function* saga() {
  yield takeLatest(actionTypes.changeDataId, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.staticPages.data.map((item) => {
        if (currentData.data.id === item.id) {
          item = currentData.data
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.blockPage, function* (currentData) {
    try {
      const state = yield select()

      let newData = state.adminPanel.staticPages.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))

      yield blockPage(currentData.id)

      newData = state.adminPanel.staticPages.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'draft'
          item.loader = false
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.staticPages.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.updateData({ data: newData }))
      }
      yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.unBlockPage, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.staticPages.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))

      yield unBlockPage(currentData.id)

      newData = state.adminPanel.staticPages.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.staticPages.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.updateData({ data: newData }))
      }
      yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.deletePage, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.staticPages.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))

      if (currentData.history) {
        currentData.history.push('/admin/content/article/')
      } else {
        yield deletePage(currentData.id)
      }

      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      if (state.adminPanel.staticPages.meta.pager.page === 0) {
        return yield put(changePageAction(1))
      }
      const data = state.adminPanel.staticPages.data.filter(
        (item) => item.id !== currentData.id
      )
      const meta = (state.adminPanel.staticPages.meta.pager = {
        ...state.adminPanel.staticPages.meta.pager,
        total: state.adminPanel.staticPages.meta.pager.total - 1,
        per_page: state.adminPanel.staticPages.meta.pager.per_page,
        pages: state.adminPanel.staticPages.meta.pager.pages,
      })

      if (meta.pages !== 1 && meta.pages - 1 >= meta.total / meta.per_page) {
        meta.pages = meta.pages - 1
      }
      // actions.updateData
      yield put(actions.updateData({ data: data, meta: { pager: meta } }))

      if (!data.length) {
        if (meta.total !== 0) {
          if (meta.pages !== meta.page) {
            if (meta.page > meta.pages) {
              yield put(changePageAction(meta.page - 1))
            } else {
              yield put(changePageAction(meta.page))
            }
          } else {
            yield put(changePageAction(Math.ceil(meta.total / meta.per_page)))
          }
        }
      }
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.staticPages.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
      yield put(actions.error())
    }
  })

  yield takeLatest(actionTypes.getData, function* (e) {
    try {
      console.log(e)
      yield put(changePageAction(e.page))
    } catch (e) {
      console.log(e)
      yield put(actions.error())
    }
  })

  yield takeLatest(actionTypes.setDataPage, function* (e) {
    try {
      const { data } = yield getStaticPages(e.page)

      yield put(actions.updateData(data))
    } catch (e) {
      yield put(actions.error())
    }
  })
}
