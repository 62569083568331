import React, { Component } from 'react'
import {
  getListDictionariesSite,
  getDictionaryValuesSite,
  getListRisks,
  getCoverageInfo,
  getGeneralDictionaryValuesSite,
} from '../redux/crud'
import { formioDataTypes } from '../../../../constants'
import { getAllData } from '../../../helpers/api'
import * as _ from 'lodash'
import classNames from 'classnames'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default class RadioBox extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {
      info: null,
      values: [],
      isLoading: false,
      checkedValues: [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { key } = this.props.component
    const { checkedValues } = this.state
    if (this.props._data[`${key}`]?.submit?.value) {
      if (!_.isEqual(prevProps._data[`${key}`]?.submit.value, checkedValues)) {
        this.setState({
          checkedValues: this.props._data[`${key}`]?.submit.value,
        })
      }
    }
  }

  componentDidMount() {
    this._isMounted = true
    // this.props.onChange({})

    const {
      dictionary,
      id,
      validate: { required },
    } = this.props.component
    const { template_id, checkReadyFunction } = this.props.options

    checkReadyFunction && checkReadyFunction(id, false)

    if (dictionary) {
      const {
        data: { id, key },
        formioDataType,
      } = dictionary

      if (formioDataType === formioDataTypes.dictionary) {
        if (id && key) {
          getListDictionariesSite(template_id, key)
            .then((response) => {
              const { data } = response
              if (this._isMounted) {
                this.setState({ info: data }, () => {
                  getAllData(getDictionaryValuesSite, 1, [template_id, key])
                    .then((response) => {
                      const { data } = response
                      this.setState({ values: data }, () => {
                        const { checkedValues } = this.state

                        const valuesForConditional = checkedValues
                          ? { [checkedValues]: true }
                          : []

                        checkReadyFunction &&
                          checkReadyFunction(this.props.component.id, true)
                        // onchange для того что бы отправлять true false для рисков если даже ни одно значение не выбрано отправлять всем false ДЛЯ СПРАВОЧНИКОВ НЕ НУЖНЛ!
                        !required &&
                          this.props.onChange({
                            ...valuesForConditional,
                            submit: {
                              data: dictionary.data,
                              formioDataType,
                              value: checkedValues,
                              type: 'radio',
                              valueTxt: data.filter(
                                (val) => checkedValues.indexOf(val.id) >= 0
                              ),
                              values: data.map((val) => val.id),
                            },
                          })
                      })
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                })
              }
            })
            .catch((e) => {
              console.log(e.response)
            })
        }
      } else if (formioDataType === formioDataTypes.coverage) {
        // this rule never be apper IMPORTANT
        if (id) {
          getCoverageInfo(template_id, id)
            .then((response) => {
              const { data } = response
              if (data) {
                this.setState({ info: data }, () => {
                  getAllData(getListRisks, 1, [template_id, id])
                    .then((response) => {
                      const { data } = response
                      this.setState({ values: data })
                      const { checkedValues } = this.state

                      const valuesForConditional = checkedValues
                        ? { [checkedValues]: true }
                        : []

                      this.props.onChange({
                        ...valuesForConditional,
                        submit: {
                          data: dictionary.data,
                          formioDataType,
                          value: [checkedValues],
                          type: 'radio',
                          valueTxt: data.filter(
                            (val) => checkedValues.indexOf(val.id) >= 0
                          ),
                          values: data.map((val) => val.id),
                        },
                      })
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                })
              }
            })
            .catch((e) => {})
        }
      } else if (formioDataType === formioDataTypes.generalDictionary) {
        getAllData(getGeneralDictionaryValuesSite, 1, [key])
          .then((response) => {
            const { data } = response
            this.setState({ values: data }, () => {
              checkReadyFunction &&
                checkReadyFunction(this.props.component.id, true)
            })
          })
          .catch((e) => {
            console.log(e)
          })
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    const { clearOnHide } = this.props.component
    if (clearOnHide) {
      this.props.onChange(null)
    }
  }

  _handleChange = (e, label) => {
    const { dictionary } = this.props.component
    const { data, formioDataType } = dictionary
    const { values } = this.state

    this.setState({ checkedValues: [e.target.value] }, () => {
      const { checkedValues } = this.state

      const valuesForConditional = checkedValues
        ? { [checkedValues]: true }
        : []

      const sendedLabel = // нужно отправить текст для общего справочника
        formioDataType === formioDataTypes.generalDictionary
          ? { label: label }
          : null

      this.props.onChange({
        ...valuesForConditional,
        submit: {
          data,
          formioDataType,
          value: [...checkedValues],
          type: 'radio',
          valueTxt: values.filter((val) => checkedValues.indexOf(val.id) >= 0),
          values: values.map((val) => val.id),
          ...sendedLabel,
        },
      })
    })
  }

  render() {
    const { info, values, checkedValues } = this.state

    const { component } = this.props

    return (
      <>
        <div className="formioRadioRow">
          <div className="formioRadioRow-wrapper">
            {info?.name ? <div className="title">{info.name}</div> : null}
            {info?.description && (
              <OverlayTrigger
                placement="auto"
                arrowProps={null}
                overlay={<Tooltip content={true}>{info?.description}</Tooltip>}
              >
                <i className="fa fa-question-circle text-muted"></i>
              </OverlayTrigger>
            )}
            <div className="formioRadioRow-items">
              {values.map((value) => (
                <div
                  className={classNames('formioRadioRow-item', {
                    blocked: value.is_ban,
                  })}
                  key={`dictionary-${value.id}`}
                >
                  <input
                    type="radio"
                    id={`radio-${value.id}`}
                    name={`dictionary-${component.id}`}
                    onChange={(e) =>
                      this._handleChange(e, value.value || value.name)
                    }
                    value={value.id}
                    checked={checkedValues.indexOf(value.id) !== -1}
                  />
                  <label htmlFor={`radio-${value.id}`}>
                    {value.value || value.name}{' '}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {values.find((val) => val.id === checkedValues[0])?.is_ban ? (
            component?.blocked_error ? (
              <div className={'blockedAlert'}>{component?.blocked_error}</div>
            ) : (
              <div className={'blockedAlert'}>
                Внимание, данное значение есть блокирующем и не даст списка
                результатов!
              </div>
            )
          ) : null}
        </div>
      </>
    )
  }
}
