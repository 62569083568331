import axios from 'axios'

const path = '/admin/companies'

export function getProductsCompanyCoverage(
  page = 1,
  companyId,
  productId,
  coveragesId,
  id
) {
  return axios.get(
    `${path}/${companyId}/products/${productId}/coverages?page=${page}&coverage_id=${id}`
  )
}

export function deleteProductCompanyCoverage(
  companyId,
  productId,
  coveragesId
) {
  return axios.delete(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}`
  )
}

export function unBlockProductCompanyCoverage(
  companyId,
  productId,
  coveragesId
) {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}/enable`
  )
}

export function editProductCompanyCoverage(
  companyId,
  productId,
  coveragesId,
  data
) {
  return axios.put(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}`,
    data
  )
}

export function showProductCompanyCoverageId(
  companyId,
  productId,
  coveragesId
) {
  return axios.get(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}`
  )
}

export function createProductCompanyCoverage(companyId, productId, data) {
  return axios.post(
    `${path}/${companyId}/products/${productId}/coverages`,
    data
  )
}

export function blockProductCompanyCoverage(companyId, productId, coveragesId) {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/coverages/${coveragesId}/disable`
  )
}

export const getListRisks = (page = 1, productId) => {
  return axios.get(`/admin/products/${productId}/coverages?page=${page}`)
}

// http://localhost:8001/api/v1/admin/companies/{companyId}/products/{productId}/change-prem-min
export const changePremMinCompanyProductCoverage = (
  companyId,
  productId,
  coverageId,
  data
) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/coverages/${coverageId}/change-prem-min`,
    data
  )
}
export const clearPremMinCompanyProductCoverage = (
  companyId,
  productId,
  coverageId
) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/coverages/${coverageId}/clear-prem-min`
  )
}
