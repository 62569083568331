export const setContainerStyle = ({ labelPosition }) => {
  const style = {}
  style.display = labelPosition !== 'top' ? 'flex' : 'initial'
  const words = labelPosition.split('-')
  if (words && words[0] && words[0] === 'right') {
    style.flexDirection = 'row-reverse'
  } else if (words && words[0] && words[0] === 'bottom') {
    style.flexDirection = 'column-reverse'
  } else if (words && words[0] && words[0] === 'top') {
    style.display = 'initial'
  }
  return style
}

export const setLabelStyle = ({ labelPosition }) => {
  const style = {}
  const words = labelPosition.split('-')
  if (words && words[1] && words[1] === 'left') {
    style.justifyContent = 'flex-start'
  } else if (words && words[1] && words[1] === 'right') {
    style.justifyContent = 'flex-end'
    style.textAlign = 'end'
  }
  return style
}

export const setWrapperStyle = ({ labelPosition, labelWidth, labelMargin }) => {
  const style = {}
  style.flex = `${Number(labelWidth ?? 30)} 1 0%`
  const words = labelPosition.split('-')
  if (words && words[0] && words[0] === 'right') {
    style.marginLeft = `${Number(labelMargin ?? 3)}%`
  } else if (words && words[0] && words[0] === 'left') {
    style.marginRight = `${Number(labelMargin ?? 3)}%`
  }
  return style
}

export const setContainerValueStyle = ({
  labelMargin = 30,
  labelWidth = 3,
}) => {
  const style = {}
  style.flex = `${100 - (labelMargin + (labelWidth ?? 3))} 1 0`
  return style
}
