import React, { Component } from 'react'
import {
  getListDictionariesSite,
  getDictionaryValuesSite,
  getListRisks,
  getGeneralDictionaryValuesSite,
} from '../redux/crud'
import Select from 'react-select'
import { formioDataTypes } from '../../../../constants'
import { getAllData } from '../../../helpers/api'
import * as _ from 'lodash'
export default class SelectBox extends Component {
  constructor(props) {
    super(props)
    this._isMounted = true
    this.state = {
      dictionaryInfo: null,
      values: [],
      options: [],
      isLoading: false,
      checkedValues: [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { key } = this.props.component
    const { checkedValues } = this.state
    if (this.props._data[`${key}`]?.submit?.value) {
      if (!_.isEqual(prevProps._data[`${key}`]?.submit.value, checkedValues)) {
        this.setState({
          checkedValues: this.props._data[`${key}`].submit.value,
        })
      }
    }
  }

  componentDidMount() {
    this._isMounted = true

    const { id, key, dictionary } = this.props.component
    const { template_id, checkReadyFunction } = this.props.options

    checkReadyFunction && checkReadyFunction(id, false)

    if (this.props._data[`${key}`]?.submit?.value) {
      this.setState({
        checkedValues: this.props._data[`${key}`].submit.value,
      })
    }

    if (dictionary) {
      const {
        data: { id, key },
        formioDataType,
      } = dictionary

      // загрузка данных взависимости от типа данных
      if (formioDataType === formioDataTypes.dictionary) {
        if (id && key) {
          getListDictionariesSite(template_id, key)
            .then((response) => {
              const { data } = response
              if (this._isMounted) {
                this.setState({ dictionaryInfo: data }, () => {
                  getAllData(getDictionaryValuesSite, 1, [template_id, key])
                    .then((response) => {
                      const { data } = response
                      if (data) {
                        const newOptions = data.map((item) => ({
                          value: item.id,
                          label: item.value,
                        }))
                        this.setState(
                          { values: data, options: newOptions },
                          () => {
                            checkReadyFunction &&
                              checkReadyFunction(this.props.component.id, true)
                          }
                        )
                      }
                    })
                    .catch((e) => {})
                })
              }
            })
            .catch((e) => {})
        }
      } else if (formioDataType === formioDataTypes.coverage) {
        // this rule never be apper IMPORTANT
        if (id) {
          getAllData(getListRisks, 1, [template_id, id])
            .then((response) => {
              const { data } = response
              if (data) {
                const newOptions = data.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))
                if (this._isMounted) {
                  this.setState({ options: newOptions })
                }
              }
            })
            .catch((e) => {})
        }
      } else if (formioDataType === formioDataTypes.generalDictionary) {
        getAllData(getGeneralDictionaryValuesSite, 1, [key])
          .then((response) => {
            const { data } = response

            if (data) {
              const newOptions = data.map((item) => ({
                value: item.id,
                label: item.value,
              }))
              this.setState({ values: data, options: newOptions }, () => {
                checkReadyFunction &&
                  checkReadyFunction(this.props.component.id, true)
              })
            }
          })
          .catch((e) => {})
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    const { clearOnHide } = this.props.component
    if (clearOnHide) {
      this.props.onChange(null)
    }
  }

  _handleChange = ({ value, label }) => {
    const { dictionary } = this.props.component
    const { options } = this.state

    const { data, formioDataType } = dictionary

    this.setState({ checkedValues: [value] }, () => {
      const { checkedValues } = this.state

      const valuesForConditional = checkedValues[0]
        ? { [checkedValues[0]]: true }
        : []

      const sendedLabel = // нужно отправить текст для общего справочника
        formioDataType === formioDataTypes.generalDictionary
          ? { label: label }
          : null

      this.props.onChange({
        ...valuesForConditional,
        submit: {
          data,
          formioDataType,
          value: [value],
          values: options.map((val) => val.value),
          ...sendedLabel,
        },
      })
    })
  }

  render() {
    const { options, checkedValues, values } = this.state
    const { component } = this.props

    if (options.length) {
      return (
        <>
          <Select
            defaultValue={options.find((opt) => opt.value === checkedValues[0])}
            name="select"
            options={options}
            className="basic-single"
            isSearchable={false}
            classNamePrefix="select"
            styles={customStyles}
            noOptionsMessage={() => 'Нет результатов'}
            placeholder={''}
            onChange={(e) => {
              this._handleChange(e)
            }}
          />

          {values.find((val) => val.id === checkedValues[0])?.is_ban ? (
            component?.blocked_error ? (
              <div className={'blockedAlert'}>{component?.blocked_error}</div>
            ) : (
              <div className={'blockedAlert'}>
                Внимание, данное значение есть блокирующем и не даст списка
                результатов!
              </div>
            )
          ) : null}
        </>
      )
    }
    return <></>
  }
}

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#d6d6e0',
    boxShadow: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '12px 24px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}
