import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects' // select
import { getContactData, getRequsitesData, getRecordsData } from './contactCrud'
import { getAllData } from '../../../helpers/loadedData'

export const actionTypes = {
  getContactDataStart: 'GET_CONTACT_DATA_START',
  getContactDataSuccess: 'GET_CONTACT_DATA_SUCCESS',
  getContactDataError: 'GET_CONTACT_DATA_ERROR',

  getRecordsDataStart: 'GET_RECORDS_DATA_START',
  getRecordsDataSuccess: 'GET_RECORDS_DATA_SUCCESS',
  getRecordsDataError: 'GET_RECORDS_DATA_ERROR',

  getRequisitesDataStart: 'GET_REQUISITES_DATA_START',
  getRequisitesDataSuccess: 'GET_REQUISITES_DATA_SUCCESS',
  getRequisitesDataError: 'GET_REQUISITES_DATA_ERROR',
}

const initialState = {
  contact: {
    data: [],
    error: false,
    loading: false,
  },
  records: {
    data: [],
    error: false,
    loading: false,
  },
  requisites: {
    data: [],
    error: false,
    loading: false,
  },
}

export const reducer = persistReducer(
  {
    storage,
    key: 'site_contact',
    blacklist: ['contact', 'records', 'requisites'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.getContactDataError: {
        return {
          ...state,
          contact: {
            ...state.contact,
            loading: false,
            error: true,
          },
        }
      }
      case actionTypes.getContactDataStart: {
        return {
          ...state,
          contact: {
            ...state.contact,
            loading: true,
            error: false,
          },
        }
      }
      case actionTypes.getContactDataSuccess: {
        return {
          ...state,
          contact: {
            ...state.contact,
            error: false,
            loading: false,
            data: action.payload,
          },
        }
      }

      case actionTypes.getRequisitesDataError: {
        return {
          ...state,
          requisites: {
            ...state.requisites,
            loading: false,
            error: true,
          },
        }
      }
      case actionTypes.getRequisitesDataStart: {
        return {
          ...state,
          requisites: {
            ...state.requisites,
            loading: true,
            error: false,
          },
        }
      }
      case actionTypes.getRequisitesDataSuccess: {
        return {
          ...state,
          requisites: {
            ...state.requisites,
            error: false,
            loading: false,
            data: action.payload,
          },
        }
      }

      case actionTypes.getRecordsDataError: {
        return {
          ...state,
          records: {
            ...state.records,
            loading: false,
            error: true,
          },
        }
      }
      case actionTypes.getRecordsDataStart: {
        return {
          ...state,
          records: {
            ...state.records,
            loading: true,
            error: false,
          },
        }
      }
      case actionTypes.getRecordsDataSuccess: {
        return {
          ...state,
          records: {
            ...state.records,
            error: false,
            loading: false,
            data: action.payload,
          },
        }
      }
      default:
        return state
    }
  }
)

export const getListContactsStart = () => ({
  type: actionTypes.getContactDataStart,
})

export const getListContactsSuccess = (data) => ({
  type: actionTypes.getContactDataSuccess,
  payload: data,
})

export const getListContactsError = () => ({
  type: actionTypes.getContactDataError,
})

export const getListRecordsStart = () => ({
  type: actionTypes.getRecordsDataStart,
})

export const getListRecordsSuccess = (data) => ({
  type: actionTypes.getRecordsDataSuccess,
  payload: data,
})

export const getListRecordsError = () => ({
  type: actionTypes.getRecordsDataError,
})

export const getListRequisitesStart = () => ({
  type: actionTypes.getRequisitesDataStart,
})

export const getListtRequisitesSuccess = (data) => ({
  type: actionTypes.getRequisitesDataSuccess,
  payload: data,
})

export const getListRequisitesError = () => ({
  type: actionTypes.getRequisitesDataError,
})

export function* saga() {
  yield takeLatest(actionTypes.getRequisitesDataStart, function* (e) {
    try {
      const { data } = yield getAllData(getRequsitesData)

      yield put(getListtRequisitesSuccess(data))
    } catch (e) {
      yield put(getListRequisitesError())
    }
  })

  yield takeLatest(actionTypes.getRecordsDataStart, function* (e) {
    try {
      const { data } = yield getAllData(getRecordsData)

      yield put(getListRecordsSuccess(data))
    } catch (e) {
      yield put(getListRecordsError())
    }
  })

  yield takeLatest(actionTypes.getContactDataStart, function* (e) {
    try {
      const { data } = yield getAllData(getContactData)

      yield put(getListContactsSuccess(data))
    } catch (e) {
      yield put(getListContactsError())
    }
  })
}
