import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects' // select
import { getListFaq } from './faqCrud'

export const actionTypes = {
  getListFaqStart: 'GET_FAQ_LIST_START',
  getListFaqSuccess: 'GET_FAQ_LIST_SUCCESS',
  getMoreFaqStart: 'GET_MORE_FAQ_START',
  getMoreFaqSuccess: 'GET_MORE_FAQ_SUCCESS',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
}

export const reducer = persistReducer(
  {
    storage,
    key: 'site_faq',
    blacklist: ['data', 'meta'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.getListFaqSuccess: {
        return {
          ...state,
          ...action.payload,
        }
      }
      case actionTypes.getMoreFaqSuccess: {
        return {
          ...state,
          ...action.payload,
          data: [...state.data, ...action.payload.data],
        }
      }
      default:
        return state
    }
  }
)

export const getListFaqStart = (page = 1) => ({
  type: actionTypes.getListFaqStart,
  payload: page,
})

export const getListFaqSuccess = (data) => ({
  type: actionTypes.getListFaqSuccess,
  payload: data,
})

export const getMoreFaqStart = (page = 1) => ({
  type: actionTypes.getMoreFaqStart,
  payload: page,
})

export const getMoreFaqSuccess = (data) => ({
  type: actionTypes.getMoreFaqSuccess,
  payload: data,
})

export function* saga() {
  // get faq list
  yield takeLatest(actionTypes.getListFaqStart, function* (e) {
    try {
      const { data } = yield getListFaq(e.payload)

      yield put(getListFaqSuccess(data))
    } catch (e) {
      console.log(e)
    }
  })
  yield takeLatest(actionTypes.getMoreFaqStart, function* (e) {
    try {
      const { data } = yield getListFaq(e.payload)

      yield put(getMoreFaqSuccess(data))
    } catch (e) {
      console.log(e)
    }
  })
}
