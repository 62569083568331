import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deleteProductCompany,
  getProductsCompany,
  blockProductCompany,
  unBlockProductCompany,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  getProductCompanyDataStart: 'GET_PRODUCT_COMPANY_DATA_START',
  getProductCompanyDataSuccess: 'GET_PRODUCT_COMPANY_DATA_SUCCESS',
  deleteProductCompany: 'DELETE_PRODUCT_COMPANY',
  blockProductCompany: 'BLOCK_PRODUCT_COMPANY',
  unBlockProductCompany: 'UN_BLOCK_PRODUCT_COMPANY',
  loadingStop: 'LOADING_STOP_PRODUCT_COMPANY',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getProductCompanyDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getProductCompanyDataStart: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.unBlockProductCompany: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.blockProductCompany: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const loadingStopAction = () => ({
  type: actionTypes.loadingStop,
})

export const getProductCompanyActionStart = (product_id, page) => ({
  type: actionTypes.getProductCompanyDataStart,
  product_id,
  page,
})

export const getProductCompanyActionSuccess = (data) => ({
  type: actionTypes.getProductCompanyDataSuccess,
  payload: data,
})

export const deleteProductCompanyAction = (product_id, id) => ({
  type: actionTypes.deleteProductCompany,
  product_id,
  id,
})

export const blockProductCompanyAction = (product_id, id) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.blockProductCompany,
      product_id,
      id,
      resolve,
    })
  })
}
export const unBlockProductCompanyAction = (product_id, id) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.unBlockProductCompany,
      product_id,
      id,
      resolve,
    })
  })
}

export function* saga() {
  yield takeLatest(actionTypes.getProductCompanyDataStart, function* (e) {
    try {
      const { data } = yield getProductsCompany(e.product_id, e.page)

      yield put(getProductCompanyActionSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.blockProductCompany, function* (e) {
    try {
      const {
        adminPanel: { productCompany },
      } = yield select()
      yield blockProductCompany(e.product_id, e.id)

      yield put(
        getProductCompanyActionStart(
          e.product_id,
          productCompany.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    } finally {
      yield put(loadingStopAction())
      e.resolve()
    }
  })
  yield takeLatest(actionTypes.unBlockProductCompany, function* (e) {
    try {
      const {
        adminPanel: { productCompany },
      } = yield select()
      yield unBlockProductCompany(e.product_id, e.id)

      yield put(
        getProductCompanyActionStart(
          e.product_id,
          productCompany.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    } finally {
      yield put(loadingStopAction())
      e.resolve()
    }
  })

  yield takeLatest(actionTypes.deleteProductCompany, function* (e) {
    try {
      const {
        adminPanel: {
          productCompany: { meta },
        },
      } = yield select()

      yield deleteProductCompany(e.product_id, e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Продукт успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(getProductCompanyActionStart(e.product_id, page))
    } catch (e) {
      errorParser(e.response)
    }
  })
}
