import axios from 'axios'

export const getListHeader = () => {
  return axios.get('/menu/header/items')
}

export const getListFooter = () => {
  return axios.get('/menu/footer/items')
}

export const getFooterContacts = () => {
  return axios.get('/contacts?placement=footer')
}

export const getSeoPageData = (identifier = '') => {
  return axios.get(`/seo?identifier=${identifier}`)
}

export const getAnalyticsRecordsData = () => {
  return axios.get('/general/info/records', {
    params: {
      keys: ['head-script', 'body-script'],
    },
  })
}

export const getFooterSettings = () => {
  return axios.get('/general/info/records', {
    params: {
      keys: [
        'footer-info-text',
        'copyright-text',
        'privacy-link',
        'privacy-link-title',
        'support-link',
        'support-link-title',
      ],
    },
  })
}
