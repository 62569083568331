import React from 'react'
import { getEngineReProcessProduct } from '../redux/crud'
import { isAdminSide } from '../../../helpers/isAdminSide'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import Spinner from '../../../../catalog_app/components/Spinner'
import { priceFormat } from '../../../../_metronic/_helpers/currencyFormatter'
import { parseForConfirmComponent } from './checkoutDataParser'
import * as _ from 'lodash'
import { animateScroll } from 'react-scroll'

export default class CheckoutConfirm extends React.PureComponent {
  constructor(props) {
    super(props)
    this._isMounted = true
    this.state = {
      productInfo: null,
      productLoading: false,
      confirmInfo: [],
      stepInfo: null,
    }
  }

  handleEdit = (title, panelId) => {
    const wizard_pages = document.querySelectorAll(
      '.formioWizardWrapper .pagination .page-item'
    )
    const page = [...wizard_pages].find(
      (page) => page.outerText.trim() === title
    )
    if (page) {
      page.querySelector('span').click()

      const block = document.getElementById(panelId)
      if (block) {
        const bodyRect = document.body.getBoundingClientRect()
        const elemRect = block.getBoundingClientRect()
        const offset = elemRect.top - bodyRect.top - 25
        setTimeout(() => {
          animateScroll.scrollTo(offset, {
            spy: true,
            smooth: true,
          })
        }, 300)
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.props.onChange(null)
  }

  stateUpdater = (state) => {
    // console.log(stepInfo)
    const { stepInfo } = this.state
    this.setState((prevState) => {
      const prevConfirmInfoStep =
        prevState.confirmInfo?.[stepInfo.id]?.dataGroup ?? []
      return {
        ...prevState,
        confirmInfo: {
          ...prevState.confirmInfo,
          [stepInfo.id]: {
            ...stepInfo,
            dataGroup: [...prevConfirmInfoStep, ...state],
          },
        },
      }
    })
  }

  loopComponents = async (component) => {
    component.components.map((c) => {
      if (c.type === 'components' && c.component.type !== 'panel') {
        // c.id - id панелі
        // console.log(component.label, c.id, c.component.legend)
        const dataGroup = _.filter(
          c.components.map((c2) => {
            return parseForConfirmComponent(c2)
          }),
          (item) => item
        )

        const formDataToShow = []

        dataGroup.forEach((dataItem) => {
          if (Array.isArray(dataItem)) {
            dataItem.forEach((nestedItem) => {
              formDataToShow.push(nestedItem)
            })
          } else {
            formDataToShow.push(dataItem)
          }
        })

        if (dataGroup.length) {
          this.setState((prevState) => {
            return {
              ...prevState,
              confirmInfo: [
                ...prevState.confirmInfo,
                {
                  pageTitle: component.component.title,
                  panelLabel: c.component.legend,
                  panelId: c.id,
                  dataGroup: [...formDataToShow],
                },
              ],
            }
          })
        }
      }
    })
  }

  awaitLineGenerator = async (c, i) => {
    return new Promise((resolve) => {
      return this.loopComponents(c[i], 0).then(async () => {
        i = i + 1
        if (i < c.length) {
          await this.awaitLineGenerator(c, i)
        }
        resolve()
      })
    })
  }

  async componentDidMount() {
    this._isMounted = true
    const { components } = this.props.root
    const {
      location: { pathname },
    } = window

    if (!isAdminSide(pathname)) {
      await this.awaitLineGenerator(components, 0)
    }
    // generate step by step promises line for adecvat state

    const { productLoading } = this.state

    const { story_id } = this.props.options
    if (!isAdminSide(pathname)) {
      const template_id = pathname.split('/')[2]
      const product_id = pathname.split('/')[4]
      if (!productLoading) {
        this.setState({
          productLoading: true,
        })
        getEngineReProcessProduct(template_id, product_id, { story_id })
          .then((response) => {
            if (this._isMounted) {
              const { data } = response
              if (data) {
                this.setState({
                  productInfo: data,
                })
              }
            }
          })
          .catch((e) => {})
          .finally((e) => {
            if (this._isMounted) {
              this.setState({
                productLoading: false,
              })
            }
          })
      }
    }
  }

  render() {
    const {
      location: { pathname },
    } = window

    // const { component } = this.props
    const { productInfo, productLoading, confirmInfo } = this.state

    if (productLoading) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '200px' }}
        >
          <Spinner animation="border" variant="primary">
            <span className="sr-only">Загрузка...</span>
          </Spinner>
        </div>
      )
    }
    if (productInfo && !isAdminSide(pathname)) {
      return (
        <div className="productWrapperConfirm">
          <div>
            <h1>Подтверждение информации</h1>
            <h3>Основные условия</h3>
            <div className="main__conditions">
              <div className="main__conditions-item">
                <div className="main__conditions-item-label">Компания</div>
                <div className="main__conditions-item-text">
                  {productInfo.company.name}
                </div>
              </div>
              {Boolean(productInfo.coverages.length) &&
                productInfo.coverages.map((coverage) => (
                  <React.Fragment key={`coverage-${coverage.id}`}>
                    <div className="main__conditions-item">
                      <div className="main__conditions-item-label">
                        {coverage.product_coverage_name}
                      </div>
                      <div className="main__conditions-item-text">
                        {priceFormat(coverage.insured_sum)}
                        <span> </span>
                      </div>
                    </div>
                    {coverage.risks.map((risk) => (
                      <div
                        className="main__conditions-item"
                        key={`coverage-${coverage.id}-${risk.id}`}
                      >
                        <div className="main__conditions-item-label">
                          {risk.product_coverage_risk_name}
                        </div>
                        <div className="main__conditions-item-text">
                          {risk.is_include ? 'Есть' : 'Нет'}
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              {Boolean(productInfo.dictionary_values.length) &&
                productInfo.dictionary_values.map((dictionary) => (
                  <div
                    className="main__conditions-item"
                    key={`dictionary_values-${dictionary.id}`}
                  >
                    <div className="main__conditions-item-label">
                      {dictionary.dictionary}
                    </div>
                    <div className="main__conditions-item-text">
                      {dictionary.name}
                    </div>
                  </div>
                ))}
            </div>
            <div className="checkout_confirm">
              <h3>Ваши данные</h3>
              <div className="checkout_confirmList">
                {confirmInfo &&
                  _.map(confirmInfo, (item) => {
                    return (
                      <div
                        key={item.panelId}
                        className={'checkout_confirm-item'}
                      >
                        <div className="checkout_confirm-item-heading">
                          <h6>{item.panelLabel}</h6>
                          <div
                            className="checkout_confirm-item-edit"
                            onClick={() =>
                              this.handleEdit(item.pageTitle, item.panelId)
                            }
                          ></div>
                        </div>
                        <div className={'checkout_confirm-item-group'}>
                          {item.dataGroup.map((dataItem, index) => (
                            <div
                              key={`${item.id}-${index}`}
                              className={'checkout_confirm-item-groupItem'}
                            >
                              {dataItem.label && !dataItem.hideLabelCheckout
                                ? `${dataItem.label}: `
                                : null}
                              {dataItem.value}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className="total__info">
              <h3>Стоимость</h3>
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <div className="price__info">
                    <div className="price__info-price">
                      {productInfo.promo_code.code ? (
                        <>
                          <div className="price__info-newPrice">
                            {`${priceFormat(
                              _.round(productInfo.price_with_discount, 0)
                            )}`}
                            <span> </span>
                          </div>
                          <div className="price__info-oldPrice">
                            {`${priceFormat(_.round(productInfo.price, 0))}`}
                            <span> </span>
                          </div>
                        </>
                      ) : (
                        <div className="price__info-newPrice">
                          {`${priceFormat(_.round(productInfo.price, 0))}`}
                          <span> </span>
                        </div>
                      )}
                    </div>
                    {productInfo.promo_code.code && (
                      <div className="price__info-promocode">
                        <div className="text">Скидка по Промо-коду</div>
                        <div className="sale">
                          {productInfo.promo_code.discount_in_percent}%
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="productWrapper-admin">
          <img
            src={toAbsoluteUrl('/media/pib/checkoutConfirm.png')}
            alt="productInfo"
          />
        </div>
      )
    }
  }
}
