/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy } from 'react'
import { Redirect, Switch, Route, useLocation } from 'react-router-dom'
import ErrorsPage from '../app/modules/ErrorsExamples/ErrorsPage'

const AdminApp = lazy(() => import('../app/index.js'))
const CatalogApp = lazy(() => import('../catalog_app/index.js'))

export function Routes() {
  const { pathname } = useLocation()

  const loadRoute = () => {
    if (pathname.indexOf('/admin') === 0) {
      return <Route path="/admin" component={AdminApp} />
    } else {
      return <Route path="/" component={CatalogApp} />
    }
  }
  return (
    <Switch>
      {loadRoute()}
      <Route path="/error" component={ErrorsPage} />
      <Redirect to="/error" />
    </Switch>
  )
}
