import { put, takeLatest } from 'redux-saga/effects' // select
import { getSlidesMain, getSlidesBest, getIndustriesShowAll } from './crud'
import { getAllData } from '../../../helpers/loadedData'

export const actionTypes = {
  getListSliderMainPageMainStart: 'GET_LIST_SLIDERS_MAIN_PAGE_MAIN_START',
  getListSliderMainPageMainSuccess: 'GET_LIST_SLIDERS_MAIN_PAGE_MAIN_SUCCESS',
  getListSliderMainPageMainError: 'GET_LIST_SLIDERS_MAIN_PAGE_MAIN_ERROR',

  getListSliderMainPageBestStart: 'GET_LIST_SLIDERS_MAIN_PAGE_BEST_START',
  getListSliderMainPageBestSuccess: 'GET_LIST_SLIDERS_MAIN_PAGE_BEST_SUCCESS',
  getListSliderMainPageBestError: 'GET_LIST_SLIDERS_MAIN_PAGE_BEST_ERROR',

  getListIndustriesMainPageIndividualStart:
    'GET_LIST_INDUSTRIES_MAIN_PAGE_INDIVIDUAL_START',
  getListIndustriesMainPageIndividualSuccess:
    'GET_LIST_INDUSTRIES_MAIN_PAGE_INDIVIDUAL_SUCCESS',
  getListIndustriesMainPageIndividualError:
    'GET_LIST_INDUSTRIES_MAIN_PAGE_INDIVIDUAL_ERROR',
  // Organization
  getListIndustriesMainPageOrganizationStart:
    'GET_LIST_INDUSTRIES_MAIN_PAGE_ORGANIZATION_START',
  getListIndustriesMainPageOrganizationSuccess:
    'GET_LIST_INDUSTRIES_MAIN_PAGE_ORGANIZATIONL_SUCCESS',
  getListIndustriesMainPageOrganizationError:
    'GET_LIST_INDUSTRIES_MAIN_PAGE_ORGANIZATION_ERROR',
}

const initialState = {
  mainSlider: {
    data: [],
    loader: false,
    error: false,
  },
  bestSlider: {
    data: [],
    loader: false,
    error: false,
  },
  industriesIndividual: {
    data: [],
    loader: false,
    error: false,
  },
  industriesOrganization: {
    data: [],
    loader: false,
    error: false,
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getListIndustriesMainPageIndividualSuccess: {
      return {
        ...state,
        industriesIndividual: {
          ...state.industriesIndividual,
          error: false,
          loader: false,
          data: action.payload,
        },
      }
    }
    case actionTypes.getListIndustriesMainPageIndividualStart: {
      return {
        ...state,
        industriesIndividual: {
          ...state.industriesIndividual,
          loader: true,
          error: false,
        },
      }
    }
    case actionTypes.getListIndustriesMainPageIndividualError: {
      return {
        ...state,
        industriesIndividual: {
          ...state.industriesIndividual,
          loader: false,
          error: true,
        },
      }
    }

    case actionTypes.getListIndustriesMainPageOrganizationSuccess: {
      return {
        ...state,
        industriesOrganization: {
          ...state.industriesOrganization,
          error: false,
          loader: false,
          data: action.payload,
        },
      }
    }
    case actionTypes.getListIndustriesMainPageOrganizationStart: {
      return {
        ...state,
        industriesOrganization: {
          ...state.industriesOrganization,
          loader: true,
          error: false,
        },
      }
    }
    case actionTypes.getListIndustriesMainPageOrganizationError: {
      return {
        ...state,
        industriesOrganization: {
          ...state.industriesOrganization,
          loader: false,
          error: true,
        },
      }
    }

    case actionTypes.getListSliderMainPageMainSuccess: {
      return {
        ...state,
        mainSlider: {
          ...state.mainSlider,
          error: false,
          loader: false,
          data: action.payload,
        },
      }
    }
    case actionTypes.getListSliderMainPageMainStart: {
      return {
        ...state,
        mainSlider: {
          ...state.mainSlider,
          loader: true,
          error: false,
        },
      }
    }
    case actionTypes.getListSliderMainPageMainError: {
      return {
        ...state,
        mainSlider: {
          ...state.mainSlider,
          loader: false,
          error: true,
        },
      }
    }
    case actionTypes.getListSliderMainPageBestSuccess: {
      return {
        ...state,
        bestSlider: {
          ...state.bestSlider,
          loader: false,
          data: action.payload,
        },
      }
    }
    case actionTypes.getListSliderMainPageBestStart: {
      return {
        ...state,
        bestSlider: {
          ...state.bestSlider,
          loader: true,
          error: false,
        },
      }
    }
    case actionTypes.getListSliderMainPageBestError: {
      return {
        ...state,
        bestSlider: {
          ...state.bestSlider,
          loader: false,
          error: true,
        },
      }
    }
    default:
      return state
  }
}

export const getListSliderMainPageMainError = () => ({
  type: actionTypes.getListSliderMainPageMainError,
})

export const getListSliderMainPageMainStart = (page = 1) => ({
  type: actionTypes.getListSliderMainPageMainStart,
  payload: page,
})

export const getListSliderMainPageMainSuccess = (data) => ({
  type: actionTypes.getListSliderMainPageMainSuccess,
  payload: data,
})

export const getListSliderMainPageBestError = () => ({
  type: actionTypes.getListArticlesMainError,
})

export const getListSliderMainPageBestStart = (page = 1) => ({
  type: actionTypes.getListSliderMainPageBestStart,
  payload: page,
})

export const getListSliderMainPageBestSuccess = (data) => ({
  type: actionTypes.getListSliderMainPageBestSuccess,
  payload: data,
})

export const getListIndustriesMainPageIndividualStart = (page = 1) => ({
  type: actionTypes.getListIndustriesMainPageIndividualStart,
  payload: page,
})

export const getListIndustriesMainPageIndividualSuccess = (data) => ({
  type: actionTypes.getListIndustriesMainPageIndividualSuccess,
  payload: data,
})

export const getListIndustriesMainPageIndividualError = (data) => ({
  type: actionTypes.getListIndustriesMainPageIndividualError,
})

export const getListIndustriesMainPageOrganizationStart = (page = 1) => ({
  type: actionTypes.getListIndustriesMainPageOrganizationStart,
  payload: page,
})

export const getListIndustriesMainPageOrganizationSuccess = (data) => ({
  type: actionTypes.getListIndustriesMainPageOrganizationSuccess,
  payload: data,
})

export const getListIndustriesMainPageOrganizationError = (data) => ({
  type: actionTypes.getListIndustriesMainPageOrganizationError,
})

export function* saga() {
  yield takeLatest(actionTypes.getListSliderMainPageMainStart, function* (e) {
    try {
      const { data } = yield getAllData(getSlidesMain)
      yield put(getListSliderMainPageMainSuccess(data))
    } catch (e) {
      yield put(getListSliderMainPageMainError())
    }
  })
  yield takeLatest(actionTypes.getListSliderMainPageBestStart, function* (e) {
    try {
      const { data } = yield getAllData(getSlidesBest)
      yield put(getListSliderMainPageBestSuccess(data))
    } catch (e) {
      yield put(getListSliderMainPageBestError())
    }
  })

  yield takeLatest(
    actionTypes.getListIndustriesMainPageIndividualStart,
    function* (e) {
      try {
        const { data } = yield getAllData(getIndustriesShowAll, 'individual')
        yield put(getListIndustriesMainPageIndividualSuccess(data))
      } catch (e) {
        yield put(getListIndustriesMainPageIndividualError())
      }
    }
  )
  yield takeLatest(
    actionTypes.getListIndustriesMainPageOrganizationStart,
    function* (e) {
      try {
        const { data } = yield getAllData(getIndustriesShowAll, 'organization')
        yield put(getListIndustriesMainPageOrganizationSuccess(data))
      } catch (e) {
        yield put(getListIndustriesMainPageOrganizationError())
      }
    }
  )
}
