import React, { Component } from 'react'
import Slider from '@material-ui/core/Slider'
import { getProductBoundary } from '../redux/crud'
import * as _ from 'lodash'
import { priceFormat } from '../../../../_metronic/_helpers/currencyFormatter'

export default class FormioRange extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {
      value: {
        coverage: this.props.component.coverage,
        value: null,
      },
      marksRange: null,
      valuesRange: null,
    }

    this.handleChangeInputDebounced = _.debounce(this.handleChangeInput, 2000)
  }

  _createValuesRange = ({ min, max }) => {
    const valuesRange = []
    const ser = (max - min) / 100
    let minimum = min
    return new Promise((resolve) => {
      valuesRange.push(minimum)
      for (let i = 0; i < 100; i++) {
        minimum = minimum + ser
        valuesRange.push(_.round(minimum, 0))
      }
      this.setState({ valuesRange: valuesRange }, () => resolve(true))
    })
  }

  _createMarksRange = ({ min, max }) => {
    const marksRange = []
    const labels = [25, 50, 75, 100]
    const ser = (max - min) / 4
    let minimum = min

    return new Promise((resolve) => {
      marksRange.push({ value: 0, label: priceFormat(_.round(minimum, 0)) })
      labels.map((item) => {
        minimum = minimum + ser
        marksRange.push({
          value: item,
          label: priceFormat(_.round(minimum, 0)),
        })
      })
      this.setState(
        (prevState) => {
          const { coverage } = this.props.component

          return {
            marksRange: marksRange,
            value: {
              ...prevState.value,
              coverage: coverage,
              value: prevState?.value?.value ?? min,
            },
          }
        },
        () => {
          this.props.onChange({ ...this.state.value })
          resolve(true)
        }
      )
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { key } = this.props.component

    if (this.props._data[`${key}`]) {
      if (!_.isEqual(prevProps._data[`${key}`], this.props._data[`${key}`])) {
        this.setState({
          value: this.props._data[`${key}`],
        })
      }
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    const { coverage } = this.props.component
    const { template_id } = this.props.options

    if (coverage) {
      getProductBoundary(template_id, coverage)
        .then(async (response) => {
          const { data } = response
          if (this._isMounted) {
            await this._createValuesRange(data)
            await this._createMarksRange(data)
          }
        })
        .catch()
        .finally()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    const { clearOnHide } = this.props.component
    if (clearOnHide) {
      this.props.onChange(null)
    }
  }

  handleChangeInputImmediately = (event) => {
    const { value } = event.target

    const correctValue = value.replace(/\D/g, '')
    this.setState(
      {
        value: {
          value: _.round(correctValue, 0),
        },
      },
      this.handleChangeInputDebounced
    )
  }

  handleChangeInput = () => {
    let {
      valuesRange,
      value: { value },
    } = this.state
    const { coverage } = this.props.component

    const min = valuesRange[0]
    const max = valuesRange[100]

    if (value < min) {
      value = min
    } else if (value > max) {
      value = max
    }
    _.map(valuesRange, (valueRange, index) => {
      if (index <= valuesRange.length - 1) {
        this.setState(
          (prevState) => {
            return {
              value: {
                coverage: coverage,
                value: _.round(value, 0),
              },
            }
          },
          () =>
            this.props.onChange({
              ...this.state.value,
            })
        )
      }
    })
  }

  handleChange = (event, newValue) => {
    const { coverage } = this.props.component
    if (this.state.valuesRange) {
      this.setState(
        (prevState) => {
          return {
            value: {
              coverage: coverage,
              value: _.round(this.state.valuesRange[newValue], 0),
            },
          }
        },
        () =>
          this.props.onChange({
            ...this.state.value,
          })
      )
    }
  }

  rangeLineNormalize = (value) => {
    const { valuesRange } = this.state
    let i = 0
    while (i < valuesRange.length) {
      if (
        (value >= valuesRange[i] && value < valuesRange[i + 1]) ||
        value === valuesRange[i]
      ) {
        return i
      } else {
        i++
      }
    }
  }

  render() {
    const { value } = this.state?.value ?? 0
    const { marksRange, valuesRange } = this.state

    let current
    if (value && valuesRange) {
      current = this.rangeLineNormalize(value)
    }
    return (
      <>
        <input
          value={priceFormat(value || 0)}
          onChange={(e) => {
            this.handleChangeInputImmediately(e)
          }}
          className="form-control right-icon-rub"
          type="text"
        />
        <Slider
          defaultValue={0}
          value={current || 0}
          step={1}
          marks={marksRange}
          onChange={this.handleChange}
        />
      </>
    )
  }
}
