import React from 'react'
import { isAdminSide } from '../../../helpers/isAdminSide'
import {} from '../redux/crud'
import ReCAPTCHA from 'react-google-recaptcha'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'

const initialState = {}

export default class Recaptcha extends React.PureComponent {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = initialState
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
    this.props.onChange(null)
  }

  render() {
    const {
      location: { pathname },
    } = window

    if (!isAdminSide(pathname)) {
      return (
        <div className="recaptcha-Wrapper">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_API_RECAPTCHA_KEY}
            onChange={(e) => {
              this.props.onChange('Подтверждено')
            }}
            onExpired={(e) => {
              this.props.onChange(null)
            }}
          />
        </div>
      )
    }

    return (
      <img src={toAbsoluteUrl('/media/pib/recaptcha.png')} alt="recaptcha" />
    )
  }
}
