import React from 'react'

export function ErrorPage1() {
  return (
    <div className="container">
      <h1
        className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
        style={{ fontSize: '150px' }}
      >
        404
      </h1>
    </div>
  )
}
