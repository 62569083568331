import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import {
  getStaticProductIndustries,
  blockProductIndustries,
  deleteProductIndustries,
  unBlockedProductIndustries,
} from './crud'
import lodash from 'lodash'

import { getAllData } from '../../../helpers/api'

export const actionTypes = {
  setData: 'INDUSTRY_PRODUCT__SET_LIST',
  setDataPage: 'INDUSTRY_PRODUCT__SET_LIST_PAGE',
  updateData: 'INDUSTRY_PRODUCT_UPDATE_LIST_PAGE',
  getData: 'INDUSTRY_PRODUCT__GET_LIST',
  errorData: 'INDUSTRY_PRODUCT__GET_LIST_ERROR',
  delete: 'INDUSTRY_PRODUCT__DELETE_ID',
  block: 'INDUSTRY_PRODUCT__BLOCK_ID',
  unBlock: 'INDUSTRY_PRODUCT__UNBLOCK_ID',
  changeDataId: 'INDUSTRY_PRODUCT__CHANGE_DATA',
  setAllData: 'INDUSTRY_PRODUCT__SET_LIST_ALL',
  getAllData: 'INDUSTRY_PRODUCT__GET_LIST_ALL',
  errorAllData: 'INDUSTRY_PRODUCT__ERROR_LIST_ALL',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: false,
  error: false,
  disabled: false,
  globalLoading: true,
  allData: {
    data: [],
    loading: false,
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getData: {
      return {
        ...state,
        data: [],
        isLoading: true,
        globalLoading: true,
      }
    }
    case actionTypes.setData: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: false,
        disabled: false,
        globalLoading: false,
      }
    }
    case actionTypes.errorAllData: {
      return {
        ...state,
        allData: {
          data: [],
          loading: false,
          error: true,
        },
      }
    }
    case actionTypes.getAllData: {
      return {
        ...state,
        allData: {
          data: [],
          loading: true,
          error: false,
        },
      }
    }
    case actionTypes.setAllData: {
      return {
        ...state,
        allData: {
          data: action.payload,
          error: false,
          loading: false,
        },
      }
    }
    case actionTypes.errorData: {
      return {
        ...state,
        isLoading: false,
        error: true,
        disabled: false,
        globalLoading: false,
      }
    }

    case actionTypes.delete: {
      return {
        ...state,
        isLoading: true,
        error: false,
        disabled: true,
      }
    }

    case actionTypes.updateData: {
      return {
        ...state,
        ...action.payload,
        disabled: false,
        isLoading: false,
        error: false,
      }
    }
    case actionTypes.changePage:
      return { ...state, disabled: true, isLoading: true, error: false }
    default:
      return state
  }
}

export const getData = (id = 1) => ({
  type: actionTypes.getData,
  id,
})

export const changePage = (page) => ({
  type: actionTypes.setDataPage,
  data: page,
})

export const deleteProductIndustriesAction = (id, history) => ({
  type: actionTypes.delete,
  id,
  history,
})

export const block = (id, error = false) => ({
  type: actionTypes.block,
  id,
  error,
})

export const unBlock = (id, error = false) => ({
  type: actionTypes.unBlock,
  id,
  error,
})

export const changeDataId = (data) => ({
  type: actionTypes.changeDataId,
  data,
})

const errorAllData = () => ({
  type: actionTypes.errorAllData,
})

const updateData = (data) => ({
  type: actionTypes.updateData,
  payload: data,
})

const setAllData = (data) => ({
  type: actionTypes.setAllData,
  payload: data,
})

export const getAllDataProductIndustries = () => ({
  type: actionTypes.getAllData,
})

const errorData = () => ({
  type: actionTypes.errorData,
})

export function* saga() {
  yield takeLatest(actionTypes.getAllData, function* () {
    try {
      const { data } = yield getAllData(getStaticProductIndustries)

      yield put(setAllData(data))
    } catch (e) {
      yield put(errorAllData())
    }
  })

  yield takeLatest(actionTypes.getData, function* (currentData) {
    try {
      const { data } = yield getStaticProductIndustries(currentData.id)

      yield put(updateData(data))
    } catch (e) {
      yield put(errorData())
    }
  })

  yield takeEvery(actionTypes.block, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.industryProduct.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(updateData({ data: newData }))

      yield blockProductIndustries(currentData.id)
      newData = state.adminPanel.industryProduct.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'disable'
          item.loader = false
        }
        return item
      })

      yield put(updateData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industryProduct.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = false
        }
        return item
      })

      yield put(updateData({ data: newData }))
    }
  })

  yield takeEvery(actionTypes.unBlock, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.industryProduct.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })
      yield put(updateData({ data: newData }))
      yield unBlockedProductIndustries(currentData.id)
      newData = state.adminPanel.industryProduct.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })
      yield put(updateData({ data: newData }))
    } catch (e) {
      console.log(e)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industryProduct.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = false
        }
        return item
      })
      yield put(updateData({ data: newData }))
    }
  })

  yield takeEvery(actionTypes.delete, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.industryProduct.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })
      yield put(updateData({ data: newData }))
      if (currentData.history) {
        currentData.history.push('/admin/services/')
      } else {
        yield deleteProductIndustries(currentData.id)
      }
      const data = state.adminPanel.industryProduct.data.filter(
        (item) => item.id !== currentData.id
      )

      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      if (state.adminPanel.industryProduct.meta.pager.page === 0) {
        return yield put(getData(1))
      }

      const meta = (state.adminPanel.industryProduct.meta.pager = {
        ...state.adminPanel.industryProduct.meta.pager,
        total: state.adminPanel.industryProduct.meta.pager.total - 1,
        per_page: state.adminPanel.industryProduct.meta.pager.per_page,
        pages: state.adminPanel.industryProduct.meta.pager.pages,
      })

      if (meta.pages !== 1 && meta.pages - 1 >= meta.total / meta.per_page) {
        meta.pages = meta.pages - 1
      }

      yield put(updateData({ data: data, meta: { pager: meta } }))

      if (!data.length) {
        if (meta.total !== 0) {
          if (meta.pages !== meta.page) {
            if (meta.page > meta.pages) {
              yield put(getData(meta.page - 1))
            } else {
              yield put(getData(meta.page))
            }
          } else {
            yield put(getData(Math.ceil(meta.total / meta.per_page)))
          }
        }
      }
    } catch (e) {
      console.log(e)
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.industryProduct.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })
      yield put(updateData({ data: newData }))
    }
  })

  yield takeLatest(actionTypes.changeDataId, function* (currentData) {
    const state = yield select()
    const newData = state.adminPanel.industryProduct.data.map((item) => {
      if (currentData.data.id === item.id) {
        item = currentData.data
      }
      return item
    })

    yield put(updateData({ data: newData }))
  })
}
