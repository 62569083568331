import axios from 'axios'

const path = '/admin/widgets'

export function getWidgets(page) {
  return axios.get(`${path}?page=${page}`)
}

export function deleteWidget() {
  return axios.delete(`${path}`, {
    data: {},
  })
}

export function blockWidget(id) {
  return axios.patch(`${path}/${id}/disable`)
}

export function unBlockWidget(id) {
  return axios.patch(`${path}/${id}/enable`)
}

export function createWidget(data) {
  return axios.post(`${path}`, data)
}

export function editWidget(id, data) {
  return axios.put(`${path}/${id}`, data)
}

export function showWidget(id) {
  return axios.get(`${path}/${id}`)
}
export function changeHostInfoWidget(id, data) {
  return axios.patch(`${path}/${id}/change-host-info`, data)
}
