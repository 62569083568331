import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/pages/Auth/_redux/authRedux'
import * as usersSlice from '../app/pages/Users/_redux/usersRedux'
import * as header from '../app/pages/ControlContent/Header/_redux/ccRedux'
import * as footer from '../app/pages/ControlContent/Footer/_redux/ccRedux'
import * as contact from '../app/pages/ControlContent/Contacts/_redux/ccRedux'
import * as siteLayout from '../catalog_app/components/_redux/layoutRedux'
import * as siteFaq from '../catalog_app/pages/faq/_redux/faqRedux'
import * as siteContact from '../catalog_app/pages/contact/_redux/contactRedux'
import * as sitePartners from '../catalog_app/pages/partners/_redux/partnerRedux'
import * as siteStaticPage from '../catalog_app/pages/static/_redux/staticRedux'
import * as faq from '../app/pages/FAQ/_redux/redux'
import * as seoAdmin from '../app/pages/SEO/_redux/redux'
import * as staticPagesAdmin from '../app/pages/StaticPages/redux/redux'
import * as headingAdmin from '../app/pages/Heading/redux/redux'
import * as partnersAdmin from '../app/pages/Partners/redux/redux'
import * as aboutAdmin from '../app/pages/aboutUs/_redux/redux'
import * as contactsAdmin from '../app/pages/Contacts/_redux/redux'
import * as articlesAdmin from '../app/pages/Articles/_redux/articlesRedux'
import * as mainAdmin from '../app/pages/MainPages/redux/redux'
import * as siteBlog from '../catalog_app/pages/blog/_redux/blogRedux'
import * as siteHeading from '../catalog_app/pages/heading/_redux/headingRedux'
import * as productTemplates from '../app/pages/ProductTemplates/redux/redux'
import * as productCoverages from '../app/pages/Coverages/_redux/redux'
import * as companyAdmin from '../app/pages/Copmany/redux/redux'
import * as mainPage from '../catalog_app/pages/home/redux/redux'
import * as industryAdmin from '../app/pages/Services/_redux/redux'
import * as industryProductAdmin from '../app/pages/ProductIndustries/redux/redux'
import * as promocodesAdmin from '../app/pages/Promocodes/redux/redux'
import * as productRisks from '../app/pages/Risks/_redux/redux'
import * as technicalCoefficient from '../app/pages/TechnicalCoefficients/_redux/redux'
import * as directoriesBlocking from '../app/pages/Directories/DirectoriesBlocking/redux/redux'
import * as directoriesRated from '../app/pages/Directories/DirectoriesRated/redux/redux'
import * as directoriesInformation from '../app/pages/Directories/DirectoriesInformation/redux/redux'
import * as ProductCompanyAdmin from '../app/pages/ProductCompany/redux/redux'
import * as industriesPage from '../catalog_app/pages/industries/_redux/industryRedux'
import * as productCompanyCoveragesAdmin from '../app/pages/ProductCompanyCoverages/redux/redux'
import * as productCompanyCoveragesRiskAdmin from '../app/pages/ProductCompanyCoveragesRisk/redux/redux'
import * as productCompanyCoveragesRated from '../app/pages/ProductCompanyDictionary/redux/redux'
import * as GeneralDictionary from '../app/pages/GeneralDictionary/redux/redux'
import * as productCompanyQuery from '../app/pages/ProductTemplatePriorityCompany/redux/redux'
import * as productTemplate from '../catalog_app/pages/productTemplate/_redux/redux.js'
import * as RetentionAdmin from '../app/pages/Retention/redux/redux'
import * as feedbackAdmin from '../app/pages/Feedback/redux/redux'
import * as checkout from '../catalog_app/pages/checkout/redux/redux'
import * as widgetAdmin from '../app/pages/Widget/redux/redux'
import * as reportsCheckouts from '../app/pages/ReportsCheckouts/redux/redux'
import * as reportsQueries from '../app/pages/ReportsQueries/redux/redux'
import * as reportsCheckoutsForOperators from '../app/pages/ReportsCheckoutForOperators/redux/redux'
export const rootReducer = combineReducers({
  site: combineReducers({
    layout: siteLayout.reducer,
    faq: siteFaq.reducer,
    contact: siteContact.reducer,
    partners: sitePartners.reducer,
    staticPage: siteStaticPage.reducer,
    blog: siteBlog.reducer,
    heading: siteHeading.reducer,
    main: mainPage.reducer,
    industries: industriesPage.reducer,
    productTemplate: productTemplate.reducer,
    checkout: checkout.reducer,
  }),
  adminPanel: combineReducers({
    auth: auth.reducer,
    users: usersSlice.reducer,
    footer: footer.reducer,
    header: header.reducer,
    contact: contact.reducer,
    faq: faq.reducer,
    seo: seoAdmin.reducer,
    staticPages: staticPagesAdmin.reducer,
    heading: headingAdmin.reducer,
    partners: partnersAdmin.reducer,
    about: aboutAdmin.reducer,
    contacts: contactsAdmin.reducer,
    articles: articlesAdmin.reducer,
    main: mainAdmin.reducer,
    productTemplates: productTemplates.reducer,
    productCoverages: productCoverages.reducer,
    company: companyAdmin.reducer,
    industry: industryAdmin.reducer,
    industryProduct: industryProductAdmin.reducer,
    promocodes: promocodesAdmin.reducer,
    productRisks: productRisks.reducer,
    productTechnicalCoefficients: technicalCoefficient.reducer,
    directoriesBlocking: directoriesBlocking.reducer,
    directoriesRated: directoriesRated.reducer,
    directoriesInformation: directoriesInformation.reducer,
    productCompany: ProductCompanyAdmin.reducer,
    productCompanyCoverages: productCompanyCoveragesAdmin.reducer,
    productCompanyCoveragesRisk: productCompanyCoveragesRiskAdmin.reducer,
    productCompanyRated: productCompanyCoveragesRated.reducer,
    generalDictionary: GeneralDictionary.reducer,
    retention: RetentionAdmin.reducer,
    productCompanyQuery: productCompanyQuery.reducer,
    feedback: feedbackAdmin.reducer,
    widgets: widgetAdmin.reducer,
    reportsCheckouts: reportsCheckouts.reducer,
    reportsQueries: reportsQueries.reducer,
    reportsCheckoutsForOperators: reportsCheckoutsForOperators.reducer,
  }),
})

export function* rootSaga() {
  yield all([
    auth.saga(),
    usersSlice.saga(),
    header.saga(),
    footer.saga(),
    contact.saga(),
    siteLayout.saga(),
    siteFaq.saga(),
    siteContact.saga(),
    sitePartners.saga(),
    faq.saga(),
    seoAdmin.saga(),
    staticPagesAdmin.saga(),
    partnersAdmin.saga(),
    aboutAdmin.saga(),
    contactsAdmin.saga(),
    articlesAdmin.saga(),
    mainAdmin.saga(),
    siteStaticPage.saga(),
    headingAdmin.saga(),
    siteBlog.saga(),
    siteHeading.saga(),
    productTemplates.saga(),
    productCoverages.saga(),
    companyAdmin.saga(),
    mainPage.saga(),
    industryAdmin.saga(),
    industryProductAdmin.saga(),
    promocodesAdmin.saga(),
    productRisks.saga(),
    technicalCoefficient.saga(),
    directoriesBlocking.saga(),
    directoriesRated.saga(),
    directoriesInformation.saga(),
    industriesPage.saga(),
    ProductCompanyAdmin.saga(),
    productCompanyCoveragesAdmin.saga(),
    productCompanyCoveragesRiskAdmin.saga(),
    productCompanyCoveragesRated.saga(),
    GeneralDictionary.saga(),
    RetentionAdmin.saga(),
    productCompanyQuery.saga(),
    feedbackAdmin.saga(),
    widgetAdmin.saga(),
    reportsCheckouts.saga(),
    reportsQueries.saga(),
    reportsCheckoutsForOperators.saga(),
  ])
}
