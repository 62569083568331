import axios from 'axios'

const productTemplateUrl = '/admin/products'

export function getProductsTemplate(page = 1) {
  return axios.get(`${productTemplateUrl}?page=${page}`)
}

export function getProductTemplateList() {
  return axios.get(`${productTemplateUrl}-list`)
}

export function deleteProductsTemplate(id) {
  return axios.delete(`${productTemplateUrl}/${id}`)
}

export function blockProductsTemplate(id) {
  return axios.patch(`${productTemplateUrl}/${id}/unpublish`)
}

export function editProductsTemplate(id, data) {
  return axios.put(`${productTemplateUrl}/${id}`, data)
}

export function editProductsTemplateIndustry(id, data) {
  return axios.patch(`${productTemplateUrl}/${id}/change-industry`, data)
}

export function unBlockProductsTemplate(id) {
  return axios.patch(`${productTemplateUrl}/${id}/publish`)
}

export function showProductsTemplate(id) {
  return axios.get(`${productTemplateUrl}/${id}`)
}

export function createProductsTemplate(data) {
  return axios.post(`${productTemplateUrl}`, data)
}

export function getProductIndustries(page = 1) {
  return axios.get(`/admin/industries?page=${page}`)
}

export function getProductQuery(id) {
  return axios.get(`/admin/products/${id}/queries`)
}

export function createProductQuery(id, data) {
  return axios.post(`/admin/products/${id}/queries`, data)
}

export function editProductQuery(id, builderId, data) {
  return axios.put(`/admin/products/${id}/queries/${builderId}`, data)
}

export function deleteProductQuery(id, builderId) {
  return axios.delete(`/admin/products/${id}/queries/${builderId}`)
}

export function createProductPriority(data) {
  return axios.post(`/admin/query/products`, data)
}
export function getProductPriority(id, data) {
  return axios.get(`/admin/query/products/${id}`, data)
}
export function editProductPriority(id, data) {
  return axios.put(`/admin/query/products/${id}`, data)
}

export function getProductCheckout(id) {
  return axios.get(`/admin/products/${id}/checkouts`)
}

export function createProductCheckout(id, data) {
  return axios.post(`/admin/products/${id}/checkouts`, data)
}

export function editProductCheckout(id, builderId, data) {
  return axios.put(`/admin/products/${id}/checkouts/${builderId}`, data)
}

export function deleteProductCheckout(id, builderId) {
  return axios.delete(`/admin/products/${id}/checkouts/${builderId}`)
}

export function productEnableInsurantPhoneRequired(id) {
  return axios.patch(
    `${productTemplateUrl}/${id}/insurant-phone-required/enable`
  )
}

export function productDisableInsurantPhoneRequired(id) {
  return axios.patch(
    `${productTemplateUrl}/${id}/insurant-phone-required/disable`
  )
}
