import axios from 'axios'

export const getProductQuery = (template_id) => {
  return axios.get(`/products/${template_id}/queries`)
}

export const getProductTemplateInfo = (template_id) => {
  return axios.get(`/products/${template_id}`)
}

export const getProductCheckout = (template_id) => {
  return axios.get(`/products/${template_id}/checkouts`)
}

export const productQueryIssue = (id, data) => {
  return axios.post(`/query/products/${id}/issue`, data)
}

export const changeStory = (id, data) => {
  return axios.patch(`/insurant/stories/${id}/change-checkout`, data)
}

export const attachPhoneStory = (id, data) => {
  return axios.patch(`/insurant/stories/${id}/attach-phone`, data)
}

export const showStory = (story_id) => {
  return axios.get(`/insurant/stories/${story_id}`)
}

export const productQueryProcess = (product_id, data) => {
  return axios.post(`/engine/products/${product_id}/process`, data)
}
