import lodash from 'lodash'
import { errorPlaces } from '../../constants'
import * as _ from 'lodash'

export function errorParse(error) {
  return error?.data?.message ?? 'Извините произошла ошибка, попробуйте еще раз'
}

// get all response object for parsing
export function errorParser({ status, data }, placement = errorPlaces.toast) {
  const addToast = lodash.get(window, '__react_toast_provider.current.add')
  switch (true) {
    case status > 399 && status < 410:
      if (placement === errorPlaces.content) {
        return data.message
      } else if (placement === errorPlaces.toast) {
        if (addToast) {
          addToast(data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      }
      break
    case status === 422:
      if (placement === errorPlaces.content) {
        return _.map(data.errors, (item, key) => ({
          [key]: item,
        }))
      } else if (placement === errorPlaces.toast) {
        if (addToast) {
          _.map(data.errors, (item, key) => {
            addToast(`${key}: ${item}`, {
              appearance: 'error',
              autoDismiss: true,
            })
          })
        }
      }
      break
    case status >= 500:
      if (placement === errorPlaces.content) {
        return 'Ошибка загрузки данных, попробуйте перезагрузить страницу'
      } else if (placement === errorPlaces.toast) {
        if (addToast) {
          addToast(
            'Ошибка загрузки данных, попробуйте перезагрузить страницу',
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        }
      }
      break
    default:
      return 'Ошибка загрузки данных'
  }
}
