import React, { useEffect, useState } from 'react'
import { getListDictionaries } from '../redux/crud'
import { errorPlaces, formioDataTypes } from '../../../../constants'
import { getAllData } from '../../../helpers/api'
import { errorParser } from '../../../helpers/errorsParse'
import { Alert } from 'react-bootstrap'
import classNames from 'classnames'

export const TreeItem = ({
  dictionary,
  onChange,
  activeDictionary,
  template_id,
}) => {
  const [visible, setVisible] = useState(false)
  const [dictionaryList, setDictionaryList] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState(null)

  const { type, name } = dictionary

  useEffect(() => {
    setLoading(true)
    setErrorText(null)
    getAllData(getListDictionaries, 1, [template_id, type, 'enabled'])
      .then((response) => {
        const { data } = response
        setDictionaryList(data)
      })
      .catch((e) => {
        setErrorText(errorParser(e.response, errorPlaces.content))
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <div className="tree-item">
      <div
        className="tree-heading"
        onClick={() => {
          setVisible(!visible)
        }}
      >
        <span className={classNames({ active: !visible })}>
          {visible ? `-` : `+`}
        </span>
        {name}
      </div>
      {visible && (
        <div className="tree-wrapper">
          {isLoading && <p>Идет загрузка справочников...</p>}
          {errorText && (
            <Alert key={'danger'} variant={'danger'}>
              {errorText}
            </Alert>
          )}
          <ul>
            {dictionaryList.map((dict, index) => {
              return (
                <li key={`dictionary-${dict.id}`}>
                  <input
                    type="radio"
                    id={dict.id}
                    onClick={(e) =>
                      onChange(formioDataTypes.dictionary, {
                        id: dict.id,
                        key: dict.key,
                        type: type,
                      })
                    }
                    onChange={(e) => {}}
                    value={dict.id}
                    checked={dict.id === activeDictionary}
                    name={'dictionary'}
                  />
                  <label
                    onClick={(e) =>
                      onChange(formioDataTypes.dictionary, {
                        id: dict.id,
                        key: dict.key,
                        type: type,
                      })
                    }
                    htmlFor={dict.id}
                  >
                    {dict.name}
                  </label>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}
