import React, { Component } from 'react'
import { checkPromocode } from '../redux/crud'
import { isAdminSide } from '../../../helpers/isAdminSide'
import Button from '../../../../catalog_app/components/Button'
import { errorParser } from '../../../helpers/errorsParse'
import { errorPlaces } from '../../../../constants'
export default class Promocode extends Component {
  constructor(props) {
    super(props)
    this._isMounted = true
    this.state = {
      promocode: '',
      promocodeInfo: null,
      promocodeLoading: false,
      promocodeError: null,
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  cancelPromocode = () => {
    this.props.onChange(null)
    this.setState({
      promocode: '',
      promocodeError: null,
      promocodeInfo: null,
    })
  }

  changePromocode = (e) => {
    this.setState({
      promocode: e.target.value,
    })
  }

  _handleCheckPromocode = () => {
    const { promocode, promocodeLoading } = this.state
    if (promocode && !promocodeLoading) {
      this.setState({
        promocodeLoading: true,
        promocodeError: null,
        promocodeInfo: null,
      })
      checkPromocode(promocode)
        .then((response) => {
          const { data } = response
          if (data) {
            this.props.onChange({ code: data.code })
            this.setState({
              promocodeInfo: data,
            })
          }
        })
        .catch((e) => {
          const error = errorParser(e.response, errorPlaces.content)
          this.setState({
            promocodeError: error,
          })
        })
        .finally((e) => {
          this.setState({
            promocodeLoading: false,
          })
        })
    }
  }

  render() {
    const {
      location: { pathname },
    } = window

    const { component } = this.props
    const {
      promocode,
      promocodeLoading,
      promocodeError,
      promocodeInfo,
    } = this.state
    return (
      <div className="promocodeWrapper">
        <div className="promocodeWrapper-flex">
          <div className={`wrapp_input ${promocodeInfo ? 'active' : ''}`}>
            <input
              type="text"
              placeholder={component.placeholder}
              className="form-control"
              value={promocode}
              onChange={this.changePromocode}
            />
          </div>
          {promocodeInfo ? (
            <>
              <div className={'promocodeInfo'}>
                ваша скидки <b>{promocodeInfo.discount} %</b>
              </div>
              <button
                onClick={this.cancelPromocode}
                className={'promocodeCancel'}
              >
                Отменить
              </button>
            </>
          ) : !isAdminSide(pathname) ? (
            <Button
              disabled={promocodeLoading || !promocode}
              onClick={() => {
                this._handleCheckPromocode()
              }}
            >
              {promocodeLoading ? 'Загрузка...' : 'Применить'}
            </Button>
          ) : (
            <button className={'btn btn-primary'}>Применить</button>
          )}
        </div>
        {Boolean(promocodeError) && (
          <div className={'error'}>{promocodeError}</div>
        )}
      </div>
    )
  }
}
