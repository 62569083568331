import React, { Component } from 'react'
import Spinner from '../../../../../catalog_app/components/Spinner'
import AsyncSelect from 'react-select/async'
import { customStyles } from '../../../formioSelect/select'
import { getDadataAddress } from '../../redux/crud'
import {
  setContainerStyle,
  setLabelStyle,
  setWrapperStyle,
  setContainerValueStyle,
} from '../../../SetStylesFormio/styles'
import { formioDataTypes } from '../../../../../constants'

class DadataAddress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productLoading: false,
      disableForm: false,
      checkedValue: false,
      addressResidence: null,
      address: null,
      searchAddress: null,
      sleep: false,

      valueAddress: null,
      valueAddressResidence: null,
    }
    this._isMounted = true
  }

  selectRefAddress = null
  selectRefAddressResidence = null

  componentDidMount() {
    this._isMounted = true

    setTimeout(() => {
      /* есть особеность в самом формио, незнаю почему но нужна задержка, может помочь даже задержка в 1 милисекунд */
      const {
        component: { key },
        _data,
      } = this.props
      this.setState((prevState) => {
        return {
          ...prevState,
          ..._data[`${key}`]?.submit,
        }
      })
    }, 100)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _handleChangeChecked = (e) => {
    this.setState(
      {
        checkedValue: e,
      },
      () => {
        const { address, addressResidence, checkedValue } = this.state
        if (address) {
          this.props.onChange({
            submit: {
              address: address,
              addressResidence: checkedValue ? address : addressResidence,
              checkedValue: checkedValue,
              formioDataType: formioDataTypes.dadataAddress,
            },
          })
        }
      }
    )
  }

  _searchAddress = async (e) => {
    clearTimeout(this.state.searchAddress)
    const promise = new Promise((resolve) => {
      const timeOutAddress = setTimeout(() => {
        getDadataAddress(e)
          .then(({ data }) => {
            resolve(data.data)
            return data.data
          })
          .catch((er) => {
            resolve([])
          })
      }, 1000)
      this.setState({
        searchAddress: timeOutAddress,
      })
    })
    return promise
  }

  onChangeValue = (type, e) => {
    this.setState(
      {
        [type]: e,
      },
      () => {
        const { address, addressResidence, checkedValue } = this.state

        this.props.onChange({
          submit: {
            address: address,
            addressResidence: checkedValue ? address : addressResidence,
            checkedValue: checkedValue,
            formioDataType: formioDataTypes.dadataAddress,
            displayAddressIndex: this.props.component.displayAddressIndex,
            displayResidenceAddressIndex: this.props.component
              .displayResidenceAddressIndex,
          },
        })
      }
    )
  }

  render() {
    const { component } = this.props
    const { checkedValue, addressResidence, address } = this.state

    if (this.state.productLoading) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '200px' }}
        >
          <Spinner animation="border" variant="primary">
            <span className="sr-only">Загрузка...</span>
          </Spinner>
        </div>
      )
    }

    return (
      <div className="dadataWrapper">
        <div
          className="container-fields-wrapper"
          style={setContainerStyle(component)}
        >
          <div className="field-wrapper" style={setWrapperStyle(component)}>
            <label
              style={setLabelStyle(component)}
              htmlFor={'addressResidence'}
            >
              {component?.label || ''}
            </label>
          </div>
          <div style={setContainerValueStyle(component)}>
            <AsyncSelect
              styles={customStyles}
              className={'dadataInputContainer'}
              name="address"
              defaultOptions={[]}
              loadingMessage={() => 'Идет загрузка...'}
              placeholder={
                component?.placeholder ? component.placeholder : 'Укажите адрес'
              }
              noOptionsMessage={(e) => 'Список пуст'}
              components={{ DropdownIndicator: null }}
              isSearchable={true}
              inputValue={this.state.valueAddress}
              ref={(e) => (this.selectRefAddress = e)}
              onFocus={(e) => {
                this.setState({
                  valueAddress: this.state.address?.value || null,
                })
              }}
              value={address}
              onChange={(e) => {
                this.onChangeValue('address', e)
                this.selectRefAddress.select &&
                  this.selectRefAddress.select.select.onInputFocus()
                const value = component?.displayAddressIndex
                  ? `${e?.postal_code ? e?.postal_code + ', ' : ''}${e?.value}`
                  : e?.value
                this.setState({
                  valueAddress: value,
                })
              }}
              getOptionLabel={(option) => {
                if (component?.displayAddressIndex) {
                  const postalCode = option['postal_code']
                    ? option['postal_code'] + ', '
                    : ''
                  return postalCode + option['value']
                }
                return option['value']
              }}
              getOptionValue={(item) => item}
              onInputChange={(e) => {
                this.setState({
                  valueAddress: e,
                })
              }}
              loadOptions={async (e) => {
                return await this._searchAddress(e)
              }}
            />
            {/* {false ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">error</div>
              </div>
            ) : null} */}
          </div>
        </div>
        {!component?.hideResidence && (
          <div
            className="container-fields-wrapper"
            style={setContainerStyle(component)}
          >
            <div className="field-wrapper" style={setWrapperStyle(component)}>
              <label
                style={setLabelStyle(component)}
                htmlFor={'addressResidence'}
              >
                Адрес фактического проживания
              </label>
            </div>
            <div style={setContainerValueStyle(component)}>
              <div className="formioCheckboxRow checkboxWithInput">
                <input
                  id={`checkbox-address`}
                  type="checkbox"
                  checked={checkedValue}
                  onChange={(e) => {
                    this._handleChangeChecked(e.target.checked)
                  }}
                />
                <label
                  style={setLabelStyle(component)}
                  htmlFor={`checkbox-address`}
                >
                  Совпадает с адресом регистрации
                </label>
              </div>
              <AsyncSelect
                styles={customStyles}
                className={'dadataInputContainer'}
                name="addressResidence"
                defaultOptions={[]}
                placeholder="Укажите адрес фактического проживания"
                value={checkedValue ? address : addressResidence}
                loadingMessage={() => 'Идет загрузка...'}
                noOptionsMessage={(e) =>
                  e.length ? 'Список пуст' : 'Начните поиск'
                }
                inputValue={this.state.valueAddressResidence}
                components={{ DropdownIndicator: null }}
                isSearchable={true}
                isDisabled={checkedValue}
                onChange={(e) => {
                  this.selectRefAddressResidence.select &&
                    this.selectRefAddressResidence.select.select.onInputFocus()
                  this.onChangeValue('addressResidence', e)
                  const value = component?.displayResidenceAddressIndex
                    ? `${e?.postal_code ? e?.postal_code + ', ' : ''}${
                        e?.value
                      }`
                    : e?.value
                  this.setState({
                    valueAddressResidence: value,
                  })
                }}
                getOptionLabel={(option) => {
                  if (component?.displayResidenceAddressIndex) {
                    const postalCode = option['postal_code']
                      ? option['postal_code'] + ', '
                      : ''
                    return postalCode + option['value']
                  }
                  return option['value']
                }}
                ref={(e) => (this.selectRefAddressResidence = e)}
                onFocus={(e) => {
                  this.setState({
                    valueAddressResidence:
                      this.state.addressResidence?.value || null,
                  })
                }}
                getOptionValue={(item) => item}
                onInputChange={(e) => {
                  this.setState({
                    valueAddressResidence: e,
                  })
                }}
                loadOptions={async (e) => {
                  return await this._searchAddress(e)
                }}
              />
              {/* {false ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">error</div>
              </div>
            ) : null} */}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default DadataAddress
