import * as _ from 'lodash'
import { formioDataTypes } from '../../../../constants'
import moment from 'moment'

export const parseForConfirmComponent = (component) => {
  if (component._data[component.component.key]) {
    switch (component.type) {
      case 'dadata_address':
        if (component?.component?.hideResidence) {
          return {
            label: component?.component?.label,
            value: component?.component?.displayAddressIndex
              ? (component._data[component.component.key].submit?.address
                  ?.postal_code || '') +
                (component._data[component.component.key].submit?.address?.value
                  ? `, ${
                      component._data[component.component.key].submit?.address
                        ?.value
                    }`
                  : '')
              : component._data[component.component.key].submit?.address
                  ?.value ?? '',
            hideLabelCheckout: component.component?.hideLabelCheckout || false,
          }
        } else {
          return [
            {
              label: component?.component?.label,
              value: component?.component?.displayAddressIndex
                ? (component._data[component.component.key].submit?.address
                    ?.postal_code || '') +
                  (component._data[component.component.key].submit?.address
                    ?.value
                    ? `, ${
                        component._data[component.component.key].submit?.address
                          ?.value
                      }`
                    : '')
                : component._data[component.component.key].submit?.address
                    ?.value ?? '',
              hideLabelCheckout:
                component.component?.hideLabelCheckout || false,
            },
            {
              label: 'Адрес фактического проживания',
              value: component?.component?.displayAddressIndex
                ? (component._data[component.component.key].submit
                    ?.addressResidence?.postal_code || '') +
                  (component._data[component.component.key].submit
                    ?.addressResidence?.value
                    ? `, ${
                        component._data[component.component.key].submit
                          ?.addressResidence?.value
                      }`
                    : '')
                : component._data[component.component.key].submit
                    ?.addressResidence?.value ?? '',
              hideLabelCheckout:
                component.component?.hideLabelCheckout || false,
            },
          ]
        }
      case 'dadata_organisation':
        return [
          {
            label: 'Код подразделения',
            value:
              component._data[component.component.key]?.submit?.issued?.code ??
              '',
            hideLabelCheckout: component.component?.hideLabelCheckout || false,
          },
          {
            label: 'Кем выдан',
            value:
              component._data[component.component.key]?.submit?.issued?.name ??
              '',
            hideLabelCheckout: component.component?.hideLabelCheckout || false,
          },
        ]
      case 'dadata_party':
        return [
          {
            label: 'ИНН',
            value:
              component._data[component.component.key]?.submit?.party?.inn ??
              '',
            hideLabelCheckout: component.component?.hideLabelCheckout || false,
          },
          {
            label: 'Фактический адрес',
            value:
              component._data[component.component.key]?.submit?.address ?? '',
            hideLabelCheckout: component.component?.hideLabelCheckout || false,
          },
          {
            label: 'Наименование',
            value: component._data[component.component.key]?.submit?.name ?? '',
            hideLabelCheckout: component.component?.hideLabelCheckout || false,
          },
        ]
      case 'custom_select':
        return {
          label: component.component.label,
          value: component._data[component.component.key]?.submit?.label ?? '',
          hideLabelCheckout: component.component?.hideLabelCheckout || false,
        }
      case 'custom_checkbox':
        // eslint-disable-next-line no-case-declarations
        const value = component._data[
          component.component.key
        ]?.submit?.valueTxt.map((val) => val.value)
        if (value.length) {
          return {
            label: component.component.label,
            value: value.join(', '),
            hideLabelCheckout: component.component?.hideLabelCheckout || false,
          }
        }
        return
      case 'radiobox':
        if (component._data[component.component.key]?.submit?.label) {
          return {
            label: component.component.label,
            value:
              component._data[component.component.key]?.submit?.label ?? '',
            hideLabelCheckout: component.component?.hideLabelCheckout || false,
          }
        } else {
          const value = component._data[
            component.component.key
          ]?.submit?.valueTxt.map((val) => val.value)

          if (value.length) {
            return {
              label: component.component.label,
              value: value.join(', '),
              hideLabelCheckout:
                component.component?.hideLabelCheckout || false,
            }
          }
        }
        return
      case 'otp':
        return {
          label: 'Телефон',
          value:
            component._data[component.component.key]?.submit?.telephone
              ?.phone ?? '',
          hideLabelCheckout: component.component?.hideLabelCheckout || false,
        }
      case 'datetime':
        return {
          label: component.component.label,
          value: moment(component._data[component.component.key]).format('L'),
          hideLabelCheckout: component.component?.hideLabelCheckout || false,
        }
      default:
        try {
          if (
            component._data[component.component.key] &&
            typeof component._data[component.component.key] !== 'object'
          ) {
            return {
              label: component?.component?.label ?? 'unknow component',
              value: component._data[component.component.key],
              hideLabelCheckout:
                component.component?.hideLabelCheckout || false,
            }
          }
          return
        } catch (e) {
          console.log('unknow component')
        }
    }
  }
}

export const parseForSubmit = (data, isChangeCheckout) => {
  const parsedData = []
  // console.log(data)
  _.map(data, (item, name) => {
    try {
      if (typeof item === 'object') {
        switch (item.submit.formioDataType) {
          case formioDataTypes.dadataAddress: {
            const addresValue = item?.submit?.displayAddressIndex
              ? `${item.submit?.address?.postal_code}, ${item.submit?.address?.value}`
              : item.submit?.address?.value
            const residenceAddressValue = item?.submit
              ?.displayResidenceAddressIndex
              ? `${item.submit?.addressResidence?.postal_code}, ${item.submit?.addressResidence?.value}`
              : item.submit?.addressResidence?.value
            parsedData.push({
              name: name,
              value: `${addresValue} ${
                residenceAddressValue ? '/' + residenceAddressValue : ''
              }`,
            })
            return
          }
          case formioDataTypes.dadataFms:
            parsedData.push({
              name: name,
              value: `${item.submit?.issued?.code} / ${item.submit?.issued?.name}`,
            })
            return
          case formioDataTypes.dadataParty:
            parsedData.push({
              name: name,
              value: `${item.submit?.party?.inn} / ${item.submit?.address} / ${item.submit?.name}`,
            })
            return
          case formioDataTypes.dictionary:
            if (item.submit?.type && item.submit?.type === 'radio') {
              // eslint-disable-next-line no-unused-expressions
              item.submit?.valueTxt.map((val) => {
                parsedData.push({
                  name: name,
                  value: val.value,
                })
              })
            } else {
              // eslint-disable-next-line no-unused-expressions
              item.submit?.valueTxt.map((val) => {
                parsedData.push({
                  name: `dictionaries.${item.submit.data.id}`,
                  value: val.id,
                })
              })
            }
            return

          case formioDataTypes.generalDictionary:
            parsedData.push({
              name: name,
              value: item.submit.label,
            })
            return
          case formioDataTypes.otp:
            parsedData.push({
              name: name,
              value: item.submit?.telephone?.phone,
            })
            return
          default:
            console.log('unknow  component')
            return null
        }
      } else {
        parsedData.push({ name: name, value: item })
        return
      }
    } catch (e) {
      console.log('unknow component')
    }
  })

  if (isChangeCheckout) {
    return parsedData
  }
  const noEmptyData = parsedData.filter(
    (item) => item?.value && item?.value.toString().length
  )

  return noEmptyData
}
