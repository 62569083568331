import React, { createRef } from 'react'
import { isAdminSide } from '../../../helpers/isAdminSide'
import Button from '../../../../catalog_app/components/Button'
import classNames from 'classnames'
import Spinner from '../../../../catalog_app/components/Spinner'
import {
  checkPhoneCountryCode,
  checkPhoneValidation,
  checkSmsCodeValidation,
} from '../redux/crud'

import MaskedInput from 'react-text-mask'
import { PHONE_MASK, errorPlaces, formioDataTypes } from '../../../../constants'
import { errorParser } from '../../../helpers/errorsParse'
import { attachPhoneStory } from '../../../../catalog_app/pages/productTemplate/_redux/crud'

const initialState = {
  telephone: {
    phone: '',
    approved: false,
    sending: false,
    sended: false,
    filled: false,
    error: null,
  },
  smscode: {
    code: '',
    sending: false,
    sended: false,
    filled: false,
    error: null,
  },
  next_try: {
    count: null,
    measurement: null,
  },
}

let countdown
export default class Otp extends React.PureComponent {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = initialState
  }

  telRef = createRef()

  componentDidMount() {
    this._isMounted = true
    clearInterval(countdown)
    if (this.props.value) {
      this.setState(
        (prevState) => ({ ...this.props.value?.submit }),
        () => {
          this._countDownTimer()
        }
      )
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  preventDeletingPhonePrefix = (phoneNumber) => {
    const phonePrefix = '+7 '
    if (!phoneNumber.startsWith(phonePrefix)) {
      return phonePrefix
    }
    return phoneNumber
  }

  changePhone = (e, cb = () => {}) => {
    const {
      component,
      options: { story_id },
    } = this.props
    const { value } = e.target
    console.log(value)

    this.telRef.current.inputElement.value = this.preventDeletingPhonePrefix(
      value
    )
    console.log(value)

    let focusIndex = value.indexOf('_')
    if (focusIndex !== -1 && value[focusIndex - 1] === ' ') {
      focusIndex = focusIndex - 1
    }
    if (focusIndex === -1) {
      focusIndex = value.length
    }

    this.telRef.current.inputElement.setSelectionRange(focusIndex, focusIndex)
    this.setState((prevState) => {
      return {
        telephone: {
          ...prevState.telephone,
          phone: this.preventDeletingPhonePrefix(value),
          // phone: value.replace(/\s+/g, ''),
          filled: value.indexOf('_') === -1 && value.length,
        },
      }
    }, cb())

    if (
      component?.disablePhoneValidation &&
      value.indexOf('_') === -1 &&
      value.replace(/\s+/g, '').length === 12 &&
      !this.state.telephone.filled
    ) {
      const phoneValue = value.replace(/\s+/g, '')

      attachPhoneStory(story_id, { phone: phoneValue })
        .then((response) => {
          this.setState({
            telephone: {
              ...this.state.telephone,
              filled: true,
            },
          })
          this.props.onChange({
            submit: {
              ...this.state,
              formioDataType: formioDataTypes.otp,
            },
          })
        })
        .catch((e) => {
          console.log(e.response)
        })
    }
  }

  handleClick = (e) => {
    const { phone } = this.state.telephone
    this.changePhone(
      { target: { value: this.preventDeletingPhonePrefix(phone) } },
      () => {
        this.telRef.current.inputElement.value = phone
        setTimeout(() => {
          const el = this.telRef.current.inputElement
          // const len = el.value.length
          let len = el.value.indexOf('_')
          if (el.value.indexOf('_') === -1) {
            len = el.value.length
          }
          if (el.setSelectionRange) {
            el.focus()
            el.setSelectionRange(len, len)
          }
        }, 1)
      }
    )
  }

  keyUpPhone = (e) => {
    const {
      telephone: { filled },
    } = this.state
    if (e.key === 'Enter' && filled) {
      this._handleCheckPhone()
    }
  }

  changeSmsCode = (e) => {
    const { value } = e.target

    const newValue = value.replace(/\s+/g, '').slice(0, 4)

    this.setState((prevState) => {
      return {
        smscode: {
          ...prevState.smscode,
          code: newValue,
          filled: newValue.length === 4,
        },
      }
    })
  }

  _handleCheckPhone = () => {
    const {
      telephone: { phone },
    } = this.state

    this.setState((prevState) => ({
      telephone: {
        ...prevState.telephone,
        sending: true,
        error: null,
      },
    }))

    const newPhone = phone.replace(/\s+/g, '')

    const countryCode = newPhone.substring(2, 5)
    /* проверка на наличие кода страны в общем справочнике */
    checkPhoneCountryCode(countryCode)
      .then((response) => {
        // отправляет телефон для получения смс
        checkPhoneValidation(newPhone)
          .then((response) => {
            this.setState(
              (prevState) => ({
                telephone: {
                  ...prevState.telephone,
                  sended: true,
                },
                next_try: response.data.next_try,
              }),
              () => {
                this._countDownTimer()
              }
            )
          })
          .catch((e) => {
            const error = errorParser(e.response, errorPlaces.content)
            if (typeof error === 'object') {
              this.setState((prevState) => ({
                telephone: {
                  ...prevState.telephone,
                  error: error[0][Object.keys(error[0])[0]],
                },
              }))
            } else {
              this.setState((prevState) => ({
                telephone: {
                  ...prevState.telephone,
                  error: error,
                },
              }))
            }
          })
      })
      .catch((e) => {
        const error = errorParser(e.response, errorPlaces.content)
        if (typeof error === 'object') {
          this.setState((prevState) => ({
            telephone: {
              ...prevState.telephone,
              error: error[0][Object.keys(error[0])[0]],
            },
          }))
        } else {
          this.setState((prevState) => ({
            telephone: {
              ...prevState.telephone,
              error: error,
            },
          }))
        }
      })
      .finally(() => {
        this.setState((prevState) => ({
          telephone: {
            ...prevState.telephone,
            sending: false,
          },
        }))
      })
  }

  _handleCheckSmsCode = () => {
    const { story_id } = this.props.options
    const {
      telephone: { phone },
      smscode: { code },
    } = this.state

    this.setState((prevState) => ({
      smscode: {
        ...prevState.smscode,
        sending: true,
        error: null,
      },
    }))

    // TODO need delete
    // backdoor for localhost test
    if (phone === '+79999999999' && code === '9999') {
      setTimeout(() => {
        this.setState(
          (prevState) => ({
            telephone: {
              ...prevState.telephone,
              approved: true,
            },
            next_try: initialState.next_try,
          }),
          () => {
            clearInterval(countdown)
            attachPhoneStory(story_id, { phone: phone })
              .then((response) => {
                this.props.onChange({
                  submit: {
                    ...this.state,
                    formioDataType: formioDataTypes.otp,
                  },
                })
              })
              .catch((e) => {
                console.log(e.response)
              })
          }
        )
      }, 1500)

      return
    }

    const newPhone = phone.replace(/\s+/g, '')

    checkSmsCodeValidation(newPhone, code)
      .then((response) => {
        this.setState(
          (prevState) => ({
            telephone: {
              ...prevState.telephone,
              approved: true,
            },
            next_try: initialState.next_try,
          }),
          () => {
            attachPhoneStory(story_id, { phone: newPhone })
              .then((response) => {
                this.props.onChange({
                  submit: {
                    ...this.state,
                    formioDataType: formioDataTypes.otp,
                  },
                })
              })
              .catch((e) => {
                console.log(e.response)
              })

            clearInterval(countdown)
          }
        )
      })
      .catch((e) => {
        const error = errorParser(e.response, errorPlaces.content)
        if (typeof error === 'object') {
          this.setState((prevState) => ({
            smscode: {
              ...prevState.smscode,
              error: error[0][Object.keys(error[0])[0]],
            },
          }))
        } else {
          this.setState((prevState) => ({
            smscode: {
              ...prevState.smscode,
              error: error,
            },
          }))
        }
      })
      .finally(() => {
        this.setState((prevState) => ({
          smscode: {
            ...prevState.smscode,
            sending: false,
          },
        }))
      })
  }

  onCancel = () => {
    this.setState(initialState)
    this.props.onChange(null)
  }

  _countDownTimer = () => {
    const { next_try } = this.state
    if (next_try.count > 0) {
      countdown = setInterval(() => {
        this.setState(
          (prevState) => {
            return {
              next_try: {
                ...prevState.next_try,
                count: prevState.next_try.count - 1,
              },
            }
          },
          () => {
            if (this.state.next_try.count <= 0) {
              clearInterval(countdown)
            }
          }
        )
      }, 1000)
    }
  }

  render() {
    const {
      location: { pathname },
    } = window
    const { component } = this.props
    const { telephone, smscode, next_try } = this.state

    return (
      <div className="otp-Wrapper">
        <div className="otp-controlWrapper">
          <div
            className={classNames('otp-form-control-wrapper', {
              approved: telephone.approved,
            })}
          >
            <MaskedInput
              ref={this.telRef}
              value={telephone.phone}
              type={'tel'}
              style={{
                letterSpacing: '-1px',
              }}
              keepCharPositions={true}
              mask={PHONE_MASK}
              showMask={true}
              placeholder={component.placeholder}
              className={classNames('form-control')}
              onChange={this.changePhone}
              onClick={this.handleClick}
              onKeyUp={this.keyUpPhone}
              disabled={telephone.sending || telephone.sended}
            />
          </div>
          {!component?.disablePhoneValidation && (
            <>
              {!telephone.approved ? (
                !isAdminSide(pathname) ? (
                  <Button
                    disabled={
                      !telephone.filled ||
                      telephone.sending ||
                      next_try.count > 0
                    }
                    onClick={() => {
                      this._handleCheckPhone()
                    }}
                  >
                    {telephone.sending ? (
                      <Spinner
                        style={{ width: '15px', height: '15px' }}
                        animation="border"
                        variant="primary"
                      >
                        <span className="sr-only">Загрузка...</span>
                      </Spinner>
                    ) : (
                      'Подтвердить'
                    )}
                  </Button>
                ) : (
                  <button className={'btn btn-primary'}>Подтвердить</button>
                )
              ) : (
                <div className="otp-approvedText">— подтверждено</div>
              )}
            </>
          )}
        </div>
        {!component?.disablePhoneValidation && (
          <>
            {Boolean(telephone.error) && (
              <div className={'errorLabel'}>{telephone.error}</div>
            )}

            {!telephone.approved && telephone.sended && (
              <div className="otp-codeWrapper">
                <div className="text">
                  Введите 4-ех значный код, полученный в SMS
                </div>
                <div className="otp-code-control-wrapper">
                  <input
                    value={smscode.code}
                    style={{
                      letterSpacing: '-1px',
                    }}
                    placeholder={'____'}
                    type={'tel'}
                    autoComplete="one-time-code"
                    // mask={SMS_CODE_MASK}
                    className={classNames(
                      'form-control sms-code-form-controll'
                    )}
                    onChange={this.changeSmsCode}
                    disabled={smscode.sending}
                  />
                  <Button
                    yellow
                    disabled={!smscode.filled || smscode.sending}
                    onClick={() => {
                      this._handleCheckSmsCode()
                    }}
                    className={'sms-code-button'}
                  >
                    {smscode.sending ? (
                      <Spinner
                        style={{ width: '15px', height: '15px' }}
                        animation="border"
                        variant="primary"
                      >
                        <span className="sr-only">Загрузка...</span>
                      </Spinner>
                    ) : (
                      'OK'
                    )}
                  </Button>
                  <button className="btn otp-code-btns" onClick={this.onCancel}>
                    Отменить
                  </button>

                  {next_try.count > 0 ? (
                    <div>
                      Отправить код повторно можно чере <b>{next_try.count}</b>{' '}
                      секунд
                    </div>
                  ) : (
                    <button
                      className="btn otp-code-btns"
                      onClick={this._handleCheckPhone}
                    >
                      Отправить код еще раз
                    </button>
                  )}
                </div>
                {Boolean(smscode.error) && (
                  <div className={'errorLabel'}>{smscode.error}</div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}
