import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  filter: null,
  story_id: null,
  contentSending: false,
  productsData: null,
}

export const actionTypes = {
  setFilterObject: 'SET_FILTER_OBJECT',
  setStoryId: 'SET_STORY_ID',
  clearStoryId: 'CLEAR_STORY_ID',
  setContentSending: 'SET_CONTENT_SENDING',
  setProductsOfferData: 'SET_CONTENT_PRODUCTS_DATA',
}

export const reducer = persistReducer(
  {
    storage,
    key: 'search_filter',
    story_id: 'search_filter',
    blacklist: ['contentSending', 'productsData'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.setFilterObject:
        return {
          ...state,
          filter: action.payload,
        }
      case actionTypes.setStoryId:
        return {
          ...state,
          story_id: action.payload,
        }
      case actionTypes.clearStoryId:
        return {
          ...state,
          story_id: initialState.story_id,
        }
      case actionTypes.setContentSending:
        return {
          ...state,
          contentSending: action.payload,
        }
      case actionTypes.setProductsOfferData: {
        return {
          ...state,
          productsData: action.payload,
        }
      }
      default:
        return {
          ...state,
        }
    }
  }
)

export const actions = {
  setFilterObject: (filter) => ({
    type: actionTypes.setFilterObject,
    payload: filter,
  }),
  setStoryId: (id) => ({
    type: actionTypes.setStoryId,
    payload: id,
  }),
  clearStoryId: () => ({
    type: actionTypes.clearStoryId,
  }),
  setContentSending: (bool) => ({
    type: actionTypes.setContentSending,
    payload: bool,
  }),
  setProductsOfferData: (data) => ({
    type: actionTypes.setProductsOfferData,
    payload: data,
  }),
}
