// import { put, takeLatest } from 'redux-saga/effects'
// import { showSeoUrl } from '../../SEO/_redux/crud'

export const actionTypes = {
  setInitialState: 'SET_PAGE_ABOUT_INITIAL_VALUES',
  getDataPage: 'GET_DATA_PAGE_ABOUT',
  setData: 'SET_DATA_PAGE_ABOUT',
  SetLoaderState: 'SET_PAGE_ABOUT_LOADER',
  setError: 'PAGE_ABOUT_SET_ERROR',
  // setSeoSuccess: 'SEO_SUCCESS',
}

const initialAuthState = {
  initialValues: {
    seo: {
      id: null,
      title: null,
      description: null,
      keywords: null,
      // robots: null,
    },
  },
  loader: false,
  error: false,
}

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.default: {
      return initialAuthState
    }

    case actionTypes.setInitialState:
      return {
        initialAuthState,
      }

    case actionTypes.setData:
      return {
        ...state,
        initialValues: {
          ...action.payload,
        },
        loader: false,
        error: false,
      }
    case actionTypes.getDataPage:
      return {
        ...state,
        loader: true,
        error: false,
      }
    case actionTypes.setError:
      return {
        ...state,
        loader: false,
        error: false,
      }
    default:
      return state
  }
}

export const getDataPage = (data) => ({
  type: actionTypes.getDataPage,
  data,
})

export const setData = (data) => ({
  type: actionTypes.setData,
  payload: data,
})

export function* saga() {
  // yield takeLatest(actionTypes.getDataPage, function* (e) {
  //   try {
  //     // const { data } = yield showSeoUrl('ABOUT')
  //     // yield put(setData({ seo: data }))
  //   } catch (e) {
  //     yield put(actionTypes.setError())
  //   }
  // })
}
