import { put, takeLatest } from 'redux-saga/effects' // select
import { getPartnersData } from './partnerCrud'
import { getAllData } from '../../../helpers/loadedData'

export const actionTypes = {
  getPartnersDataStart: 'GET_PARTNERS_DATA_START',
  getPartnersDataSuccess: 'GET_PARTNERS_DATA_SUCCESS',
  getPartnersDataError: 'GET_PARTNERS_DATA_ERROR',
}

const initialState = {
  data: [],
  loader: false,
  error: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getPartnersDataError: {
      return {
        data: [],
        loader: false,
        error: true,
      }
    }
    case actionTypes.getPartnersDataSuccess: {
      return {
        data: action.payload,
        loader: false,
        error: false,
      }
    }
    case actionTypes.getPartnersDataStart: {
      return {
        ...state,
        loader: true,
        error: false,
      }
    }
    default:
      return state
  }
}

export const getListPartnersStart = () => ({
  type: actionTypes.getPartnersDataStart,
})

export const getListPartnersSuccess = (data) => ({
  type: actionTypes.getPartnersDataSuccess,
  payload: data,
})

export const getListPartnersError = (data) => ({
  type: actionTypes.getPartnersDataError,
})

export function* saga() {
  // get contacts
  yield takeLatest(actionTypes.getPartnersDataStart, function* (e) {
    try {
      const { data } = yield getAllData(getPartnersData)
      yield put(getListPartnersSuccess(data))
    } catch (e) {
      yield put(getListPartnersError())
    }
  })
}
