import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import {
  unBlockProductsTemplate,
  blockProductsTemplate,
  deleteProductsTemplate,
  getProductsTemplate,
  createProductsTemplate,
  editProductsTemplate,
  editProductsTemplateIndustry,
} from './crud'
import lodash from 'lodash'

export const actionTypes = {
  setData: 'SET_PRODUCT_TEMPLATE',
  setDataPage: 'PRODUCT_TEMPLATE_LIST_PAGE',
  getData: 'GET_PRODUCT_TEMPLATES',
  errorData: 'PRODUCT_TEMPLATE_LIST_ERROR',
  deleteProductTemplate: 'DELETE_PRODUCT_TEMPLATE',
  blockProductTemplate: 'BLOCK_PRODUCT_TEMPLATE',
  unBlockProductTemplate: 'UNBLOCK_PRODUCT_TEMPLATE',
  changeDataId: 'PRODUCT_TEMPLATE_CHANGE_DATA',
  editProductTemplate: 'EDIT_PRODUCT_TEMPLATE',
  createProductTemplate: 'CREATE_PRODUCT_TEMPLATE',
  setCurrentTemplateInfo: 'SET_CURRENT_TEMPLATE_INFO',
}

const initialAuthState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
  currentTemplateInfo: null,
}

export const reducer = persistReducer(
  {
    storage,
    key: 'admin_product_template',
    whitelist: ['currentTemplateInfo'],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.getData: {
        return {
          ...state,
          data: [],
          isLoading: true,
          globalLoading: true,
        }
      }
      case actionTypes.setData: {
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          error: false,
          disabled: false,
          globalLoading: false,
        }
      }

      case actionTypes.errorData: {
        return {
          ...state,
          isLoading: false,
          error: true,
          disabled: false,
          globalLoading: false,
        }
      }

      case actionTypes.deleteProductTemplate: {
        return {
          ...state,
          isLoading: true,
          error: false,
          disabled: true,
        }
      }

      case actionTypes.blockProductTemplate:
        return { ...state, disabled: true, isLoading: true, error: false }
      case actionTypes.unBlockProductTemplate:
        return { ...state, disabled: true, isLoading: true, error: false }
      case actionTypes.changePage:
        return { ...state, disabled: true, isLoading: true, error: false }
      case actionTypes.setCurrentTemplateInfo: {
        return {
          ...state,
          currentTemplateInfo: action.data,
        }
      }
      default:
        return state
    }
  }
)
export const getDataAction = (page) => ({
  type: actionTypes.getData,
  page,
})

export const changePageAction = (page) => ({
  type: actionTypes.setDataPage,
  page,
})

export const deleteProductTemplatesAction = (id, history) => ({
  type: actionTypes.deleteProductTemplate,
  id,
  history,
})

export const blockProductTemplatesAction = (id, error = false) => ({
  type: actionTypes.blockProductTemplate,
  id,
  error,
})

export const unBlockProductTemplatesAction = (id, error = false) => ({
  type: actionTypes.unBlockProductTemplate,
  id,
  error,
})

export const actions = {
  getData: () => ({
    type: actionTypes.getData,
  }),
  updateData: (data) => ({ type: actionTypes.setData, payload: data }),
  error: () => ({ type: actionTypes.errorData }),
}

export const changeDataId = (data) => ({
  type: actionTypes.changeDataId,
  data,
})

export const setCurrentTemplateInfoAction = (data) => ({
  type: actionTypes.setCurrentTemplateInfo,
  data,
})

export const createProductTemplateAction = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.createProductTemplate,
      data,
      resolve,
      reject,
    })
  })
}

export const editProductTemplateAction = (id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.editProductTemplate,
      id,
      data,
      resolve,
      reject,
    })
  })
}

export function* saga() {
  yield takeLatest(actionTypes.changeDataId, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.productTemplates.data.map((item) => {
        if (currentData.data.id === item.id) {
          item = currentData.data
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.blockProductTemplate, function* (currentData) {
    try {
      const state = yield select()

      let newData = state.adminPanel.productTemplates.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))

      yield blockProductsTemplate(currentData.id)

      newData = state.adminPanel.productTemplates.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'draft'
          item.loader = false
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.productTemplates.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.updateData({ data: newData }))
      }
      yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.unBlockProductTemplate, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.productTemplates.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))

      yield unBlockProductsTemplate(currentData.id)

      newData = state.adminPanel.productTemplates.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'published'
          item.loader = false
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.productTemplates.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.updateData({ data: newData }))
      }
      yield put(actions.error())
    }
  })

  yield takeEvery(actionTypes.deleteProductTemplate, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.productTemplates.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))

      if (currentData.history) {
        currentData.history.push('/admin/content/article/')
      } else {
        yield deleteProductsTemplate(currentData.id)
      }

      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Удалено успешно', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      if (state.adminPanel.productTemplates.meta.pager.page === 0) {
        return yield put(changePageAction(1))
      }
      const data = state.adminPanel.productTemplates.data.filter(
        (item) => item.id !== currentData.id
      )
      const meta = (state.adminPanel.productTemplates.meta.pager = {
        ...state.adminPanel.productTemplates.meta.pager,
        total: state.adminPanel.productTemplates.meta.pager.total - 1,
        per_page: state.adminPanel.productTemplates.meta.pager.per_page,
        pages: state.adminPanel.productTemplates.meta.pager.pages,
      })

      if (meta.pages !== 1 && meta.pages - 1 >= meta.total / meta.per_page) {
        meta.pages = meta.pages - 1
      }
      // actions.updateData
      yield put(actions.updateData({ data: data, meta: { pager: meta } }))

      if (!data.length) {
        if (meta.total !== 0) {
          if (meta.pages !== meta.page) {
            if (meta.page > meta.pages) {
              yield put(changePageAction(meta.page - 1))
            } else {
              yield put(changePageAction(meta.page))
            }
          } else {
            yield put(changePageAction(Math.ceil(meta.total / meta.per_page)))
          }
        }
      }
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      const state = yield select()
      const newData = state.adminPanel.productTemplates.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))
      yield put(actions.error())
    }
  })

  yield takeLatest(actionTypes.getData, function* (e) {
    try {
      yield put(changePageAction(e.page))
    } catch (e) {
      console.log(e)
      yield put(actions.error())
    }
  })

  yield takeLatest(actionTypes.setDataPage, function* (e) {
    try {
      const { data } = yield getProductsTemplate(e.page)

      yield put(actions.updateData(data))
    } catch (e) {
      yield put(actions.error())
    }
  })

  yield takeLatest(actionTypes.createProductTemplate, function* ({
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createProductsTemplate(data)
      resolve(response)
    } catch (e) {
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editProductTemplate, function* ({
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const industry = lodash.pick(data, ['industry_id'])
      yield editProductsTemplateIndustry(id, industry)
      const response = yield editProductsTemplate(id, JSON.stringify(data))

      resolve(response)
    } catch (e) {
      reject(e.response)
    }
  })
}
