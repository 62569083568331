import axios from 'axios'

const path = '/admin/blog/categories'

export function setHeadingImg(id, data) {
  return axios.post(`${path}/${id}/images`, data)
}

export function headingCreate(data) {
  return axios.post(`${path}`, data)
}

export function editDataHeading(id, data) {
  return axios.put(`${path}/${id}`, data)
}

export function getHeadingList(page = 1) {
  return axios.get(`${path}?page=${page}`)
}

export function headingGet(id) {
  return axios.get(`${path}/${id}`)
}
// deleteHeading, blockedHeading, unBlockHeading
export function deleteHeading(id) {
  return axios.delete(`${path}/${id}`)
}
export function blockedHeading(id) {
  return axios.patch(`${path}/${id}/disable`)
}
export function unBlockedHeading(id) {
  return axios.patch(`${path}/${id}/enable`)
}

export function deleteImageHeading(id) {
  return axios.delete(`${path}/${id}/images`)
}
