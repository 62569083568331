import { put, takeLatest } from 'redux-saga/effects'
import { getProductsCompanyCoverage, getListRisks } from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'
import { getAllData } from '../../../helpers/api'

export const actionTypes = {
  getProductCompanyCoverageDataStart: 'GET_PRODUCT_COMPANY_COVERAGE_DATA_START',
  getProductCompanyCoverageActionError:
    'GET_PRODUCT_COMPANY_COVERAGE_DATA_ERROR',
  getProductCompanyCoverageDataSuccess:
    'GET_PRODUCT_COMPANY_COVERAGE_DATA_SUCCESS',
  deleteProductCompanyCoverage: 'DELETE_PRODUCT_COMPANY_COVERAGE',
  blockProductCompanyCoverage: 'BLOCK_PRODUCT_COMPANY_COVERAGE',
  unBlockProductCompanyCoverage: 'UN_BLOCK_PRODUCT_COMPANY_COVERAGE',
  loadingStop: 'LOADING_STOP_PRODUCT_COMPANY_COVERAGE',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getProductCompanyCoverageDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        // meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getProductCompanyCoverageDataStart: {
      return {
        ...state,
        data: [],
        error: false,
        isLoading: true,
      }
    }

    case actionTypes.unBlockProductCompanyCoverage: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.blockProductCompanyCoverage: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case actionTypes.getProductCompanyCoverageActionError: {
      return {
        ...state,
        isLoading: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const loadingStopAction = () => ({
  type: actionTypes.loadingStop,
})

export const getProductCompanyCoverageActionStart = (
  companyId,
  company_product_id,
  product_id,
  page
) => ({
  type: actionTypes.getProductCompanyCoverageDataStart,
  companyId,
  company_product_id,
  product_id,
  page,
})

export const getProductCompanyCoverageActionSuccess = (data) => ({
  type: actionTypes.getProductCompanyCoverageDataSuccess,
  payload: data,
})

export const getProductCompanyCoverageActionError = () => ({
  type: actionTypes.getProductCompanyCoverageActionError,
})

export function* saga() {
  yield takeLatest(actionTypes.getProductCompanyCoverageDataStart, function* (
    e
  ) {
    try {
      let dataRequest = []

      let promises = []
      promises[0] = getAllData(getListRisks, 1, [e.product_id])
      yield Promise.allSettled(promises).then(async (res) => {
        promises = []
        res[0].value.data.map((item) => {
          promises.push(
            getAllData(getProductsCompanyCoverage, 1, [
              e.companyId,
              e.company_product_id,
              e.product_id,
              item.id,
            ]).then((res) => {
              res.id = item.id
              return res
            })
          )
        })
        await Promise.allSettled(promises).then((resChildren) => {
          if (!lodash.some(res, { status: 'rejected' })) {
            dataRequest = res[0].value.data.map((itemChild) => {
              const childres = resChildren.filter(
                (childItem) => childItem.value.id === itemChild.id
              )[0].value.data
              if (!childres.length) {
                childres.push({
                  id: 0,
                  name: '',
                  description: '',
                  rate: 1,
                  insured_sum_from: 1,
                  insured_sum_to: 1,
                  status: 'disabled',
                  newItem: true,
                })
              }
              itemChild.children = childres
              return itemChild
            })
          } else {
            throw Error
          }
        })
      })

      yield put(getProductCompanyCoverageActionSuccess({ data: dataRequest }))
    } catch (e) {
      yield put(getProductCompanyCoverageActionError())
      errorParser(e.response)
    }
  })
}
