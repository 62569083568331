import axios from 'axios'

const path = '/admin/content/industries'

export function getIndustries(page = 1) {
  return axios.get(`${path}?page=${page}`)
}

export function postIndustries(data) {
  return axios.post(`${path}`, data)
}

export function editIndustries(id, data) {
  return axios.put(`${path}/${id}`, data)
}

export function deleteIndustries(id) {
  return axios.delete(`${path}/${id}`)
}

export function publishIndustries(id) {
  return axios.patch(`${path}/${id}/publish`)
}

export function unpublishIndustries(id) {
  return axios.patch(`${path}/${id}/unpublish`)
}

export function showIndustriesId(id) {
  return axios.get(`${path}/${id}`)
}

export function postIndustriesImage(id, data) {
  return axios.post(`${path}/${id}/attachments`, data)
}

export function deleteIndustriesIconImage(id, data) {
  return axios.delete(`${path}/${id}/icon-detach`, data)
}

export function deleteIndustriesMainImage(id, data) {
  return axios.delete(`${path}/${id}/main-image-detach`, data)
}

export function getProduct(page) {
  return axios.get(`/admin/products?page=${page}`)
}

export function getProductByIndustryId(page, industry_id) {
  return axios.get(`/products?page=${page}&industry_id=${industry_id}`)
}
// blocks
export function setBlockIndustriesImage(id, blockId, data) {
  return axios.post(`${path}/${id}/blocks/${blockId}/preview-attach`, data)
}

export function getBlockIndustries(id, page) {
  return axios.get(`${path}/${id}/blocks?page=${page}`)
}

export function deleteBlockIndustriesImage(id, blockId) {
  return axios.delete(`${path}/${id}/blocks/${blockId}/preview-detach`)
}

export function createBlockIndustries(id, data) {
  return axios.post(`${path}/${id}/blocks`, data)
}

export function deleteBlockIndustries(id, blockId) {
  return axios.delete(`${path}/${id}/blocks/${blockId}`)
}

export function editBlockIndustries(id, blockId, data) {
  return axios.put(`${path}/${id}/blocks/${blockId}`, data)
}

export function disableBlockIndustries(id, blockId) {
  return axios.patch(`${path}/${id}/blocks/${blockId}/disable`)
}
export function enableBlockIndustries(id, blockId) {
  return axios.patch(`${path}/${id}/blocks/${blockId}/enable`)
}

// cards
export function setCardIndustriesImage(id, cardsId, data) {
  return axios.post(`${path}/${id}/cards/${cardsId}/icon-attach`, data)
}

export function getCardIndustries(id, page) {
  return axios.get(`${path}/${id}/cards?page=${page}`)
}

export function deleteCardIndustriesImage(id, cardsId) {
  return axios.delete(`${path}/${id}/cards/${cardsId}/icon-detach`)
}

export function createCardIndustries(id, data) {
  return axios.post(`${path}/${id}/cards`, data)
}

export function editCardIndustries(id, cardsId, data) {
  return axios.put(`${path}/${id}/cards/${cardsId}`, data)
}

export function disableCardIndustries(id, cardsId) {
  return axios.patch(`${path}/${id}/cards/${cardsId}/disable`)
}
export function enableCardIndustries(id, cardsId) {
  return axios.patch(`${path}/${id}/cards/${cardsId}/enable`)
}

export function deleteCardIndustries(id, cardsId) {
  return axios.delete(`${path}/${id}/cards/${cardsId}`)
}
