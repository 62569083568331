import axios from 'axios'

const staticPageUrl = '/admin/pages'

export function getStaticPages(page = 1) {
  return axios.get(`${staticPageUrl}?page=${page}`)
}

export function deletePage(id) {
  return axios.delete(`${staticPageUrl}/${id}`)
}

export function blockPage(id) {
  return axios.patch(`${staticPageUrl}/${id}/disable`)
}

export function editPageId(id, data) {
  return axios.put(`${staticPageUrl}/${id}`, data)
}

export function unBlockPage(id) {
  return axios.patch(`${staticPageUrl}/${id}/enable`)
}

export function showPageId(id) {
  return axios.get(`${staticPageUrl}/${id}`)
}

export function createStaticPage(data) {
  return axios.post(`${staticPageUrl}`, data)
}
