import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects' // select
import {
  getListHeader,
  getListFooter,
  getFooterContacts,
  getSeoPageData,
  getAnalyticsRecordsData,
  getFooterSettings,
} from './layoutCrud'
import { getAllData } from '../../helpers/loadedData'

export const actionTypes = {
  getDataHeaderStart: 'HEADER_GET_DATA_START',
  getDataHeaderSuccess: 'HEADER_GET_DATA_SUCCESS',
  getDataFooterStart: 'FOOTER_GET_DATA_START',
  getDataFooterSuccess: 'FOOTER_GET_DATA_SUCCESS',
  getFooterContactsStart: 'FOOTER_GET_CONTACTS_START',
  getFooterContactsSuccess: 'FOOTER_GET_CONTACTS_SUCCESS',
  getFooterSettingsStart: 'FOOTER_GET_SETTINGS_START',
  getFooterSettingsSuccess: 'FOOTER_GET_SETTINGS_SUCCESS',
  setHelmetTitleSuccess: 'SET_HELMET_TITLE_SUCCESS',
  getSeoDataStart: 'GET_SEO_DATA_START',
  getSeoDataSuccess: 'GET_SEO_DATA_SUCCESS',
  setSeoDataInitialSuccess: 'SET_SEO_DATA_INITIAL_SUCCESS',
  getAnalyticsRecordsStart: 'GET_ANALYTICS_DATA_START',
  getAnalyticsRecordsSuccess: 'GET_ANALYTICS_DATA_SUCCESS',
}

const initialState = {
  header: [],
  footer: [],
  footerSettings: {
    'footer-info-text': null,
    'copyright-text': null,
    'privacy-link': null,
    'privacy-link-title': null,
    'support-link': null,
    'support-link-title': null,
  },
  contacts: null,
  seo: {
    id: null,
    title: 'ПСБ',
    description: 'ПСБ',
    keywords: 'ПСБ',
    url: '/',
  },
  analytics: {
    'head-script': null,
    'body-script': null,
  },
  helmetTitle: 'ПСБ',
}

export const reducer = persistReducer(
  {
    storage,
    key: 'site_layout',
    blacklist: ['seo'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.getDataHeaderSuccess: {
        return {
          ...state,
          header: action.payload,
        }
      }
      case actionTypes.getDataFooterSuccess: {
        return {
          ...state,
          footer: action.payload,
        }
      }
      case actionTypes.getFooterContactsSuccess: {
        return {
          ...state,
          contacts: action.payload,
        }
      }
      case actionTypes.setHelmetTitleSuccess: {
        return {
          ...state,
          helmetTitle: action.payload,
        }
      }

      case actionTypes.getSeoDataSuccess: {
        return {
          ...state,
          seo: action.payload,
        }
      }
      case actionTypes.setSeoDataInitialSuccess: {
        return {
          ...state,
          seo: initialState.seo,
        }
      }
      case actionTypes.getAnalyticsRecordsSuccess: {
        return {
          ...state,
          analytics: action.payload,
        }
      }
      case actionTypes.getFooterSettingsSuccess: {
        return {
          ...state,
          footerSettings: action.payload,
        }
      }
      default:
        return state
    }
  }
)
export const getDataHeaderStart = () => ({
  type: actionTypes.getDataHeaderStart,
})

export const getDataHeaderSuccess = (data) => ({
  type: actionTypes.getDataHeaderSuccess,
  payload: data,
})

export const getDataFooterStart = () => ({
  type: actionTypes.getDataFooterStart,
})

export const getDataFooterSuccess = (data) => ({
  type: actionTypes.getDataFooterSuccess,
  payload: data,
})

export const getFooterContactsStart = () => ({
  type: actionTypes.getFooterContactsStart,
})

export const getFooterContactsSuccess = (data) => ({
  type: actionTypes.getFooterContactsSuccess,
  payload: data,
})

export const setHelmetTitleSuccess = (title) => ({
  type: actionTypes.setHelmetTitleSuccess,
  payload: title,
})

export const getSeoDataStart = (identifier) => ({
  type: actionTypes.getSeoDataStart,
  payload: identifier,
})

export const getSeoDataSuccess = (data) => ({
  type: actionTypes.getSeoDataSuccess,
  payload: data,
})

export const setSeoDataInitial = () => ({
  type: actionTypes.setSeoDataInitialSuccess,
})

export const getAnalyticsRecordsStart = () => ({
  type: actionTypes.getAnalyticsRecordsStart,
})

export const getAnalyticsRecordsSuccess = (data) => ({
  type: actionTypes.getAnalyticsRecordsSuccess,
  payload: data,
})

export const getFooterSettingsStart = () => ({
  type: actionTypes.getFooterSettingsStart,
})

export const getFooterSettingsSuccess = (data) => ({
  type: actionTypes.getFooterSettingsSuccess,
  payload: data,
})

export function* saga() {
  // get header list
  yield takeLatest(actionTypes.getDataHeaderStart, function* (currentData) {
    try {
      const { data } = yield getListHeader()

      yield put(getDataHeaderSuccess(data))
    } catch (e) {
      console.log(e)
      // yield put(setErrorPageHeader())
    }
  })
  // get footer items
  yield takeLatest(actionTypes.getDataFooterStart, function* (currentData) {
    try {
      const { data } = yield getListFooter()

      yield put(getDataFooterSuccess(data))
    } catch (e) {
      console.log(e)
      // yield put(setErrorPageHeader())
    }
  })

  yield takeLatest(actionTypes.getFooterContactsStart, function* (currentData) {
    try {
      const { data } = yield getAllData(getFooterContacts)

      yield put(getFooterContactsSuccess(data))
    } catch (e) {
      console.log(e)
      // yield put(setErrorPageHeader())
    }
  })

  yield takeLatest(actionTypes.getSeoDataStart, function* ({ payload }) {
    try {
      const { data } = yield getSeoPageData(payload)

      yield put(getSeoDataSuccess(data))
    } catch (e) {
      yield put(getSeoDataSuccess(initialState.seo))
      console.log(e)
      // yield put(setErrorPageHeader())
    }
  })

  yield takeLatest(actionTypes.getAnalyticsRecordsStart, function* () {
    try {
      const { data } = yield getAnalyticsRecordsData()

      const analyticsData = {
        'head-script': null,
        'body-script': null,
      }

      data.data.forEach((item) => {
        analyticsData[`${item.key}`] = item.value
      })

      yield put(getAnalyticsRecordsSuccess(analyticsData))
    } catch (e) {
      console.log(e)
    }
  })

  yield takeLatest(actionTypes.getFooterSettingsStart, function* () {
    try {
      const { data } = yield getFooterSettings()
      const settings = {
        'footer-info-text': null,
        'copyright-text': null,
        'privacy-link': null,
        'privacy-link-title': null,
        'support-link': null,
        'support-link-title': null,
      }
      data.data.forEach((item) => {
        settings[`${item.key}`] = {
          value: item.value,
          status: item.status,
        }
      })

      yield put(getFooterSettingsSuccess(settings))
    } catch (e) {
      console.log(e)
    }
  })
}
