export const conditionalObj = (values) => {
  const valuesForConditionl = {}
  values.map((item) => {
    if (item.id) {
      valuesForConditionl[item.id] = true
    } else {
      valuesForConditionl[item] = true
    }
  })
  return valuesForConditionl
}
