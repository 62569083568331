import { put, takeLatest } from 'redux-saga/effects' // select
import { getListAticles } from './headingCrud'
import { getAllData } from '../../../helpers/loadedData'

export const actionTypes = {
  getListArticlesMainStart: 'GET_ARTICLES_LIST_MAIN_START_SITE',
  getListArticlesSuccess: 'GET_ARTICLES_LIST_MAIN_SUCCESS_SITE',
  getListArticlesMainError: 'GET_ARTICLES_LIST_MAIN_ERROR_SITE',
}

const initialState = {
  data: [],
  loader: false,
  error: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getListArticlesSuccess: {
      return {
        ...state,
        loader: false,
        data: action.payload,
      }
    }
    case actionTypes.getListArticlesMainStart: {
      return {
        ...state,
        loader: true,
        error: false,
      }
    }
    case actionTypes.getListArticlesMainError: {
      return {
        ...state,
        loader: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getListArticlesMainError = () => ({
  type: actionTypes.getListArticlesMainError,
})

export const getListArticlesMainStart = (page = 1) => ({
  type: actionTypes.getListArticlesMainStart,
  payload: page,
})

export const getListArticlesSuccess = (data) => ({
  type: actionTypes.getListArticlesSuccess,
  payload: data,
})

export function* saga() {
  yield takeLatest(actionTypes.getListArticlesMainStart, function* (e) {
    try {
      const { data } = yield getAllData(getListAticles)
      yield put(getListArticlesSuccess(data))
    } catch (e) {
      yield put(getListArticlesMainError())
    }
  })
}
