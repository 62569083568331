import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import {
  get_user_show,
  delete_user,
  block_user,
  unblock_user,
} from './usersCrud'
import lodash from 'lodash'

export const actionTypes = {
  setData: 'USERS_SET_USERS_LIST',
  setDataPage: 'USERS_SET_USERS_LIST_PAGE',
  getData: 'USERS_GET_USERS_LIST',
  errorData: 'USERS_GET_USERS_LIST_ERROR',
  deleteUser: 'USERS_DELETE_ID',
  blockUser: 'USERS_BLOCK_ID',
  unBlockUser: 'USERS_UNBLOCK_ID',
}

const initialAuthState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = persistReducer(
  {
    storage,
    key: 'users',
    blacklist: ['data', 'meta', 'error', 'disabled', 'globalLoading'],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.getData: {
        return {
          ...state,
          data: [],
          isLoading: true,
          globalLoading: true,
        }
      }
      case actionTypes.setData: {
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          error: false,
          disabled: false,
          globalLoading: false,
        }
      }

      case actionTypes.errorData: {
        return {
          ...state,
          isLoading: false,
          error: true,
          disabled: false,
          globalLoading: false,
        }
      }

      case actionTypes.deleteUser: {
        return {
          ...state,
          isLoading: true,
          error: false,
          disabled: true,
        }
      }

      case actionTypes.blockUser:
        return { ...state, disabled: true, isLoading: true, error: false }
      case actionTypes.unBlockUser:
        return { ...state, disabled: true, isLoading: true, error: false }
      case actionTypes.changePage:
        return { ...state, disabled: true, isLoading: true, error: false }
      default:
        return state
    }
  }
)
export const getData = () => ({
  type: actionTypes.getData,
})

export const changePage = (page) => ({
  type: actionTypes.setDataPage,
  page,
})

export const deleteUser = (id) => ({
  type: actionTypes.deleteUser,
  id,
})

export const blockUser = (id, error) => ({
  type: actionTypes.blockUser,
  id,
  error,
})

export const unBlockUser = (id, error) => ({
  type: actionTypes.unBlockUser,
  id,
  error,
})

export const actions = {
  getData: () => ({
    type: actionTypes.getData,
  }),
  updateData: (data) => ({ type: actionTypes.setData, payload: data }),
  error: () => ({ type: actionTypes.errorData }),
}

export function* saga() {
  yield takeEvery(actionTypes.unBlockUser, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.users.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })
      yield put(actions.updateData({ data: newData }))
      yield unblock_user(currentData.id)
      newData = state.adminPanel.users.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'active'
          item.loader = false
        }
        return item
      })
      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.users.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })
        yield put(actions.updateData({ data: newData }))
      }
    }
  })

  yield takeEvery(actionTypes.blockUser, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.users.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.updateData({ data: newData }))

      yield block_user(currentData.id)
      newData = state.adminPanel.users.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'blocked'
          item.loader = false
        }
        return item
      })
      yield put(actions.updateData({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.users.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.updateData({ data: newData }))
      }
    }
  })

  yield takeLatest(actionTypes.deleteUser, function* (currentData) {
    try {
      const state = yield select()
      yield delete_user(currentData.id)

      const data = state.adminPanel.users.data.filter(
        (item) => item.id !== currentData.id
      )
      const meta = (state.adminPanel.users.meta.pager = {
        count: state.adminPanel.users.meta.pager.count - 1,
        total: state.adminPanel.users.meta.pager.total - 1,
        per_page: state.adminPanel.users.meta.pager.per_page,
        pages: state.adminPanel.users.meta.pager.pages,
      })
      yield put(actions.updateData({ data: data, meta: { pager: meta } }))
    } catch (e) {
      yield put(actions.error())
    }
  })

  yield takeLatest(actionTypes.getData, function* () {
    try {
      const { data } = yield get_user_show()

      yield put(actions.updateData(data))
    } catch (e) {
      yield put(actions.error())
    }
  })

  yield takeLatest(actionTypes.setDataPage, function* (e) {
    try {
      const { data } = yield get_user_show(e.page)

      yield put(actions.updateData(data))
    } catch (e) {
      yield put(actions.error())
    }
  })
}
