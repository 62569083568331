import { put, takeLatest, select, fork, call, take } from 'redux-saga/effects'
import {
  deleteProductCompanyCoverageRisk,
  getProductsCompanyCoverageRisk,
  blockProductCompanyCoverageRisk,
  unBlockProductCompanyCoverageRisk,
  getProductRisks,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'
import { getAllData } from '../../../helpers/api'

export const actionTypes = {
  getProductCompanyCoverageRiskDataStart:
    'GET_PRODUCT_COMPANY_COVERAGE_RISK_DATA_START',
  getProductCompanyCoverageRiskDataSuccess:
    'GET_PRODUCT_COMPANY_COVERAGE_RISK_DATA_SUCCESS',
  deleteProductCompanyCoverageRisk: 'DELETE_PRODUCT_COMPANY_COVERAGE_RISK',
  blockProductCompanyCoverageRisk: 'BLOCK_PRODUCT_COMPANY_COVERAGE_RISK',
  unBlockProductCompanyCoverageRisk: 'UN_BLOCK_PRODUCT_COMPANY_COVERAGE_RISK',
  loadingStop: 'LOADING_STOP_PRODUCT_COMPANY_COVERAGE_RISK',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getProductCompanyCoverageRiskDataSuccess: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      }
    }

    case actionTypes.getProductCompanyCoverageRiskDataStart: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.unBlockProductCompanyCoverageRisk: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.blockProductCompanyCoverageRisk: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const loadingStopAction = () => ({
  type: actionTypes.loadingStop,
})

export const getProductCompanyCoverageRiskActionStart = (
  companyId,
  product_id,
  coverageId,
  page,
  coverage
) => ({
  type: actionTypes.getProductCompanyCoverageRiskDataStart,
  companyId,
  product_id,
  coverageId,
  page,
  coverage,
})

export const getProductCompanyCoverageRiskActionSuccess = (data) => ({
  type: actionTypes.getProductCompanyCoverageRiskDataSuccess,
  payload: data,
})

export const deleteProductCompanyCoverageRiskAction = (
  companyId,
  product_id,
  coverageId,
  riskId
) => ({
  type: actionTypes.deleteProductCompanyCoverageRisk,
  companyId,
  product_id,
  coverageId,
  riskId,
})

export const blockProductCompanyCoverageRiskAction = (
  companyId,
  product_id,
  coverageId,
  riskId
) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.blockProductCompanyCoverageRisk,
      companyId,
      product_id,
      coverageId,
      riskId,
      resolve,
    })
  })
}
export const unBlockProductCompanyCoverageRiskAction = (
  companyId,
  product_id,
  coverageId,
  riskId
) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.unBlockProductCompanyCoverageRisk,
      companyId,
      product_id,
      coverageId,
      riskId,
      resolve,
    })
  })
}

export function* saga() {
  function* takeOneAndBlock(pattern, worker, ...args) {
    const task = yield fork(function* () {
      while (true) {
        const action = yield take(pattern)
        yield call(worker, ...args, action)
      }
    })
    return task
  }

  function* fetchRequest(e) {
    try {
      const promises = []
      let data = []
      promises[0] = getProductsCompanyCoverageRisk(
        e.page,
        e.companyId,
        e.product_id,
        e.coverageId
      )

      promises[1] = getAllData(getProductRisks, 1, [
        e.coverage.product_id,
        e.coverage.coverage_id,
      ])

      yield Promise.allSettled(promises)
        .then(async (res) => {
          if (!lodash.some(res, { status: 'rejected' })) {
            data = res[1].value.data.map((item) => {
              item.children = res[0].value.data.data.filter(
                (itemRisk) => itemRisk.risk_id === item.id
              )
              return item
            })
          } else {
            throw Error
          }
        })
        .catch((e) => {
          errorParser(e.response)
          throw e
        })

      yield put(getProductCompanyCoverageRiskActionSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  }

  yield takeLatest(actionTypes.blockProductCompanyCoverageRisk, function* (e) {
    try {
      const {
        adminPanel: { productCompanyCoveragesRisk },
      } = yield select()
      yield blockProductCompanyCoverageRisk(
        e.companyId,
        e.product_id,
        e.coverageId,
        e.riskId
      )

      yield put(
        getProductCompanyCoverageRiskActionStart(
          e.companyId,
          e.product_id,
          e.coverageId,
          productCompanyCoveragesRisk.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    } finally {
      yield put(loadingStopAction())
      e.resolve()
    }
  })
  yield takeLatest(actionTypes.unBlockProductCompanyCoverageRisk, function* (
    e
  ) {
    try {
      const {
        adminPanel: { productCompanyCoveragesRisk },
      } = yield select()
      yield unBlockProductCompanyCoverageRisk(
        e.companyId,
        e.product_id,
        e.coverageId,
        e.riskId
      )

      yield put(
        getProductCompanyCoverageRiskActionStart(
          e.companyId,
          e.product_id,
          e.coverageId,
          productCompanyCoveragesRisk.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    } finally {
      yield put(loadingStopAction())
      e.resolve()
    }
  })

  yield takeLatest(actionTypes.deleteProductCompanyCoverageRisk, function* (e) {
    try {
      const {
        adminPanel: {
          productCompanyCoveragesRisk: { meta },
        },
      } = yield select()

      yield deleteProductCompanyCoverageRisk(
        e.companyId,
        e.product_id,
        e.coverageId,
        e.riskId
      )

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Продукт успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(
        getProductCompanyCoverageRiskActionStart(
          e.companyId,
          e.product_id,
          e.coverageId,
          page
        )
      )
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeOneAndBlock(
    (action) =>
      actionTypes.getProductCompanyCoverageRiskDataStart === action.type,
    fetchRequest
  )
}
