/* eslint-disable no-script-url */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import UserRolesAccessComponent from '../../../../../app/components/UserRolesAccessComponent'
import { userRoles } from '../../../../../constants'

export function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : ''
  }

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <UserRolesAccessComponent
          access={[userRoles.root.type, userRoles.superAdmin.type]}
        >
          <li
            className={`menu-item ${getMenuItemActive('/users', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin/users">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/global/i-users1.svg')}
                />
              </span>
              <span className="menu-text">Пользователи</span>
            </NavLink>
          </li>
        </UserRolesAccessComponent>

        <li
          className={`menu-item ${getMenuItemActive('/profile', false)} d-none`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/Communication/i-users-active.svg'
                )}
              />
            </span>
            <span className="menu-text">Пользователь</span>
          </NavLink>
        </li>
        <UserRolesAccessComponent
          access={[
            userRoles.root.type,
            userRoles.superAdmin.type,
            userRoles.contentManager.type,
          ]}
        >
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/error',
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/content/">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/General/Settings-1.svg')}
                />
              </span>
              <span className="menu-text">Управление контентом</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Управление контентом</span>
                  </span>
                </li>
                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/content/control-content'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/admin/content/control-content"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Header/Footer</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/content/static-pages'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/admin/content/static-pages"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Статические страницы</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/content/faq'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/content/faq">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">FAQ</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
                <UserRolesAccessComponent
                  access={[
                    userRoles.root.type,
                    userRoles.superAdmin.type,
                    userRoles.contentManager.type,
                  ]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/content/article'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/content/article">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Статьи</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
                <UserRolesAccessComponent
                  access={[
                    userRoles.root.type,
                    userRoles.superAdmin.type,
                    userRoles.contentManager.type,
                  ]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/content/heading'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/content/heading">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Рубрики</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>

                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/content/service'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/content/service">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Услуги</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/content/contacts'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/content/contacts">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Контакты</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/content/about'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/content/about">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">О нас</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
                <UserRolesAccessComponent
                  access={[
                    userRoles.root.type,
                    userRoles.superAdmin.type,
                    userRoles.contentManager.type,
                  ]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/main-management'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/main-management">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        Управление главной страницы
                      </span>
                    </NavLink>
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        {/* begin::3 Level */}
                        <li
                          className={`menu-item  ${getMenuItemActive(
                            '/admin/main-management/slider-main/item/',
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/admin/main-management/slider-main/item/"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Добавить слайд</span>
                          </NavLink>
                        </li>
                        <li
                          className={`menu-item  ${getMenuItemActive(
                            '/admin/main-management/slider-best-deal/item/',
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/admin/main-management/slider-best-deal/item/"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              Добавить слайд "Лучшее предложение"
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                </UserRolesAccessComponent>
                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/partners'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/partners">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Партнеры</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/analytics'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/analytics">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Внешняя аналитика</span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
              </ul>
            </div>
          </li>
        </UserRolesAccessComponent>

        <UserRolesAccessComponent
          access={[
            userRoles.root.type,
            userRoles.superAdmin.type,
            userRoles.productTemplateManager.type,
          ]}
        >
          <li
            className={`menu-item ${getMenuItemActive(
              '/admin/product-templates'
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin/product-templates">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
              </span>
              <span className="menu-text">Шаблоны продуктов</span>
            </NavLink>
          </li>
        </UserRolesAccessComponent>
        <UserRolesAccessComponent
          access={[
            userRoles.root.type,
            userRoles.superAdmin.type,
            userRoles.partnerManager.type,
          ]}
        >
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/admin/company',
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/company">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/General/Shield-check.svg'
                  )}
                />
              </span>
              <span className="menu-text">Страховые компании</span>
            </NavLink>
          </li>
        </UserRolesAccessComponent>
        <UserRolesAccessComponent
          access={[userRoles.root.type, userRoles.superAdmin.type]}
        >
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/admin/services',
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/services/">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Layout/Layout-4-blocks.svg'
                  )}
                />
              </span>
              <span className="menu-text">Услуги</span>
            </NavLink>
          </li>
        </UserRolesAccessComponent>
        <UserRolesAccessComponent
          access={[userRoles.root.type, userRoles.superAdmin.type]}
        >
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/admin/promocodes',
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/promocodes/">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/global/i-promocodes1.svg'
                  )}
                />
              </span>
              <span className="menu-text">Промокоды</span>
            </NavLink>
            <div className={'hidden'}>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/promocodes/'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/promocodes/">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Список промокодов</span>
                    </NavLink>
                    <div className="menu-submenu">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li
                          className={`menu-item  ${getMenuItemActive(
                            '/admin/promocodes/item/'
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/admin/promocodes/item/"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              Карточка промокода
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </UserRolesAccessComponent>
        <UserRolesAccessComponent
          access={[userRoles.root.type, userRoles.superAdmin.type]}
        >
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/admin/general-dictionary',
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/admin/general-dictionary"
            >
              <span
                onMouseMove={() => {
                  console.log('set')
                }}
                className="svg-icon menu-icon"
              >
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/global/i-help1.svg')}
                />
              </span>
              <span className="menu-text">Общие справочники</span>
            </NavLink>
          </li>
        </UserRolesAccessComponent>
        <UserRolesAccessComponent
          access={[userRoles.root.type, userRoles.superAdmin.type]}
        >
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/admin/retention',
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/retention">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/global/i-magnet.svg')}
                />
              </span>
              <span className="menu-text">Механизм удержания</span>
            </NavLink>
          </li>
        </UserRolesAccessComponent>
        <UserRolesAccessComponent
          access={[userRoles.root.type, userRoles.superAdmin.type]}
        >
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/admin/feedback',
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/feedback">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Communication/Group-chat.svg'
                  )}
                />
              </span>
              <span className="menu-text">Обратная связь</span>
            </NavLink>
          </li>
        </UserRolesAccessComponent>
        <UserRolesAccessComponent
          access={[userRoles.root.type, userRoles.superAdmin.type]}
        >
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/admin/widgets',
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/widgets">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Layout/Layout-arrange.svg'
                  )}
                />
              </span>
              <span className="menu-text">Виджеты</span>
            </NavLink>
          </li>
        </UserRolesAccessComponent>
        <UserRolesAccessComponent
          access={[
            userRoles.root.type,
            userRoles.superAdmin.type,
            userRoles.partnerManager.type,
            userRoles.productTemplateOperator.type,
            userRoles.companyOperator.type,
          ]}
        >
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/error',
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/admin/reports/checkouts"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Communication/Clipboard-check.svg'
                  )}
                />
              </span>
              <span className="menu-text">Отчеты</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Отчеты</span>
                  </span>
                </li>
                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/reports/checkouts'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/admin/reports/checkouts"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        Отчет по анкетам оформления
                      </span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/admin/reports/checkout-for-operators'
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/admin/reports/checkout-for-operators"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Реестр анкет оформления</span>
                  </NavLink>
                </li>
                <UserRolesAccessComponent
                  access={[userRoles.root.type, userRoles.superAdmin.type]}
                >
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin/reports/queries'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin/reports/queries">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        Отчет по анкетам предложения
                      </span>
                    </NavLink>
                  </li>
                </UserRolesAccessComponent>
              </ul>
            </div>
          </li>
        </UserRolesAccessComponent>
      </ul>
    </>
  )
}
