import axios from 'axios'

const staticPageUrl = '/admin/products'
const staticIsShow = '/admin/query/coverages'

export function getProductCoverages(product_id, page = 1) {
  return axios.get(`${staticPageUrl}/${product_id}/coverages?page=${page}`)
}

export function deleteProductCoverage(product_id, id) {
  return axios.delete(`${staticPageUrl}/${product_id}/coverages/${id}`, {
    data: {},
  })
}

export function blockProductCoverage(product_id, id) {
  return axios.patch(`${staticPageUrl}/${product_id}/coverages/${id}/disable`)
}

export function unBlockProductCoverage(product_id, id) {
  return axios.patch(`${staticPageUrl}/${product_id}/coverages/${id}/enable`)
}

export function createProductCoverage(product_id, data) {
  return axios.post(`${staticPageUrl}/${product_id}/coverages`, data)
}

export function editProductCoverage(product_id, id, data) {
  return axios.put(`${staticPageUrl}/${product_id}/coverages/${id}`, data)
}

export function showProductCoverage(product_id, id) {
  return axios.get(`${staticPageUrl}/${product_id}/coverages/${id}`)
}

export function showAllQueryProductCoverage(product_id, coverage_id) {
  return axios.get(
    `${staticIsShow}?product_id=${product_id}&coverage_id=${coverage_id}`
  )
}

export function createQueryProductCoverage(data) {
  return axios.post(`${staticIsShow}`, data)
}

export function editQueryProductCoverage(id, data) {
  return axios.put(`${staticIsShow}/${id}`, data)
}
