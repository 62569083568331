import React, { useMemo } from 'react'
import { useHtmlClassService } from '../../_core/MetronicLayout'

export function Footer() {
  const uiService = useHtmlClassService()

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true),
    }
  }, [uiService])

  return (
    <div
      className={`footer d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      ></div>
    </div>
  )
}
