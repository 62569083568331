import axios from 'axios'

const path = '/admin/companies'

export function getProductsCompany(companyId, page = 1) {
  return axios.get(`${path}/${companyId}/products?page=${page}`)
}

export function getProductCompanyList(companyId) {
  return axios.get(`${path}/${companyId}/products-list`)
}

export function deleteProductCompany(companyId, productId) {
  return axios.delete(`${path}/${companyId}/products/${productId}`)
}

export function unBlockProductCompany(companyId, productId) {
  return axios.patch(`${path}/${companyId}/products/${productId}/publish`)
}

export function editProductCompany(companyId, productId, data) {
  return axios.put(`${path}/${companyId}/products/${productId}`, data)
}

export function showProductCompanyId(companyId, productId) {
  return axios.get(`${path}/${companyId}/products/${productId}`)
}

export function createProductCompany(companyId, data) {
  return axios.post(`${path}/${companyId}/products`, data)
}

export function enableBestCompanyProduct(companyId, productId) {
  return axios.patch(`${path}/${companyId}/products/${productId}/enable-best`)
}

export function disableBestCompanyProduct(companyId, productId) {
  return axios.patch(`${path}/${companyId}/products/${productId}/disable-best`)
}

export function changeCodeCompanyProduct(companyId, productId, data) {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/change-code`,
    data
  )
}

// @Seinyan
export function changePaymentCompanyProduct(companyId, productId, data) {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/payment/change-payment`,
    data
  )
}

export function blockProductCompany(companyId, productId) {
  return axios.patch(`${path}/${companyId}/products/${productId}/unpublish`)
}

export function attachCompanyProductContract(companyId, productId, data) {
  return axios.post(
    `${path}/${companyId}/products/${productId}/contracts`,
    data
  )
}

export function detachCompanyProductContract(companyId, productId) {
  return axios.delete(`${path}/${companyId}/products/${productId}/contracts`)
}

export function getCompanyProductContractNumber(companyId, productId) {
  return axios.get(`${path}/${companyId}/products/${productId}/contract-number`)
}

export function editCompanyProductContractNumber(companyId, productId, data) {
  return axios.put(
    `${path}/${companyId}/products/${productId}/contract-number`,
    data
  )
}

export const getProductsShortcurts = (productId) =>
  axios.get(`/admin/products/${productId}/shortcuts`)

export const getProductsShortcurtsFunctions = (productId) =>
  axios.get(`/admin/products/${productId}/shortcuts/functions`)

export const changePremMinCompanyProduct = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/change-prem-min`,
    data
  )
}
export const clearPremMinCompanyProduct = (companyId, productId) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/clear-prem-min`
  )
}

export const changeConfigMailerBrokerInbox = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/mailer/change-broker-inbox`,
    data
  )
}

export const changeConfigMailerCompanyInbox = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/mailer/change-company-inbox`,
    data
  )
}

export const changeConfigMailerCompanyOutbox = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/mailer/change-company-outbox`,
    data
  )
}

export const changeConfigMailerCompanyLetter = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/mailer/change-company-letter`,
    data
  )
}

export const disableConfigMailerClientRecipient = (companyId, productId) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/mailer/disable-client-recipient`
  )
}

export const enableConfigMailerClientRecipient = (companyId, productId) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/mailer/enable-client-recipient`
  )
}

export const sendEmailToBroker = (companyId, productId) => {
  return axios.post(
    `${path}/${companyId}/products/${productId}/config/mailer/test-broker-inbox`
  )
}

export const sendEmailToCompany = (companyId, productId) => {
  return axios.post(
    `${path}/${companyId}/products/${productId}/config/mailer/test-company-inbox`
  )
}

export const sendEmailFromOutbox = (companyId, productId) => {
  return axios.post(
    `${path}/${companyId}/products/${productId}/config/mailer/test-company-outbox`
  )
}

export const changeConfigContractForm = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/api-gateway/change-contract-form`,
    data
  )
}

export const changeConfigPaymentConfig = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/api-gateway/change-payment`,
    data
  )
}

export const changeConfigPaymentCheckConfig = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/api-gateway/change-payment-check`,
    data
  )
}

export const changeConfigConfirmationConfig = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/api-gateway/change-confirmation`,
    data
  )
}

export const changeConfigCalculation = (companyId, productId, data) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/api-gateway/change-calculation`,
    data
  )
}

export const changeConfigApiGatewayDisable = (companyId, productId) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/api-gateway/disable`
  )
}

export const changeConfigApiGatewayEnable = (companyId, productId) => {
  return axios.patch(
    `${path}/${companyId}/products/${productId}/config/api-gateway/enable`
  )
}
