import { put, takeLatest, takeEvery } from 'redux-saga/effects' // select
import {
  getCategoryBlog,
  getCategoryBlogId,
  getCategoryBlogById,
} from './blogCrud'

export const actionTypes = {
  getListBlogMainStart: 'GET_BLOG_LIST_MAIN_START_SITE',
  getListBlogMainSuccess: 'GET_BLOG_LIST_MAIN_SUCCESS_SITE',
  getListBlogMainError: 'GET_BLOG_LIST_MAIN_ERROR_SITE',
  getIdBlogStart: 'GET_ID_BLOG_START_SITE',
  getMoreIdBlogSuccess: 'GET_ID_BLOG_SUCCESS_SITE',
  getIdBlogError: 'GET_ID_BLOG_ERROR_SITE',
  loadedIdBlogStart: 'GET_BLOG_PAGES_LOAD_START',
  loadedIdBlogSuccess: 'GET_BLOG_PAGES_LOAD_SUCCESS',
  loadedIdBlogError: 'GET_BLOG_PAGES_LOAD_ERROR',
  loadedPageMainBlogStart: 'GET_BLOG_PAGES_MAIN_LOAD_START',
  loadedMainBlogSuccess: 'GET_BLOG_PAGES_MAIN_LOAD_SUCCESS',
  loadedMainBlogError: 'GET_BLOG_PAGES_MAIN_LOAD_ERROR',
  getInitialCategory: 'SET_INITIAL_CATEGORIES_BLOG',
  getMainIdCategoryStart: 'GET_BLOG_USEFUL_LOAD_START',
  getMainIdCategorySuccess: 'GET_BLOG_USEFUL_LOAD_SUCCESS',
  getMainIdCategoryError: 'GET_BLOG_USEFUL_LOAD_ERROR',
  getIdArticleStart: 'GET_BLOG_ID_START',
  getIdArticleSuccess: 'GET_BLOG_ID_SUCCESS',
  getIdArticleError: 'GET_BLOG_ID_ERROR',
}

const initialState = {
  main: {},
  poleznoZnat: {},
  category: {},
  // => for 1 article
  article: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getIdArticleStart: {
      return {
        ...state,
        article: {
          loader: true,
          error: false,
        },
      }
    }
    case actionTypes.getIdArticleError: {
      return {
        ...state,
        article: {
          loader: false,
          error: action.payload,
        },
      }
    }
    case actionTypes.getIdArticleSuccess: {
      return {
        ...state,
        article: {
          loader: false,
          ...action.payload,
        },
      }
    }
    case actionTypes.getMainIdCategorySuccess: {
      return {
        ...state,
        [action.blog]: {
          loader: false,
          ...action.payload,
        },
      }
    }
    case actionTypes.getMainIdCategoryError: {
      return {
        ...state,
        [action.blog]: {
          loader: false,
          ...state[action.blog],
          error: true,
        },
      }
    }
    case actionTypes.getMainIdCategoryStart:
      return {
        ...state,
        [action.blog]: {
          data: [],
          loader: true,
          error: false,
        },
      }
    case actionTypes.getInitialCategory: {
      return {
        ...state,
        category: {
          data: [],
        },
      }
    }
    case actionTypes.getListBlogMainSuccess: {
      return {
        ...state,
        main: {
          loader: false,
          error: false,
          ...action.payload,
        },
      }
    }
    case actionTypes.getListBlogMainStart: {
      return {
        ...state,
        main: {
          ...state.main,
          loader: true,
          error: false,
        },
      }
    }
    case actionTypes.getListBlogMainError: {
      return {
        ...state,
        main: {
          ...state.main,
          loader: false,
          error: true,
        },
      }
    }
    case actionTypes.loadedMainBlogSuccess: {
      return {
        ...state,
        main: {
          loader: false,
          data: [...state.main.data, ...action.payload.data],
          meta: action.payload.meta,
        },
      }
    }
    case actionTypes.loadedPageMainBlogStart:
      return {
        ...state,
        main: {
          ...state.main,
          loader: true,
          error: false,
        },
      }
    case actionTypes.loadedMainBlogError:
      return {
        ...state,
        main: {
          ...state.main,
          loader: false,
          error: true,
        },
      }
    case actionTypes.loadedIdBlogStart:
    case actionTypes.getIdBlogStart: {
      return {
        ...state,
        category: {
          ...state.category,
          name: action.blog,
          loader: true,
          error: false,
        },
      }
    }
    case actionTypes.getIdBlogError: {
      return {
        ...state,
        category: {
          ...state.category,
          name: action.blog,
          loader: false,
          error: true,
        },
      }
    }
    case actionTypes.getMoreIdBlogSuccess: {
      return {
        ...state,
        category: {
          ...state.category,
          loader: false,
          ...action.payload,
        },
      }
    }
    case actionTypes.loadedIdBlogSuccess: {
      return {
        ...state,
        category: {
          ...state.category,
          loader: false,
          data: [...state.category.data, ...action.payload.data],
          meta: action.payload.meta,
        },
      }
    }
    case actionTypes.loadedIdBlogError: {
      return {
        ...state,
        category: {
          ...state.category,
          loader: false,
          error: true,
        },
      }
    }
    default:
      return state
  }
}

export const loadedMainBlogError = () => ({
  type: actionTypes.loadedMainBlogError,
})

export const loadedIdBlogError = () => ({
  type: actionTypes.loadedIdBlogError,
})

export const getIdBlogError = () => ({
  type: actionTypes.getIdBlogError,
})

export const getInitialCategory = () => ({
  type: actionTypes.getInitialCategory,
})
export const loadedMainBlogSuccess = (data, blog) => ({
  type: actionTypes.loadedMainBlogSuccess,
  payload: data,
  blog,
})

export const loadedIdBlogStart = (blog, page) => ({
  type: actionTypes.loadedIdBlogStart,
  blog,
  page,
})

export const loadedIdBlogSuccess = (data, blog) => ({
  type: actionTypes.loadedIdBlogSuccess,
  payload: data,
  blog,
})

export const loadedPageMainBlogStart = (page) => ({
  type: actionTypes.loadedPageMainBlogStart,
  page,
})

export const getListBlogMainStart = (page = 1) => ({
  type: actionTypes.getListBlogMainStart,
  payload: page,
})

export const getListBlogMainError = () => ({
  type: actionTypes.getListBlogMainError,
})

export const getListBlogMainSuccess = (data) => ({
  type: actionTypes.getListBlogMainSuccess,
  payload: data,
})

export const getIdBlogStart = (blog, page = 1) => ({
  type: actionTypes.getIdBlogStart,
  payload: page,
  blog,
})

export const getMoreIdBlogSuccess = (data, blog) => ({
  type: actionTypes.getMoreIdBlogSuccess,
  payload: data,
  blog,
})

export const getMainIdCategoryStart = (blog, searchBy = 'bySlug') => ({
  type: actionTypes.getMainIdCategoryStart,
  blog,
  searchBy,
})

export const getMainIdCategoryError = (blog) => ({
  type: actionTypes.getMainIdCategoryError,
  blog,
})

export const getMainIdCategorySuccess = (blog, data) => ({
  type: actionTypes.getMainIdCategorySuccess,
  blog,
  payload: data,
})

export const getIdArticleStart = (id) => ({
  type: actionTypes.getIdArticleStart,
  id,
})

export const getIdArticleSuccess = (data) => ({
  type: actionTypes.getIdArticleSuccess,
  payload: data,
})

export const getIdArticleError = (data) => ({
  type: actionTypes.getIdArticleError,
  payload: data,
})

export function* saga() {
  yield takeLatest(actionTypes.getIdArticleStart, function* (e) {
    try {
      const { data } = yield getCategoryBlogId(e.id)

      yield put(getIdArticleSuccess(data))
    } catch (e) {
      if (e?.response?.status === 409) {
        yield put(getIdArticleError('Новость не была найдена!'))
      } else {
        yield put(getIdArticleError('Произошла ошибка!'))
      }
    }
  })
  yield takeLatest(actionTypes.loadedPageMainBlogStart, function* (e) {
    try {
      const { data } = yield getCategoryBlog('', e.page)

      yield put(loadedMainBlogSuccess(data))
    } catch (e) {
      yield put(loadedMainBlogError())
    }
  })

  yield takeLatest(actionTypes.getListBlogMainStart, function* (e) {
    try {
      const { data } = yield getCategoryBlog('')

      yield put(getListBlogMainSuccess(data))
    } catch (e) {
      yield put(getListBlogMainError())
    }
  })

  yield takeEvery(actionTypes.getMainIdCategoryStart, function* (e) {
    try {
      let response
      switch (e.searchBy) {
        case 'byId':
          response = yield getCategoryBlogById(e.blog)
          break
        case 'bySlug':
          response = yield getCategoryBlog(e.blog)
          break
      }

      const { data } = response

      yield put(getMainIdCategorySuccess(e.blog, data))
    } catch (e) {
      yield put(getMainIdCategoryError(e.blog))
    }
  })

  yield takeEvery(actionTypes.getIdBlogStart, function* (e) {
    try {
      const { data } = yield getCategoryBlog(e.blog)
      yield put(getMoreIdBlogSuccess(data, e.blog))
    } catch (e) {
      yield put(getIdBlogError())
    }
  })
  yield takeEvery(actionTypes.loadedIdBlogStart, function* (e) {
    try {
      const { data } = yield getCategoryBlog(e.blog, e.page)
      yield put(loadedIdBlogSuccess(data, e.blog))
    } catch (e) {
      yield put(loadedIdBlogError())
    }
  })
}
