import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deletePromocodes,
  getPromocodess,
  draftPromocode,
  activePromocode,
  createPromocodes,
  editPromocodes,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  getPromocodesDataStart: 'GET_PROMOCODES_DATA_START',
  getPromocodesDataSuccess: 'GET_PROMOCODES_DATA_SUCCESS',
  deletePromocode: 'DELETE_PROMOCODE',
  blockPromocode: 'BLOCK_PROMOCODE',
  unBlockPromocode: 'UN_BLOCK_PROMOCODE',
  createPromocode: 'CREATE_PROMOCODE',
  editPromocode: 'EDIT_PROMOCODE',
  loadingStop: 'LOADING_STOP_PROMOCODE',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getPromocodesDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getPromocodesDataStart: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.unBlockPromocode: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.blockPromocode: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export const getPromocodesActionStart = (template_id, page, typeCrud) => ({
  type: actionTypes.getPromocodesDataStart,
  template_id,
  page,
  typeCrud,
})

export const getPromocodesActionSuccess = (data) => ({
  type: actionTypes.getPromocodesDataSuccess,
  payload: data,
})

export const deletePromocodeAction = (template_id, id) => ({
  type: actionTypes.deletePromocode,
  template_id,
  id,
})

export const blockPromocodeAction = (template_id, id) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.blockPromocode,
      template_id,
      id,
      resolve,
    })
  })
}
export const unBlockPromocodeAction = (template_id, id) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.unBlockPromocode,
      template_id,
      id,
      resolve,
    })
  })
}

export const createPromocodeAction = (template_id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.createPromocode,
      template_id,
      data,
      resolve,
      reject,
    })
  })
}

export const editPromocodeAction = (template_id, id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.editPromocode,
      template_id,
      id,
      data,
      resolve,
      reject,
    })
  })
}

export const loadingStopAction = () => ({
  type: actionTypes.loadingStop,
})

export function* saga() {
  yield takeLatest(actionTypes.getPromocodesDataStart, function* (e) {
    try {
      const { data } = yield getPromocodess(e.template_id, e.page, e.typeCrud)
      yield put(
        getPromocodesActionSuccess({
          data: [],
          meta: {
            pager: {
              count: 0,
              total: 0,
              per_page: 0,
              page: 0,
              pages: 0,
            },
          },
        })
      )
      yield put(getPromocodesActionSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.blockPromocode, function* (e) {
    try {
      const {
        adminPanel: { promocodes },
      } = yield select()
      yield draftPromocode(e.template_id, e.id)

      yield put(getPromocodesActionStart(promocodes.meta.pager.page))
    } catch (e) {
      const {
        adminPanel: { promocodes },
      } = yield select()
      errorParser(e.response)
      yield put(getPromocodesActionStart(promocodes.meta.pager.page))
    } finally {
      yield put(loadingStopAction())
      e.resolve()
    }
  })
  yield takeLatest(actionTypes.unBlockPromocode, function* (e) {
    try {
      const {
        adminPanel: { promocodes },
      } = yield select()
      yield activePromocode(e.template_id, e.id)

      yield put(getPromocodesActionStart(promocodes.meta.pager.page))
    } catch (e) {
      const {
        adminPanel: { promocodes },
      } = yield select()
      errorParser(e.response)
      yield put(getPromocodesActionStart(promocodes.meta.pager.page))
    } finally {
      yield put(loadingStopAction())
      e.resolve()
    }
  })

  yield takeLatest(actionTypes.deletePromocode, function* (e) {
    try {
      const {
        adminPanel: {
          promocodes: { meta },
        },
      } = yield select()

      yield deletePromocodes(e.template_id, e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Промокод успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(getPromocodesActionStart(page))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createPromocode, function* ({
    template_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createPromocodes(template_id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editPromocode, function* ({
    template_id,
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editPromocodes(template_id, id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
