import axios from 'axios'

const staticPageUrl = '/admin/products'

export function getProductDirectoriesInformations(
  product_id,
  page = 1,
  type = 'information'
) {
  return axios.get(
    `${staticPageUrl}/${product_id}/dictionaries?page=${page}&type=${type}`
  )
}

export function deleteProductDirectoriesInformation(product_id, directory_id) {
  return axios.delete(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}`,
    {
      data: {},
    }
  )
}

export function blockProductDirectoriesInformation(product_id, directory_id) {
  return axios.patch(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/disable`
  )
}

export function unBlockProductDirectoriesInformation(product_id, directory_id) {
  return axios.patch(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/enable`
  )
}

export function createProductDirectoriesInformation(product_id, data) {
  return axios.post(`${staticPageUrl}/${product_id}/dictionaries`, data)
}

export function editProductDirectoriesInformation(
  product_id,
  directory_id,
  data
) {
  return axios.put(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}`,
    data
  )
}

export function showProductDirectoriesInformation(product_id, directory_id) {
  return axios.get(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}`
  )
}

export function showProductSpecializations(page, product_id, directory_id) {
  return axios.get(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/values?page=${page}`
  )
}

export function createProductSpecializations(product_id, directory_id, data) {
  return axios.post(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/values`,
    data
  )
}

export function editProductSpecializations(product_id, directory_id, id, data) {
  return axios.put(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/values/${id}`,
    data
  )
}

export function deleteProductSpecializations(product_id, directory_id, id) {
  return axios.delete(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/values/${id}`
  )
}
