import { put, takeLatest } from 'redux-saga/effects' // select
import { getIndustryBySlug } from './industryCrud'

export const actionTypes = {
  getIndustriesMainStart: 'GET_INDUSTRIES_ID_START_SITE',
  getIndustriesMainSuccess: 'GET_INDUSTRIES_ID_SUCCESS_SITE',
  getIndustriesMainError: 'GET_INDUSTRIES_ID_ERROR_SITE',
}

const initialState = {
  industries: {
    data: {
      id: '',
      title: '',
      h_one: '',
      slug: '',
      icon: '',
      main_image: '',
      description: '',
      blocks: [],
      cards: [],
    },
    loader: false,
    error: false,
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getIndustriesMainSuccess: {
      return {
        ...state,
        industries: {
          loader: false,
          error: false,
          data: action.payload,
        },
      }
    }
    case actionTypes.getIndustriesMainStart: {
      return {
        ...state,
        industries: {
          data: {
            id: '',
            title: '',
            h_one: '',
            slug: '',
            icon: '',
            main_image: '',
            description: '',
            blocks: [],
            cards: [],
          },
          loader: true,
          error: false,
        },
      }
    }
    case actionTypes.getIndustriesMainError: {
      return {
        ...state,
        industries: {
          data: {
            id: '',
            title: '',
            h_one: '',
            slug: '',
            icon: '',
            main_image: '',
            description: '',
            blocks: [],
            cards: [],
          },
          loader: false,
          error: action.payload,
        },
      }
    }
    default:
      return state
  }
}

export const getIndustriesMainStart = (slug) => ({
  type: actionTypes.getIndustriesMainStart,
  slug: slug,
})

export const getIndustriesMainError = (error) => ({
  type: actionTypes.getIndustriesMainError,
  payload: error,
})

export const getIndustriesMainSuccess = (data) => ({
  type: actionTypes.getIndustriesMainSuccess,
  payload: data,
})

export function* saga() {
  yield takeLatest(actionTypes.getIndustriesMainStart, function* (e) {
    try {
      const { data } = yield getIndustryBySlug(e.slug)

      yield put(getIndustriesMainSuccess(data))
    } catch (e) {
      if (e?.response?.status === 409) {
        yield put(getIndustriesMainError('Услуга не была найдена!'))
      } else {
        yield put(getIndustriesMainError('Произошла ошибка!'))
      }
    }
  })
}
