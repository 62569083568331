import axios from 'axios'

const staticPageUrl = '/admin/products'

export function getProductDirectoriesBlockings(
  product_id,
  page = 1,
  type = 'blocking'
) {
  return axios.get(
    `${staticPageUrl}/${product_id}/dictionaries?page=${page}&type=${type}`
  )
}

export function deleteProductDirectoriesBlocking(product_id, directory_id) {
  return axios.delete(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}`,
    {
      data: {},
    }
  )
}

export function blockProductDirectoriesBlocking(product_id, directory_id) {
  return axios.patch(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/disable`
  )
}

export function unBlockProductDirectoriesBlocking(product_id, directory_id) {
  return axios.patch(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/enable`
  )
}

export function createProductDirectoriesBlocking(product_id, data) {
  return axios.post(`${staticPageUrl}/${product_id}/dictionaries`, data)
}

export function editProductDirectoriesBlocking(product_id, directory_id, data) {
  return axios.put(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}`,
    data
  )
}

export function showProductDirectoriesBlocking(product_id, directory_id) {
  return axios.get(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}`
  )
}

export function showProductSpecializations(page, product_id, directory_id) {
  return axios.get(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/values?page=${page}`
  )
}

export function createProductSpecializations(product_id, directory_id, data) {
  return axios.post(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/values`,
    data
  )
}

export function editProductSpecializations(product_id, directory_id, id, data) {
  return axios.put(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/values/${id}`,
    data
  )
}

export function deleteProductSpecializations(product_id, directory_id, id) {
  return axios.delete(
    `${staticPageUrl}/${product_id}/dictionaries/${directory_id}/values/${id}`
  )
}

export const createQueryProductDictionary = (data) => {
  return axios.post(`/admin/query/dictionaries`, data)
}

export const putQueryProductDictionary = (id, data) => {
  return axios.put(`/admin/query/dictionaries/${id}`, data)
}

export const showAllQueryProductDictionary = (product_id, dictionary_id) => {
  return axios.get(
    `/admin/query/dictionaries?product_id=${product_id}&dictionary_id=${dictionary_id}`
  )
}
