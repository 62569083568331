import axios from 'axios'

const seo_url = '/admin/seo'
const seo_url_url = '/seo'

export function createSeo(data) {
  return axios.post(`${seo_url}`, data)
}

export function editSeo(id, data) {
  return axios.put(`${seo_url}/${id}`, data)
}

export function deleteSeo(id) {
  return axios.delete(`${seo_url}/${id}`)
}

export function showSeo(id) {
  return axios.get(`${seo_url}/${id}`)
}

export const showSeoUrl = (url) => {
  return axios.get(`${seo_url_url}`, {
    params: {
      identifier: url,
    },
  })
}
