import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deleteProductDirectoriesInformation,
  getProductDirectoriesInformations,
  blockProductDirectoriesInformation,
  unBlockProductDirectoriesInformation,
  createProductDirectoriesInformation,
  editProductDirectoriesInformation,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../../helpers/errorsParse'

export const actionTypes = {
  getDirectoriesInformationsDataStart:
    'GET_DIRECTORIES_INFORMATIONS_DATA_START',
  getDirectoriesInformationsDataSuccess:
    'GET_DIRECTORIES_INFORMATIONS_DATA_SUCCESS',
  deleteDirectoriesInformation: 'DELETE_DIRECTORIES_INFORMATION',
  blockDirectoriesInformation: 'BLOCK_DIRECTORIES_INFORMATION',
  unBlockDirectoriesInformation: 'UN_BLOCK_DIRECTORIES_INFORMATION',
  createDirectoriesInformation: 'CREATE_DIRECTORIES_INFORMATION',
  editDirectoriesInformation: 'EDIT_DIRECTORIES_INFORMATION',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getDirectoriesInformationsDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getDirectoriesInformationsDataStart: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.unBlockDirectoriesInformation: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.blockDirectoriesInformation: {
      return {
        ...state,
        isLoading: true,
      }
    }
    default:
      return state
  }
}

export const getDirectoriesInformationsActionStart = (
  template_id,
  page,
  typeCrud
) => ({
  type: actionTypes.getDirectoriesInformationsDataStart,
  template_id,
  page,
  typeCrud,
})

export const getDirectoriesInformationsActionSuccess = (data) => ({
  type: actionTypes.getDirectoriesInformationsDataSuccess,
  payload: data,
})

export const deleteDirectoriesInformationAction = (template_id, id) => ({
  type: actionTypes.deleteDirectoriesInformation,
  template_id,
  id,
})

export const blockDirectoriesInformationAction = (template_id, id) => ({
  type: actionTypes.blockDirectoriesInformation,
  template_id,
  id,
})

export const unBlockDirectoriesInformationAction = (template_id, id) => ({
  type: actionTypes.unBlockDirectoriesInformation,
  template_id,
  id,
})

export const createDirectoriesInformationAction = (template_id, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.createDirectoriesInformation,
      template_id,
      data,
      resolve,
      reject,
    })
  })
}

export const editDirectoriesInformationAction = (template_id, id, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.editDirectoriesInformation,
      template_id,
      id,
      data,
      resolve,
      reject,
    })
  })
}

export function* saga() {
  yield takeLatest(actionTypes.getDirectoriesInformationsDataStart, function* (
    e
  ) {
    try {
      const { data } = yield getProductDirectoriesInformations(
        e.template_id,
        e.page,
        e.typeCrud
      )
      yield put(getDirectoriesInformationsActionSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.blockDirectoriesInformation, function* (e) {
    try {
      const {
        adminPanel: { directoriesInformation },
      } = yield select()
      yield blockProductDirectoriesInformation(e.template_id, e.id)

      yield put(
        getDirectoriesInformationsActionStart(
          e.template_id,
          directoriesInformation.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.unBlockDirectoriesInformation, function* (e) {
    try {
      const {
        adminPanel: { directoriesInformation },
      } = yield select()
      yield unBlockProductDirectoriesInformation(e.template_id, e.id)

      yield put(
        getDirectoriesInformationsActionStart(
          e.template_id,
          directoriesInformation.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.deleteDirectoriesInformation, function* (e) {
    try {
      const {
        adminPanel: {
          directoriesInformation: { meta },
        },
      } = yield select()

      yield deleteProductDirectoriesInformation(e.template_id, e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Справочник успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(getDirectoriesInformationsActionStart(e.template_id, page))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createDirectoriesInformation, function* ({
    template_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createProductDirectoriesInformation(
        template_id,
        data
      )
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editDirectoriesInformation, function* ({
    template_id,
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editProductDirectoriesInformation(
        template_id,
        id,
        data
      )
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
