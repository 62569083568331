import React, { Component } from 'react'
import Spinner from '../../../../../catalog_app/components/Spinner'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import { customStylesFormInputParty } from '../../../formioSelect/select'
import { getDadataParty } from '../../redux/crud'
import { CODE_PARTY_CODE_MASK, formioDataTypes } from '../../../../../constants'
import MaskedInput from 'react-text-mask'
import {
  setContainerStyle,
  setLabelStyle,
  setWrapperStyle,
  setContainerValueStyle,
} from '../../../SetStylesFormio/styles'

export default class DadataParty extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productLoading: false,
      disableForm: false,
      checkedValue: false,
      party: null,
      searchAddress: null,

      name: '',
      address: '',
    }
    this._isMounted = true
    this._isDebounce = null
  }

  componentDidMount() {
    this._isMounted = true

    setTimeout(() => {
      /* есть особеность в самом формио, незнаю почему но нужна задержка, может помочь даже задержка в 1 милисекунд */
      const {
        component: { key },
        _data,
      } = this.props
      this.setState((prevState) => {
        return {
          ...prevState,
          ..._data[`${key}`]?.submit,
        }
      })
    }, 100)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _handleChangeChecked = (e) => {
    this.setState({
      checkedValue: e,
    })
  }

  _searchParty = async (e) => {
    clearTimeout(this._isDebounce)
    const promise = new Promise((resolve) => {
      this._isDebounce = setTimeout(() => {
        const search_numbers = e.replace(/\D/g, '')
        getDadataParty(search_numbers)
          .then(({ data }) => {
            resolve(data.data)
            return data.data
          })
          .catch((er) => {
            resolve([])
          })
      }, 1000)
    })
    return promise
  }

  onChangeValue = (type, e) => {
    // 7707 083 893
    // const { address, name } = this.state;
    this.setState(
      {
        [type]: e,
        address: e['address']['value'],
        name: e['name']['full'],
      },
      () => {
        const { party, name, address } = this.state
        this.props.onChange({
          submit: {
            party: party,
            name: name,
            address: address,
            formioDataType: formioDataTypes.dadataParty,
          },
        })
      }
    )
  }

  onChangeInput = (type, e) => {
    this.setState(
      {
        [type]: e,
      },
      () => {
        const { party, name, address } = this.state
        this.props.onChange({
          submit: {
            party: party,
            name: name,
            address: address,
            formioDataType: formioDataTypes.dadataParty,
          },
        })
      }
    )
  }

  render() {
    const { component } = this.props
    const { address, party, name } = this.state

    if (this.state.productLoading) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '200px' }}
        >
          <Spinner animation="border" variant="primary">
            <span className="sr-only">Загрузка...</span>
          </Spinner>
        </div>
      )
    }

    return (
      <div className="dadataWrapper">
        <div
          className="container-fields-wrapper"
          style={setContainerStyle(component)}
        >
          <div className="field-wrapper" style={setWrapperStyle(component)}>
            <label style={setLabelStyle(component)} htmlFor={'party'}>
              ИНН
            </label>
          </div>
          <div style={setContainerValueStyle(component)}>
            <AsyncSelect
              styles={customStylesFormInputParty}
              className={'dadataInputContainer'}
              name="party"
              defaultOptions={[]}
              loadingMessage={() => 'Идет загрузка...'}
              placeholder="_&nbsp;_&nbsp;_&nbsp;_&nbsp;&nbsp;_&nbsp;_&nbsp;_&nbsp;&nbsp;_&nbsp;_&nbsp;_"
              noOptionsMessage={(e) => 'Список пуст'}
              isSearchable={true}
              value={party}
              onChange={(e) => {
                this.onChangeValue('party', e)
              }}
              getOptionValue={(item) => item}
              getOptionLabel={(option) => `${option['inn']}`}
              loadOptions={async (e) => {
                return await this._searchParty(e)
              }}
              components={{
                DropdownIndicator: null,
                Option: (props) => {
                  return (
                    <components.Option {...props}>
                      {`${props.data.inn} - ${props.data.name.full}`}
                    </components.Option>
                  )
                },
                Input: (inputProps) => {
                  return (
                    <MaskedInput
                      onBlur={inputProps.onBlur}
                      onFocus={inputProps.onFocus}
                      onChange={inputProps.onChange}
                      id={inputProps.id}
                      value={inputProps.value}
                      style={{
                        letterSpacing: '-1px',
                        width: '180px',
                        padding: '0 26px 0 26px',
                      }}
                      mask={CODE_PARTY_CODE_MASK}
                      placeholder={component.placeholder}
                      className="form-control"
                      render={(textMaskRef, props) => (
                        <input
                          {...props}
                          ref={(node) => {
                            textMaskRef(node) // Keep this so the component can still function
                            inputProps.innerRef(node)
                          }}
                        />
                      )}
                    />
                  )
                },
              }}
            />
            {/* {false ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">error</div>
              </div>
            ) : null} */}
          </div>
        </div>

        <div
          className="container-fields-wrapper dadataInputContainer"
          style={setContainerStyle(component)}
        >
          <div className="field-wrapper" style={setWrapperStyle(component)}>
            <label style={setLabelStyle(component)} htmlFor={'name'}>
              Наименование
            </label>
          </div>
          <div style={setContainerValueStyle(component)}>
            <input
              name={'name'}
              type="text"
              value={name}
              placeholder="Укажите название"
              className="input-field form-control"
              onChange={(e) => this.onChangeInput('name', e.target.value)}
            />
            {/* {false ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">error</div>
              </div>
            ) : null} */}
          </div>
        </div>

        <div
          className="container-fields-wrapper dadataInputContainer"
          style={setContainerStyle(component)}
        >
          <div className="field-wrapper" style={setWrapperStyle(component)}>
            <label style={setLabelStyle(component)} htmlFor={'address'}>
              Фактический адрес
            </label>
          </div>
          <div style={setContainerValueStyle(component)}>
            <input
              name={'address'}
              type="text"
              placeholder="Укажите адрес"
              className="input-field form-control"
              value={address}
              onChange={(e) => this.onChangeInput('address', e.target.value)}
            />
            {/* {false ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">error</div>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    )
  }
}
