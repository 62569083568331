import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deleteProductDirectoriesBlocking,
  getProductDirectoriesBlockings,
  blockProductDirectoriesBlocking,
  unBlockProductDirectoriesBlocking,
  createProductDirectoriesBlocking,
  editProductDirectoriesBlocking,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../../helpers/errorsParse'

export const actionTypes = {
  getDirectoriesBlockingsDataStart: 'GET_DIRECTORIES_BLOCKINGS_DATA_START',
  getDirectoriesBlockingsDataSuccess: 'GET_DIRECTORIES_BLOCKINGS_DATA_SUCCESS',
  deleteDirectoriesBlocking: 'DELETE_DIRECTORIES_BLOCKING',
  blockDirectoriesBlocking: 'BLOCK_DIRECTORIES_BLOCKING',
  unBlockDirectoriesBlocking: 'UN_BLOCK_DIRECTORIES_BLOCKING',
  createDirectoriesBlocking: 'CREATE_DIRECTORIES_BLOCKING',
  editDirectoriesBlocking: 'EDIT_DIRECTORIES_BLOCKING',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getDirectoriesBlockingsDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getDirectoriesBlockingsDataStart: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.unBlockDirectoriesBlocking: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case actionTypes.blockDirectoriesBlocking: {
      return {
        ...state,
        isLoading: true,
      }
    }
    default:
      return state
  }
}

export const getDirectoriesBlockingsActionStart = (
  template_id,
  page,
  typeCrud
) => ({
  type: actionTypes.getDirectoriesBlockingsDataStart,
  template_id,
  page,
  typeCrud,
})

export const getDirectoriesBlockingsActionSuccess = (data) => ({
  type: actionTypes.getDirectoriesBlockingsDataSuccess,
  payload: data,
})

export const deleteDirectoriesBlockingAction = (template_id, id) => ({
  type: actionTypes.deleteDirectoriesBlocking,
  template_id,
  id,
})

export const blockDirectoriesBlockingAction = (template_id, id) => ({
  type: actionTypes.blockDirectoriesBlocking,
  template_id,
  id,
})

export const unBlockDirectoriesBlockingAction = (template_id, id) => ({
  type: actionTypes.unBlockDirectoriesBlocking,
  template_id,
  id,
})

export const createDirectoriesBlockingAction = (template_id, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.createDirectoriesBlocking,
      template_id,
      data,
      resolve,
      reject,
    })
  })
}

export const editDirectoriesBlockingAction = (template_id, id, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.editDirectoriesBlocking,
      template_id,
      id,
      data,
      resolve,
      reject,
    })
  })
}

export function* saga() {
  yield takeLatest(actionTypes.getDirectoriesBlockingsDataStart, function* (e) {
    try {
      const { data } = yield getProductDirectoriesBlockings(
        e.template_id,
        e.page,
        e.typeCrud
      )
      yield put(getDirectoriesBlockingsActionSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.blockDirectoriesBlocking, function* (e) {
    try {
      const {
        adminPanel: { directoriesBlocking },
      } = yield select()
      yield blockProductDirectoriesBlocking(e.template_id, e.id)

      yield put(
        getDirectoriesBlockingsActionStart(
          e.template_id,
          directoriesBlocking.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.unBlockDirectoriesBlocking, function* (e) {
    try {
      const {
        adminPanel: { directoriesBlocking },
      } = yield select()
      yield unBlockProductDirectoriesBlocking(e.template_id, e.id)

      yield put(
        getDirectoriesBlockingsActionStart(
          e.template_id,
          directoriesBlocking.meta.pager.page
        )
      )
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.deleteDirectoriesBlocking, function* (e) {
    try {
      const {
        adminPanel: {
          directoriesBlocking: { meta },
        },
      } = yield select()

      yield deleteProductDirectoriesBlocking(e.template_id, e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Справочник успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(getDirectoriesBlockingsActionStart(e.template_id, page))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createDirectoriesBlocking, function* ({
    template_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createProductDirectoriesBlocking(template_id, data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editDirectoriesBlocking, function* ({
    template_id,
    id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editProductDirectoriesBlocking(
        template_id,
        id,
        data
      )
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
