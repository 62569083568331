import { put, takeLatest, select } from 'redux-saga/effects'
import {
  deleteGeneralDictionary,
  getGeneralDictionaries,
  createGeneralDictionary,
  editGeneralDictionary,
  getGeneralDictionariesValues,
  deleteGeneralDictionaryValue,
  createGeneralDictionaryValue,
  editGeneralDictionaryValue,
} from './crud'
import lodash from 'lodash'
import { errorParser } from '../../../helpers/errorsParse'

export const actionTypes = {
  getGeneralDictionaryDataStart: 'GET_GENERAL_DICTIONARY_DATA_START',
  getGeneralDictionaryDataSuccess: 'GET_GENERAL_DICTIONARY_DATA_SUCCESS',
  deleteGeneralDictionary: 'DELETE_GENERAL_DICTIONARY',
  createGeneralDictionary: 'CREATE_GENERAL_DICTIONARY',
  editGeneralDictionary: 'EDIT_GENERAL_DICTIONARY',
  loadingStop: 'LOADING_STOP',
  changeStatus: 'CHANGE_STATUS',
  getGeneralDictionaryValuesStart: 'GET_GENERAL_DICTIONARY_VALUES_DATA_START',
  getGeneralDictionaryValuesSuccess:
    'GET_GENERAL_DICTIONARY_VALUES_DATA_SUCCESS',
  deleteGeneralDictionaryValue: 'DELETE_GENERAL_DICTIONARY_VALUE',
  createGeneralDictionaryValue: 'CREATE_GENERAL_DICTIONARY_VALUE',
  editGeneralDictionaryValue: 'EDIT_GENERAL_DICTIONARY_VALUE',
}

const initialState = {
  data: [],
  meta: {
    pager: {
      count: 0,
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
    },
  },
  isLoading: true,
  error: false,
  disabled: false,
  globalLoading: true,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getGeneralDictionaryDataSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.getGeneralDictionaryDataStart: {
      return {
        ...state,
        data: initialState.data,
        meta: initialState.meta,
        isLoading: true,
      }
    }

    case actionTypes.getGeneralDictionaryValuesStart: {
      return {
        data: initialState.data,
        meta: initialState.meta,
        isLoading: true,
      }
    }
    case actionTypes.getGeneralDictionaryValuesSuccess: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      }
    }

    case actionTypes.loadingStop: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export const actions = {
  getGeneralDictionaryStart: (page) => ({
    type: actionTypes.getGeneralDictionaryDataStart,
    page,
  }),
  getGeneralDictionarySuccess: (data) => ({
    type: actionTypes.getGeneralDictionaryDataSuccess,
    payload: data,
  }),
  deleteGeneralDictionary: (id) => ({
    type: actionTypes.deleteGeneralDictionary,
    id,
  }),
  createGeneralDictionary: (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.createGeneralDictionary,
        data,
        resolve,
        reject,
      })
    })
  },
  editGeneralDictionary: (product_id, coverage_id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.editGeneralDictionary,
        product_id,
        coverage_id,
        data,
        resolve,
        reject,
      })
    })
  },
  loadingStop: () => ({
    type: actionTypes.loadingStop,
  }),

  getGeneralDictionaryValuesStart: (dictionary_id, page) => ({
    type: actionTypes.getGeneralDictionaryValuesStart,
    dictionary_id,
    page,
  }),
  getGeneralDictionaryValuesSuccess: (data) => ({
    type: actionTypes.getGeneralDictionaryValuesSuccess,
    payload: data,
  }),
  deleteGeneralDictionaryValue: (dictionary_id, id) => ({
    type: actionTypes.deleteGeneralDictionaryValue,
    dictionary_id,
    id,
  }),
  createGeneralDictionaryValue: (dictionary_id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.createGeneralDictionaryValue,
        dictionary_id,
        data,
        resolve,
        reject,
      })
    })
  },
  editGeneralDictionaryValue: (dictionary_id, id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.editGeneralDictionaryValue,
        dictionary_id,
        id,
        data,
        resolve,
        reject,
      })
    })
  },
}

export function* saga() {
  yield takeLatest(actionTypes.getGeneralDictionaryDataStart, function* (e) {
    try {
      const { data } = yield getGeneralDictionaries(e.page)

      yield put(actions.getGeneralDictionarySuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.deleteGeneralDictionary, function* (e) {
    try {
      const {
        adminPanel: {
          generalDictionary: { meta },
        },
      } = yield select()

      yield deleteGeneralDictionary(e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Справочник успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(actions.getGeneralDictionaryStart(page))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createGeneralDictionary, function* ({
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield createGeneralDictionary(data)
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.editGeneralDictionary, function* ({
    product_id,
    coverage_id,
    data,
    resolve,
    reject,
  }) {
    try {
      const response = yield editGeneralDictionary(
        product_id,
        coverage_id,
        data
      )
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })

  yield takeLatest(actionTypes.getGeneralDictionaryValuesStart, function* (e) {
    try {
      const { data } = yield getGeneralDictionariesValues(
        e.dictionary_id,
        e.page
      )

      yield put(actions.getGeneralDictionaryValuesSuccess(data))
    } catch (e) {
      errorParser(e.response)
    }
  })
  yield takeLatest(actionTypes.deleteGeneralDictionaryValue, function* (e) {
    try {
      const {
        adminPanel: {
          generalDictionary: { meta },
        },
      } = yield select()

      yield deleteGeneralDictionaryValue(e.dictionary_id, e.id)

      let page
      if (
        meta.pager.total % meta.pager.per_page === 1 &&
        meta.pager.total !== 1
      ) {
        page = meta.pager.page - 1
      } else {
        page = meta.pager.page
      }
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast) {
        addToast('Значение успешно удаленно!', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      yield put(actions.getGeneralDictionaryValuesStart(e.dictionary_id, page))
    } catch (e) {
      errorParser(e.response)
    }
  })

  yield takeLatest(actionTypes.createGeneralDictionaryValue, function* ({
    dictionary_id,
    data,
    resolve,
    reject,
  }) {
    const {
      adminPanel: { generalDictionary },
    } = yield select()

    try {
      const response = yield createGeneralDictionaryValue(dictionary_id, data)

      yield put(
        actions.getGeneralDictionaryValuesStart(
          dictionary_id,
          generalDictionary.meta.pager.page
        )
      )

      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
  yield takeLatest(actionTypes.editGeneralDictionaryValue, function* ({
    dictionary_id,
    id,
    data,
    resolve,
    reject,
  }) {
    const {
      adminPanel: { generalDictionary },
    } = yield select()
    try {
      const response = yield editGeneralDictionaryValue(dictionary_id, id, data)
      yield put(
        actions.getGeneralDictionaryValuesStart(
          dictionary_id,
          generalDictionary.meta.pager.page
        )
      )
      resolve(response)
    } catch (e) {
      errorParser(e.response)
      reject(e.response)
    }
  })
}
