import axios from 'axios'
const path = '/admin/companies'
const staticPageUrl = '/admin/products'

export function getProductDirectoriesRateds(
  page = 1,
  product_id,
  type = 'rated'
) {
  return axios.get(
    `${staticPageUrl}/${product_id}/dictionaries?page=${page}&type=${type}`
  )
}

export function getProductsCompanyDirectoriesRated(
  companyId,
  productId,
  dictionaryId,
  page = 1,
  id
) {
  return axios.get(
    `${path}/${companyId}/products/${productId}/dictionaries?page=${page}&DirectoriesRated_id=${dictionaryId}`
  )
}

export function deleteProductDirectoriesRated(
  companyId,
  productId,
  dictionaryId,
  id
) {
  return axios.delete(
    `${path}/${companyId}/products/${productId}/dictionaries/values/${id}`
  )
}

export function editProductDirectoriesRated(
  companyId,
  productId,
  dictionaryId,
  id,
  data
) {
  return axios.put(
    `${path}/${companyId}/products/${productId}/dictionaries/${dictionaryId}/values/${id}`,
    data
  )
}

export function showProductCompanyDirectoriesRatedId(
  companyId,
  productId,
  dictionaryId
) {
  return axios.get(
    `${path}/${companyId}/products/${productId}/dictionaries/${dictionaryId}`
  )
}

export function createProductDirectoriesRated(
  companyId,
  productId,
  directory_id,
  data
) {
  return axios.post(
    `${path}/${companyId}/products/${productId}/dictionaries/values`,
    data
  )
}

export function companyProductDictionaryValueShow(
  companyId,
  productId,
  dictionaryId,
  id
) {
  return axios.get(
    `${path}/${companyId}/products/${productId}/dictionaries/${dictionaryId}/values/${id}`
  )
}

export function productDictionaryValueShowAll(page, productId, dictionaryId) {
  return axios.get(
    `/admin/products/${productId}/dictionaries/${dictionaryId}/values?page=${page}`
  )
}

export function companyProductDictionaryValueShowAll(
  page,
  companyId,
  productId,
  dictionaryId
) {
  return axios.get(
    `${path}/${companyId}/products/${productId}/dictionaries/${dictionaryId}/values?page=${page}`
  )
}
