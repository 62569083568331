import React, { Component, createRef } from 'react'
import {
  getListDictionariesSite,
  getDictionaryValuesSite,
  getListRisks,
  getGeneralDictionaryValuesSite,
} from '../redux/crud'
import * as _ from 'lodash'
import { formioDataTypes } from '../../../../constants'
import { getAllData } from '../../../helpers/api'
import { conditionalObj } from '../conditionalObj'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default class CheckBox extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this._isAlredyLoad = false
    this.state = {
      info: null,
      values: [],
      isLoading: false,
      checkedValues: [],
      showDefaultRisks: this.props.component.showDefaultRisks || false,
    }
    this.tooltip = createRef(null)
    this.checkboxContainer = createRef(null)

    this.debouncedLoadDataContent = _.debounce(this.loadDataContent, 500)
  }

  loadDataContent() {
    if (!this._isAlredyLoad) {
      this._isAlredyLoad = true
      const {
        dictionary,
        id,
        validate: { required },
      } = this.props.component
      const { template_id, checkReadyFunction } = this.props.options

      checkReadyFunction && checkReadyFunction(id, false)

      if (dictionary) {
        const {
          data: { id, key },
          formioDataType,
        } = dictionary

        if (formioDataType === formioDataTypes.dictionary) {
          if (id && key) {
            this._isMounted &&
              getListDictionariesSite(template_id, key)
                .then((response) => {
                  const { data } = response
                  if (this._isMounted) {
                    this.setState({ dictionaryInfo: data }, () => {
                      getAllData(getDictionaryValuesSite, 1, [template_id, key])
                        .then((response) => {
                          const { data } = response
                          this.setState({ values: data }, () => {
                            const { checkedValues } = this.state

                            const valuesForConditional = conditionalObj(
                              checkedValues
                            )

                            checkReadyFunction &&
                              checkReadyFunction(this.props.component.id, true)
                            // onchange для того что бы отправлять true false для рисков если даже ни одно значение не выбрано отправлять всем false ДЛЯ СПРАВОЧНИКОВ НЕ НУЖНЛ!
                            !required &&
                              this.props.onChange({
                                ...valuesForConditional,
                                submit: {
                                  data: dictionary.data,
                                  formioDataType,
                                  value: checkedValues,
                                  valueTxt: data.filter(
                                    (val) => checkedValues.indexOf(val.id) >= 0
                                  ),
                                  values: data.map((val) => val.id),
                                },
                              })
                          })
                        })
                        .catch((e) => {
                          console.log(e)
                        })
                    })
                  }
                })
                .catch((e) => {
                  console.log(e.response)
                })
          }
        } else if (formioDataType === formioDataTypes.coverage) {
          if (id) {
            this._isMounted &&
              getAllData(getListRisks, 1, [template_id, id])
                .then((response) => {
                  const { data } = response
                  if (this._isMounted) {
                    if (data) {
                      const { checkedValues } = this.state

                      let visibleChecked

                      if (this.state.showDefaultRisks) {
                        visibleChecked = data
                      } else {
                        visibleChecked = _.map(
                          data.filter((item) => !item.visible),
                          (val) => val.id
                        )
                      }
                      const newCheckedValues = _.uniq([
                        ...checkedValues,
                        ...visibleChecked,
                      ])
                      this.setState(
                        {
                          values: data,
                          checkedValues: newCheckedValues,
                        },
                        () => {
                          const valuesForConditional = conditionalObj(
                            newCheckedValues
                          )

                          // onchange для того что бы отправлять true false для рисков если даже ни одно значение не выбрано отправлять всем false ДЛЯ СПРАВОЧНИКОВ НЕ НУЖНЛ!
                          checkReadyFunction &&
                            checkReadyFunction(this.props.component.id, true)
                          if (newCheckedValues.length) {
                            this.props.onChange({
                              ...valuesForConditional,
                              submit: {
                                data: dictionary.data,
                                formioDataType,
                                value: newCheckedValues,
                                valueTxt: data.filter(
                                  (val) => newCheckedValues.indexOf(val.id) >= 0
                                ),
                                values: data.map((val) => val.id),
                              },
                            })
                          } else {
                            !required &&
                              this.props.onChange({
                                ...valuesForConditional,
                                submit: {
                                  data: dictionary.data,
                                  formioDataType,
                                  value: newCheckedValues,
                                  valueTxt: data.filter(
                                    (val) =>
                                      newCheckedValues.indexOf(val.id) >= 0
                                  ),
                                  values: data.map((val) => val.id),
                                },
                              })
                          }
                        }
                      )
                    }
                  }
                })
                .catch((e) => {})
          }
        } else if (formioDataType === formioDataTypes.generalDictionary) {
          this._isMounted &&
            getAllData(getGeneralDictionaryValuesSite, 1, [key])
              .then((response) => {
                if (this._isMounted) {
                  const { data } = response
                  this.setState({ values: data }, () => {
                    checkReadyFunction &&
                      checkReadyFunction(this.props.component.id, true)
                  })
                }
              })
              .catch((e) => {
                console.log(e)
              })
        }
      }
    }
  }

  componentDidMount() {
    const { key } = this.props.component

    this._isMounted = true

    if (this.props.options?.submission?.data[`${key}`]) {
      this.setState(
        {
          checkedValues: this.props.options.submission.data[`${key}`]?.submit
            .value,
        },
        () => {
          this.debouncedLoadDataContent()
        }
      )
    } else {
      this.debouncedLoadDataContent()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this._isAlredyLoad = false
    const { clearOnHide } = this.props.component
    if (clearOnHide) {
      this.props.onChange(null)
    }
  }

  _handleChange = (e) => {
    const { checkedValues, values } = this.state

    const { dictionary } = this.props.component
    const { data, formioDataType } = dictionary

    const newCheckedValue = _.xor(checkedValues, [e.target.value])

    this.setState({ checkedValues: newCheckedValue }, () => {
      const { checkedValues } = this.state

      if (!checkedValues.length) {
        this.props.onChange(null)
      } else {
        const valuesForConditional = conditionalObj(checkedValues)

        this.props.onChange({
          ...valuesForConditional,
          submit: {
            data,
            formioDataType,
            value: checkedValues,
            valueTxt: values.filter(
              (val) => checkedValues.indexOf(val.id) >= 0
            ),
            values: values.map((val) => val.id), // нужно отправлять весь список, так как на бек нужно передаватьвесь список рисков со значениями тру фолс
          },
        })
      }
    })
  }

  _hiddenLabelIFEmpty = (values) => {
    if (!values.find((v) => v.visible)) {
      const parentComponent = this.checkboxContainer.current.closest(
        '.formio-component'
      )
      if (parentComponent) {
        parentComponent.classList.add('formio-component-LabelHidden')
      }
    }
  }

  render() {
    const { component } = this.props
    const { checkedValues, values, showDefaultRisks } = this.state
    if (
      component?.dictionary?.formioDataType &&
      component.dictionary.formioDataType === formioDataTypes.coverage
    ) {
      if (values.length) {
        this._hiddenLabelIFEmpty(values)
      }
    }

    return (
      <div className="formioCheckboxContainer" ref={this.checkboxContainer}>
        {Boolean(values.length) &&
          values.map((val) => {
            return (
              <div
                className="formioCheckboxWrapper"
                key={`checkbox-${val.id}`}
                style={{
                  display: showDefaultRisks
                    ? 'block'
                    : _.has(val, 'visible') && !val.visible
                    ? 'none'
                    : null,
                }}
              >
                <div className="formioCheckboxRow">
                  <input
                    id={`checkbox-${val.id}`}
                    type="checkbox"
                    value={val.id}
                    onChange={this._handleChange}
                    disabled={
                      showDefaultRisks
                        ? true
                        : _.has(val, 'visible') && !val.visible
                    }
                    checked={
                      showDefaultRisks
                        ? true
                        : checkedValues.indexOf(val.id) !== -1
                    }
                  />
                  <label htmlFor={`checkbox-${val.id}`}>
                    <span>{val.value || val.name}</span>
                  </label>
                  {val?.description && val.description && (
                    <OverlayTrigger
                      placement="auto"
                      arrowProps={null}
                      target={this.tooltip}
                      overlay={
                        <Tooltip
                          content={true}
                          id={`checkbox-${val.id}-popover`}
                        >
                          {val.description}
                        </Tooltip>
                      }
                    >
                      <i
                        className="fa fa-question-circle text-muted"
                        ref={this.tooltip}
                      ></i>
                    </OverlayTrigger>
                  )}
                </div>
                {checkedValues.indexOf(val.id) !== -1 && val.is_ban ? (
                  component?.blocked_error ? (
                    <div className={'blockedAlert'}>
                      {component?.blocked_error}
                    </div>
                  ) : (
                    <div className={'blockedAlert'}>
                      Внимание, данное значение есть блокирующем и не даст
                      списка результатов!
                    </div>
                  )
                ) : null}
              </div>
            )
          })}
      </div>
    )
  }
}
