import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import {
  getListHeader,
  disableIdHeader,
  enableIdHeader,
  deleteIdHeader,
} from './ccCrud'
import lodash from 'lodash'

export const actionTypes = {
  setDataHeader: 'HEADER_SET_DATA',
  getDataHeader: 'HEADER_GET_DATA',
  errorDataHeader: 'HEADER_ERROR_DATA',
  setDisabledIdHeader: 'HEADER_SET_DISABLED_ID',
  setEnabledIdHeader: 'HEADER_SET_ENABLED_ID',
  deleteIdHeader: 'HEADER_DELETE_ID',
  createHeader: 'HEADER_CREATE',
  updateIdHeader: 'UPDATE_ID_HEADER',
}

const initialState = {
  header: {
    data: [],
    meta: {
      pager: {
        count: 0,
        total: 0,
        per_page: 0,
        page: 0,
        pages: 0,
      },
    },
    error: false,
    isLoading: false,
    disabled: false,
    globalLoading: true,
  },
}

export const reducer = persistReducer(
  {
    storage,
    key: 'header',
    blacklist: ['header'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.getDataHeader: {
        return {
          ...state,
          header: {
            ...state.header,
            data: [],
            isLoading: true,
            globalLoading: true,
          },
        }
      }
      case actionTypes.setDataHeader: {
        return {
          ...state,
          header: {
            ...state.header,
            ...action.payload,
            isLoading: false,
            globalLoading: false,
          },
        }
      }
      case actionTypes.errorDataHeader: {
        return {
          ...state,
          header: {
            ...state.header,
            isLoading: false,
            error: true,
            globalLoading: false,
          },
        }
      }
      case actionTypes.deleteIdHeader: {
        return {
          ...state,
          header: {
            ...state.header,
            isLoading: true,
            error: false,
          },
        }
      }
      default:
        return state
    }
  }
)
export const getDataHeader = (id = 1) => ({
  type: actionTypes.getDataHeader,
  id,
})

export const setPageHeader = (data) => ({
  type: actionTypes.setDataHeader,
  payload: data,
})

export const setDisabledIdHeader = (id) => ({
  type: actionTypes.setDisabledIdHeader,
  id,
})

export const setEnabledIdHeader = (id) => ({
  type: actionTypes.setEnabledIdHeader,
  id,
})

export const setErrorPageHeader = () => ({
  type: actionTypes.errorDataHeader,
})

export const actions = {
  setPageHeader: (data) => ({
    type: actionTypes.setDataHeader,
    payload: data,
  }),
}

export const deleteHeader = (id) => ({
  type: actionTypes.deleteIdHeader,
  id,
})

export const createDataHeader = (data) => ({
  type: actionTypes.createHeader,
  data,
})

export const editIdDataHeader = ({ id, data }) => ({
  type: actionTypes.updateIdHeader,
  data,
  id,
})

export function* saga() {
  // createHeader

  yield takeLatest(actionTypes.updateIdHeader, function* (currentData) {
    try {
      const state = yield select()
      const newData = state.adminPanel.header.header.data.map((item) => {
        if (item.id === currentData.id) {
          item = {
            ...item,
            ...currentData.data,
          }
        }
        return item
      })

      yield put(actions.setPageHeader({ data: newData }))
    } catch (e) {
      yield put(setErrorPageHeader())
    }
  })

  yield takeEvery(actionTypes.deleteIdHeader, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.header.header.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = true
        }
        return item
      })

      yield put(actions.setPageHeader({ data: newData }))
      yield deleteIdHeader(currentData.id)

      newData = state.adminPanel.header.header.data.filter(
        (item) => item.id !== currentData.id
      )
      yield put(actions.setPageHeader({ data: newData }))
    } catch (e) {
      const state = yield select()
      const newData = state.adminPanel.header.header.data.map((item) => {
        if (currentData.id === item.id) {
          item.delete = false
        }
        return item
      })

      yield put(actions.setPageHeader({ data: newData }))
      yield put(setErrorPageHeader())
    }
  })

  yield takeEvery(actionTypes.setEnabledIdHeader, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.header.header.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.setPageHeader({ data: newData }))
      yield enableIdHeader(currentData.id)
      newData = state.adminPanel.header.header.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'enabled'
          item.loader = false
        }
        return item
      })

      yield put(actions.setPageHeader({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.header.header.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.setPageHeader({ data: newData }))
      }
      yield put(setErrorPageHeader())
    }
  })

  yield takeEvery(actionTypes.setDisabledIdHeader, function* (currentData) {
    try {
      const state = yield select()
      let newData = state.adminPanel.header.header.data.map((item) => {
        if (currentData.id === item.id) {
          item.loader = true
        }
        return item
      })

      yield put(actions.setPageHeader({ data: newData }))
      yield disableIdHeader(currentData.id)
      newData = state.adminPanel.header.header.data.map((item) => {
        if (currentData.id === item.id) {
          item.status = 'disabled'
          item.loader = false
        }
        return item
      })

      yield put(actions.setPageHeader({ data: newData }))
    } catch (e) {
      const addToast = lodash.get(window, '__react_toast_provider.current.add')
      if (addToast && currentData.error) {
        addToast('Произошла ошибка', {
          appearance: 'error',
          autoDismiss: true,
        })
        const state = yield select()
        const newData = state.adminPanel.header.header.data.map((item) => {
          if (currentData.id === item.id) {
            item.loader = false
          }
          return item
        })

        yield put(actions.setPageHeader({ data: newData }))
      }
      yield put(setErrorPageHeader())
    }
  })

  yield takeLatest(actionTypes.getDataHeader, function* (currentData) {
    try {
      const { data } = yield getListHeader(currentData.id)
      yield put(actions.setPageHeader(data))
    } catch (e) {
      console.log(e)
      yield put(setErrorPageHeader())
    }
  })
}
