import axios from 'axios'

const path = '/admin/industries'

export function getStaticProductIndustries(page = 1) {
  return axios.get(`${path}?page=${page}`)
}

export function deleteProductIndustries(id) {
  return axios.delete(`${path}/${id}`)
}

export function blockProductIndustries(id) {
  return axios.patch(`${path}/${id}/disable`)
}

export function editProductIndustriesId(id, data) {
  return axios.put(`${path}/${id}`, data)
}

export function showProductIndustriesId(id) {
  return axios.get(`${path}/${id}`)
}

export function createStaticProductIndustries(data) {
  return axios.post(`${path}`, data)
}

export function unBlockedProductIndustries(id) {
  return axios.patch(`${path}/${id}/enable`)
}
