import React from 'react'
import { getEngineReProcessProduct } from '../redux/crud'
import { isAdminSide } from '../../../helpers/isAdminSide'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import Spinner from '../../../../catalog_app/components/Spinner'
import { priceFormat } from '../../../../_metronic/_helpers/currencyFormatter'
import * as _ from 'lodash'

export default class ProductInfo extends React.PureComponent {
  constructor(props) {
    super(props)
    this._isMounted = true
    this.state = {
      productInfo: null,
      productLoading: false,
      checkbox: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
    const { productLoading } = this.state
    const {
      location: { pathname },
    } = window
    const { story_id } = this.props.options
    if (!isAdminSide(pathname)) {
      const template_id = pathname.split('/')[2]
      const product_id = pathname.split('/')[4]
      if (!productLoading) {
        this.setState({
          productLoading: true,
        })
        getEngineReProcessProduct(template_id, product_id, { story_id })
          .then((response) => {
            if (this._isMounted) {
              const { data } = response
              if (data) {
                this.setState({
                  productInfo: data,
                })
              }
            }
          })
          .catch((e) => {})
          .finally((e) => {
            if (this._isMounted) {
              this.setState({
                productLoading: false,
              })
            }
          })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.checkbox !== this.props._data.productInfo) {
      this.setState({
        checkbox: this.props._data.productInfo,
      })
    }
  }

  handleCheckbox = () => {
    this.setState({ checkbox: !this.state.checkbox }, () => {
      const { checkbox } = this.state
      if (checkbox) {
        this.props.onChange(true)
      } else {
        this.props.onChange(null)
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const {
      location: { pathname },
    } = window

    // const { component } = this.props
    const { productInfo, productLoading } = this.state

    if (productLoading) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '200px' }}
        >
          <Spinner animation="border" variant="primary">
            <span className="sr-only">Загрузка...</span>
          </Spinner>
        </div>
      )
    }
    if (productInfo && !isAdminSide(pathname)) {
      return (
        <div className="productWrapper">
          <div>
            <h1>{productInfo.name}</h1>
            <h3>Основные условия</h3>
            <div className="main__conditions">
              <div className="main__conditions-item">
                <div className="main__conditions-item-label">Компания</div>
                <div className="main__conditions-item-text">
                  {productInfo.company.name}
                </div>
              </div>
              {Boolean(productInfo.coverages.length) &&
                productInfo.coverages.map((coverage) => (
                  <React.Fragment key={`coverags-${coverage.id}`}>
                    <div
                      className="main__conditions-item"
                      key={`coverage-${coverage.id}`}
                    >
                      <div className="main__conditions-item-label">
                        {coverage.product_coverage_name}
                      </div>
                      <div className="main__conditions-item-text">
                        {priceFormat(coverage.insured_sum)}
                        <span> </span>
                      </div>
                    </div>
                    {coverage.risks.map((risk) => (
                      <div
                        className="main__conditions-item"
                        key={`coverage-${coverage.id}-${risk.id}`}
                      >
                        <div className="main__conditions-item-label">
                          {risk.product_coverage_risk_name}
                        </div>
                        <div className="main__conditions-item-text">
                          {risk.is_include ? 'Есть' : 'Нет'}
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              {Boolean(productInfo.dictionary_values.length) &&
                productInfo.dictionary_values.map((dictionary) => (
                  <div
                    className="main__conditions-item"
                    key={`dictionary_values-${dictionary.id}`}
                  >
                    <div className="main__conditions-item-label">
                      {dictionary.dictionary}
                    </div>
                    <div className="main__conditions-item-text">
                      {dictionary.name}
                    </div>
                  </div>
                ))}
            </div>
            <div className="additional__info">
              <h3>Информация о продукте</h3>
              <div className="row">
                <div className="col-xs-12">
                  <p>{productInfo.description}</p>
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  {productInfo.policy_rule_link && (
                    <a
                      href={productInfo.policy_rule_link}
                      target="_blank"
                      rel="noreferrer"
                      className="rules_link mr-3"
                    >
                      Правила страхования
                    </a>
                  )}
                  {productInfo.policy_personal_rule_link && (
                    <a
                      href={productInfo.policy_personal_rule_link}
                      target="_blank"
                      rel="noreferrer"
                      className="rules_link"
                    >
                      Образец договора (полиса)
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="total__info">
              <h3>Стоимость</h3>
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <div className="price__info">
                    <div className="price__info-price">
                      {productInfo.promo_code.code ? (
                        <>
                          <div className="price__info-newPrice">
                            {`${priceFormat(
                              _.round(productInfo.price_with_discount, 0)
                            )}`}
                            <span> </span>
                          </div>
                          <div className="price__info-oldPrice">
                            {`${priceFormat(_.round(productInfo.price, 0))}`}
                            <span> </span>
                          </div>
                        </>
                      ) : (
                        <div className="price__info-newPrice">
                          {`${priceFormat(_.round(productInfo.price, 0))}`}
                          <span> </span>
                        </div>
                      )}
                    </div>
                    {productInfo.promo_code.code && (
                      <div className="price__info-promocode">
                        <div className="text">Скидка по Промо-коду</div>
                        <div className="sale">
                          {productInfo.promo_code.discount_in_percent}%
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="confirmcondition">
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <input
                    type="checkbox"
                    id={'confirmcondition'}
                    onChange={this.handleCheckbox}
                    checked={this.state.checkbox}
                  />
                  <label htmlFor="confirmcondition">
                    <span>
                      Я <a href={'/statics/agreement'}>согласен</a>{' '}
                      <a href={'/statics/policypd'}>
                        с политикой обработки персональных данных
                      </a>{' '}
                      и{' '}
                      <a href={'/statics/terms'}>
                        положениями пользовательского соглашения
                      </a>
                      . Их условия мне полностью разъяснены и понятны.
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="productWrapper-admin">
          <img
            src={toAbsoluteUrl('/media/pib/productInfo.png')}
            alt="productInfo"
          />
        </div>
      )
    }
  }
}
