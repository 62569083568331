import axios from 'axios'

export const getSlidesMain = (page) => {
  return axios.get(`/slider/main/slides?page=${page}`)
}

export const getSlidesBest = (page) => {
  return axios.get(`/slider/best-offer/slides?page=${page}`)
}

export const getIndustriesShowAll = (page = 1, type = 'individual') => {
  return axios.get(`/content/industries?page=${page}&subject_of_law=${type}`)
}
