export const customStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      cursor: 'revert',
      // cursor: isFocused ? 'not-allowed' : 'default',
      borderColor: isFocused ? '#80bdff' : '#d6d6e0',
      border: isFocused ? '1px solid #80bdff' : '1px solid #d6d6e0',
      boxShadow: 'none',
      minHeight: '48px',
      // "&:focus": {
      //   // borderColor: "red",
      //   // color: "red"
      // },
      '&:hover': {},
    }
  },
  input: () => {
    return {
      visibility: 'visible',
      color: 'hsl(0,0%,20%)',
      boxSizing: 'border-box',
      height: '100%',
      position: 'absolute',
      top: '11px',
      left: '24px',
      maxWidth: 'calc(100% - 80px)',
      overflow: 'hidden',
    }
  },
  option: (styles) => {
    return {
      ...styles,
      cursor: 'pointer',
      fontSize: '1rem',
      fontWeight: '400',
    }
  },
  singleValue: () => {
    return {
      fontSize: '16px',
      // maxWidth: 'calc(100% - 80px)',
      fontWeight: '400',
      color: '#3f4254',
      padding: '0 24px 0 24px',
      overflow: 'hidden',
    }
  },
  noOptionsMessage: () => {
    return {
      color: '#b5b5c3',
      padding: '0 24px 0 24px',
    }
  },
  placeholder: () => {
    return {
      color: '#6c757d',
      padding: '0 24px 0 24px',
    }
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => {
    return {
      left: '-12px',
      position: 'relative',
    }
  },
  valueContainer: () => {
    return {}
  },
}

export const customStylesFormInputFms = {
  control: (styles, { isFocused }) => {
    return {
      border: 'none',
    }
  },
  input: () => {
    return {
      visibility: 'visible',
      color: 'hsl(0,0%,20%)',
      boxSizing: 'border-box',
      height: '100%',
      position: 'absolute',
      top: '11px',
      left: '24px',
      maxWidth: 'calc(100% - 80px)',
      overflow: 'hidden',
    }
  },
  option: (styles) => {
    return {
      ...styles,
      cursor: 'pointer',
      fontSize: '1rem',
      fontWeight: '400',
    }
  },
  singleValue: () => {
    return {
      fontSize: '16px',
      fontWeight: '400',
      color: '#3f4254',
      overflow: 'hidden',
      position: 'absolute',
      top: '13px',
      zIndex: '0',
      letterSpacing: '1px',
      width: '124px',
      padding: '0 22px 0 27px',
    }
  },
  noOptionsMessage: () => {
    return {
      color: '#b5b5c3',
      padding: '0 24px 0 24px',
    }
  },
  placeholder: () => {
    return {
      padding: '0 26px 0 27px',
      fontSize: '16px',
      top: '13px',
      position: 'absolute',
      color: '#B5B5C3',
    }
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => {
    return {
      position: 'absolute',
      top: '12px',
      left: '142px',
    }
  },
  dropdownIndicator: () => {
    return {
      display: 'none',
    }
  },
  valueContainer: () => {
    return {
      // display: 'none'
    }
  },
}

export const customStylesFormInputParty = {
  control: (styles, { isFocused }) => {
    return {
      border: 'none',
    }
  },
  input: () => {
    return {
      visibility: 'visible',
      color: 'hsl(0,0%,20%)',
      boxSizing: 'border-box',
      height: '100%',
      position: 'absolute',
      top: '11px',
      left: '24px',
      maxWidth: 'calc(100% - 80px)',
      overflow: 'hidden',
    }
  },
  option: (styles) => {
    return {
      ...styles,
      cursor: 'pointer',
      fontSize: '1rem',
      fontWeight: '400',
    }
  },
  singleValue: () => {
    return {
      fontSize: '16px',
      fontWeight: '400',
      color: '#3f4254',
      // padding: '0 0 0 25px',
      overflow: 'hidden',
      position: 'absolute',
      top: '13px',
      zIndex: '0',
      letterSpacing: '3.05px',
      width: '240px',
      // textAlign: 'center',
      padding: '0 27px',
    }
  },
  noOptionsMessage: () => {
    return {
      color: '#b5b5c3',
      padding: '0 24px 0 24px',
    }
  },
  placeholder: () => {
    return {
      padding: '0 26px 0 27px',
      fontSize: '16px',
      top: '13px',
      position: 'absolute',
      color: '#B5B5C3',
    }
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => {
    return {
      // display: 'none'
      position: 'absolute',
      top: '12px',
      left: '242px',
    }
  },
  dropdownIndicator: () => {
    return {
      display: 'none',
    }
  },
  valueContainer: () => {
    return {
      // display: 'none'
    }
  },
}
